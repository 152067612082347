import { defineStore } from 'pinia'

export const useStoriesStore = defineStore('storiesModal', () => {
  const active = ref(false)
  const storyID = ref<string>()

  function enable(id: string) {
    active.value = true
    storyID.value = id
  }
  function disable() {
    active.value = false
    storyID.value = undefined
  }

  return { active, storyID, enable, disable }
})
