import type { Events } from '~~/plugins/5.emitter'

export default function beginCheckout(product: Events['begin-checkout']['product'], currentValue: number) {
  return {
    event: 'begin_checkout',
    ecommerce: {
      currency: 'RUB',
      value: currentValue,
      items: product.length
        ? product.map((el, i) => {
          const data = {
            item_id: el.id.toString(),
            item_name: el.name,
            price: el.price?.common?.value,
            item_category: el.category?.name,
            quantity: el.quantity,
            index: i + 1,
          }
          return data
        })
        : [],
    },
  }
}
