import Thumbnail from './Thumbnail'
import type Brand from '~/type/Brand'

export default function (el: { [ key: string ]: any } = {}): Brand {
  return {
    id: el.ID,
    code: el.UF_LINK,
    thumbnail: Thumbnail(el.THUMBNAILS),
    name: el.UF_NAME,
    image: el.UF_FILE,
  }
}
