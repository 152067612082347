import ProductSerializer from './product/Product'
import type { Product } from '~/type/product/Product'
import type { Checkout, CheckoutPayment, CheckoutReplacement } from '~/type/order/Checkout'
import type { PaymentCard } from '~/type/PaymentCard'

interface CardResponse {
  [key: string]: any
  PAN: string
}

interface CheckoutResponse {
  [key: string]: any
  BASKET: {
    ITEMS: any[]
  }
  PRODUCT_AVAILABILITY_CONDITION: {
    LIST: any[]
  }
  PAYMENTS?: any[]
  PAYMENT_CARDS?: any[]
  MAYBE_MORE?: any[]
}

export function Payment(el: { [key: string]: any } = {}): CheckoutPayment {
  return {
    id: el.ID,
    selected: el.SELECTED,
    name: el.TITLE,
    disabled: false,
  }
}

export function Card(el: CardResponse): PaymentCard {
  return {
    id: el.CARD_ID,
    rebillId: el.REBILL_ID,
    cardNumberShort: el.PAN?.length > 4 ? el.PAN?.substring(el.PAN.length - 4) : el.PAN,
    type: el.CARD_TYPE,
  }
}

export function Replacement(el: { [key: string]: any } = {}): CheckoutReplacement {
  return {
    id: el.ID,
    title: el.TITLE,
    description: el.DESCRIPTION,
  }
}

export default function (el: CheckoutResponse): Checkout {
  const product = el.BASKET?.ITEMS?.length
    ? el.BASKET?.ITEMS.map(
      (item: any) => {
        return ProductSerializer({
          ...item?.PRODUCT,
          QUANTITY: item?.QUANTITY,
          OLD_QUANTITY: item?.OLD_QUANTITY,
          IS_GIFT: item?.IS_GIFT,
        })
      },
    )
    : []
  const productCart = product.reduce((acc: Checkout['product'], product: Product) => {
    acc[product.id] = product
    return acc
  }, {})
  return {
    product: productCart,
    promocode: el.COUPON,
    replacement: el.PRODUCT_AVAILABILITY_CONDITION?.LIST?.length
      ? el.PRODUCT_AVAILABILITY_CONDITION?.LIST.map((el: any) => Replacement(el))
      : [],
    price: {
      delivery: el.PRICES?.DELIVERY_PRICE || 0,
      sale: el.PRICES?.DISCOUNT_PRICE || 0,
      product: el.PRICES?.PRODUCTS_PRICE || 0,
      total: el.PRICES?.TOTAL_PRICE || 0,
    },
    payment:
      (el.PAYMENTS?.length ? el.PAYMENTS.map((el: any) => Payment(el)) : []),
    cards: el.PAYMENT_CARDS?.length ? el.PAYMENT_CARDS.map((el: any) => Card(el)) : [],
    freeDeliveryPrice: el.FREE_DELIVERY_PRICE ? +el.FREE_DELIVERY_PRICE : 0,
    otherProduct: el.MAYBE_MORE?.length ? el.MAYBE_MORE.map((el: any) => ProductSerializer(el)) : [],
  }
}
