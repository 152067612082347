import type { Story } from '~/type/Story'

export default function (el: { [key: string]: any } = {}): Story {
  function getStoryLink() {
    switch (el.LINK?.TYPE) {
      case 'PRODUCT':
        return el.LINK?.PRODUCT?.PATH
      case 'CATEGORY':
        return el.LINK?.CATEGORY.PATH
      case 'SELECTION':
        return `/selection/${el.LINK?.SELECTION.ID as number}/`
      case 'NEW_PRODUCTS':
        return '/new-product/'
    }
  }

  return {
    image: el.BANNER,
    duration: el?.DURATION * 1000,
    linkTo: getStoryLink(),
  }
}
