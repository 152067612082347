import { configure, defineRule } from 'vee-validate'
import { alpha, max, mimes, min, required, size } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'

export default defineNuxtPlugin(() => {
  defineRule('required', required)
  defineRule('alpha', alpha)
  defineRule('mimes', mimes)
  defineRule('size', size)
  defineRule('min', min)
  defineRule('max', max)
  defineRule('phone', (value: string) => {
    const formatPhone = value.substring(0, 18)
    const exp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/
    return exp.test(formatPhone)
  })

  defineRule('length', (value: string, [length]: string[]) => {
    if (+length !== value.length)
      return `Длина поля должна быть ${length} символов`

    return true
  })

  defineRule('email', (value: string) => {
    const exp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    return exp.test(value)
  })

  defineRule('date', (value: string) => {
    if (!value)
      return true
    const exp = /^\d{1,2}\.\d{1,2}\.\d{4}$/
    return exp.test(value)
  })

  defineRule('url', (value: string) => {
    if (!value)
      return true
    const exp = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
    return exp.test(value)
  })

  configure({
    generateMessage: localize('ru', {
      messages: {
        required: 'Поле обязательно для заполнения',
        alpha: 'Поле может содержать только буквы',
        email: 'Неправильный формат эл.почты',
        url: 'Неправильный формат ссылки',
        min: 'Недостаточное количество символов',
        max: 'Превышен лимит символов',
        phone: 'Неправильный формат номера телефона',
        date: 'Неправильный формат даты',
        mimes: 'Недопустимый формат файла',
        size: 'Недопустимый размер файла',
      },
    }),
  })
})
