import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { formatAddress } from '@artmizu/utils'
import type { Address } from '~/type/Address'

export const useAddressStore = defineStore('address', () => {
  const history = ref<Address[]>([])
  const current = ref<Address>()
  const isCurrentExist = computed(() => !!current.value)
  const formatted = computed(() => current.value && formatAddress(current.value))

  function set(payload: Address) {
    current.value = payload
    if (history.value.every(item => item.street !== payload.street && item.house !== payload.house)) {
      /*
        Оставляем только пять элементов чтобы не переполнять куки
      */
      if (history.value.length < 5)
        history.value.push(payload)
      else
        history.value = [...history.value.slice(1), payload].slice(-5)
    }
  }

  function reset() {
    current.value = undefined
  }

  return { history, current, isCurrentExist, formatted, set, reset }
}, {
  persist: true,
  share: {
    enable: true,
  },
})
