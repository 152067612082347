import type { Events } from '~~/plugins/5.emitter'

export default function orderRefund({ product, id }: Events['order-refund']) {
  return {
    event: 'refund',
    ecommerce: {
      transaction_id: id,
      items: product.length
        ? product.map((el, i) => {
          const path = el.path?.match(/\/([^/]+)\/?$/)
          return {
            item_id: path && path[1],
            item_name: el.name,
            price: el.price,
            item_category: el.category,
            quantity: el.quantity,
            index: i + 1,
          }
        })
        : [],
    },
  }
}
