import type { Events } from '~~/plugins/5.emitter'

export default function productSelect(obj: Events['product-select']) {
  const path = obj?.path?.match(/\/([^/]+)\/?$/)
  const data = {
    item_name: obj.name,
    item_id: (path && path[1])?.toString(),
    price: obj.price?.common?.value,
    item_category: obj.category?.name,
    item_list_name: obj.listName,
    quantity: 1,
  }
  return {
    event: 'select_item',
    ecommerce: {
      items: [data],
    },
  }
}
