import CategorySerializer from '~/serializer/Category'
import type { Category } from '~/type/Category'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'

let categoryPromiseResponse: Promise<{ result: { list: Category[] } }> | null = null

export default {
  async get(): Promise<{ result: { list: Category[] } }> {
    const { $axios } = useNuxtApp()
    const { data }
      = await $axios.get<APIRequestSuccess<[{ CHILDREN: any[] }] >>('/api/v1/catalog/sections', {
        cache: {
          ttl: 60 * 60 * 1000,
        },
      })

    return {
      result: {
        list: data.result?.[0]?.CHILDREN ? data.result?.[0]?.CHILDREN.map((el: any) => CategorySerializer(el)) : [],
      },
    }
  },
  async getCategory() {
    const { $api } = useNuxtApp()

    /**
     * Исключаем ситуацию, при которой несколько частей приложения делают запрос за категориями
     * и в итоге генерируют пачку запросов
     */
    if (!categoryPromiseResponse)
      categoryPromiseResponse = $api.catalog.get()

    /**
     * Обнуляем переменную промиса после окончания его загрузки, чтобы
     * оно не выступала таким глобальным кешем для всех пользователей
     */
    return categoryPromiseResponse.finally(() => {
      categoryPromiseResponse = null
    })
  },
}
