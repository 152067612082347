import { defineStore } from 'pinia'

type ActionStatus = 'error' | 'success'

// store окна отображения состояния в ответ на действие пользователя
export const useActionStatusStore = defineStore('actionStatus', () => {
  const active = ref(false)
  const status = ref<ActionStatus>()
  const title = ref<string>()
  const desc = ref<string>()
  const onConfirm = ref<() => void>()
  const onReject = ref<() => void>()

  function enable(data: { status: ActionStatus; title: string; desc: string; onConfirm?: noop; onReject?: noop }) {
    active.value = true
    title.value = data.title
    desc.value = data.desc
    status.value = data.status
    onConfirm.value = data.onConfirm
    onReject.value = data.onReject
  }

  function disable() {
    active.value = false
    title.value = undefined
    desc.value = undefined
    status.value = undefined
    onConfirm.value = () => {}
    onReject.value = () => {}
  }

  return { active, title, status, desc, enable, disable, onConfirm, onReject }
})
