import { defineStore } from 'pinia'

export const useSaveCardModal = defineStore('saveCardModal', () => {
  const active = ref(false)
  const cardID = ref<string>('')

  function enable(id: string) {
    active.value = true
    cardID.value = id
  }

  function disable() {
    active.value = false
  }

  return { active, cardID, enable, disable }
})
