import { createGtm } from '@gtm-support/vue-gtm'
import afterCheckoutGtm from './gtm/afterCheckout'
import cartProductRemoveList from './gtm/cartProductRemoveList'
import beginCheckoutGtm from './gtm/beginCheckout'
import selectPaymentGtm from './gtm/selectPayment'
import productSelectGtm from './gtm/productSelect'
import searchGtm from './gtm/search'
import cartProductAddGtm from './gtm/cartProductAdd'
import cartProductRemoveGtm from './gtm/cartProductRemove'
import submitFormDataGtm from './gtm/submitFormData'
import authorizationGtm from './gtm/authorization'
import productListViewGtm from './gtm/productListView'
import productViewGtm from './gtm/productView'
import orderRefundGtm from './gtm/orderRefund'
import cleanEcommerceGtm from './gtm/cleanEcommerce'
import addToFavoriteGtm from './gtm/addToFavorite'
import sendDeliveryAreaAnalyticGtm from './gtm/sendDeliveryAreaAnalytic'
import orderCancelFromHistory from './gtm/orderCancelFromHistory'
import clickCallCourierFromOrderModal from './gtm/clickCallCourierFromOrderModal'
import clickOrderCancelFromOrderModal from './gtm/clickOrderCancelFromOrderModal'
import clickPayFromOrderModal from './gtm/clickPayFromOrderModal'
import clickOrderRepeatFromOrderModal from './gtm/clickOrderRepeatFromOrderModal'
import clickSupportChatFromOrderModal from './gtm/clickSupportChatFromOrderModal'
import openChooseAddressModal from './gtm/openChooseAddressModal'
import type { Product } from '~/type/product/Product'

/**
 * Плагин для отправки аналитики по электронной коммерции, сейчас сервисов в которые отправляем два: GTM и Criteo
 */
export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const $emitter = nuxtApp.vueApp.$nuxt.$emitter

  if (runtimeConfig.public.deployEnvironment === 'production' && runtimeConfig.public.gtmId) {
    nuxtApp.vueApp.use(createGtm({
      id: runtimeConfig.public.gtmId,
      defer: false, // Включение отложенной загрузки для увеличения скорости загрузки страницы
      compatibility: false, // Добавит `async` и `defer` в тег скрипта, чтобы не блокировать запросы для старых браузеров, которые не поддерживают `async`
      enabled: true,
      debug: true, // Отображать или не отображать отладку в консоли
      loadScript: true, // Загружать или нет сценарий GTM (полезно, если вы включаеть GTM вручную, но вам нужна функциональность dataLayer в ваших компонентах) (необязательно)
      vueRouter: useRouter(),
      trackOnNextTick: false, // Будет ли вызываться trackView в Vue.nextTick
    }))
  }

  function publishGTM(data?: any) {
    window.dataLayer?.push(data)
  }

  $emitter.on('select-payment', (paymentMetnod) => {
    publishGTM(selectPaymentGtm(paymentMetnod))
  })

  $emitter.on('product-select', (data) => {
    publishGTM(cleanEcommerceGtm())
    publishGTM(productSelectGtm(data))
  })

  $emitter.on('begin-checkout', ({ product, currentValue }) => {
    publishGTM(cleanEcommerceGtm())
    publishGTM(beginCheckoutGtm(product, currentValue))
  })

  $emitter.on('search', (searchTerm) => {
    publishGTM(searchGtm(searchTerm))
  })

  $emitter.on('wishlist-product-add', (data) => {
    publishGTM(cleanEcommerceGtm())
    publishGTM(addToFavoriteGtm(data))
  })

  $emitter.on('cart-product-remove-list', (data) => {
    publishGTM(cleanEcommerceGtm())
    publishGTM(cartProductRemoveList(data))
  })

  $emitter.on('after-checkout', (data) => {
    publishGTM(cleanEcommerceGtm())
    publishGTM(afterCheckoutGtm(data.order))

    window.mindbox('sync', {
      operation: 'Website.CreateAuthorizedOrder',
      data: {
        customer: {
          ids: {
            websiteID: data.user.id,
          },
          firstName: data.user.name ?? '',
          mobilePhone: data.user.phone,
        },
        order: {
          ids: {
            websiteID: data?.order?.id,
          },
          deliveryCost: data.order.price.delivery,
          totalPrice: data.order.price.total,
          payments: [
            {
              amount: data.order.price.total,
              type: data.order.paymentMethod,
            },
          ],
          lines: data?.order?.product.map((el: Product) => {
            return {
              basePricePerItem: el.price?.common?.value,
              quantity: el.quantity,
              product: {
                ids: {
                  website: el.id,
                },
              },
            }
          }),
        },
      },
    })
  })

  $emitter.on('cart-product-add', (data) => {
    publishGTM(cleanEcommerceGtm())
    publishGTM(cartProductAddGtm(data))
    if (data.cart)
      setCartToMindbox(data.cart)
  })

  $emitter.on('cart-product-remove', (data) => {
    publishGTM(cleanEcommerceGtm())
    publishGTM(cartProductRemoveGtm(data))
    if (data.cart)
      setCartToMindbox(data.cart)
  })

  $emitter.on('user-select-another-address', data => nuxtApp.vueApp.$nuxt.$metrika.reachGoal(data))
  $emitter.on('choose-city-modal-enable', data => nuxtApp.vueApp.$nuxt.$metrika.reachGoal(data))
  $emitter.on('get-empty-search-result', data => nuxtApp.vueApp.$nuxt.$metrika.reachGoal(data))
  $emitter.on('search-product', data => nuxtApp.vueApp.$nuxt.$metrika.reachGoal(data))
  $emitter.on('click-search-link', data => nuxtApp.vueApp.$nuxt.$metrika.reachGoal(data))
  $emitter.on('apply-promocode', (data) => {
    if (data.status === 'success') {
      if (data.discount)
        nuxtApp.vueApp.$nuxt.$metrika.reachGoal('promocode_success')
      else nuxtApp.vueApp.$nuxt.$metrika.reachGoal('wrong_promocode')
    }
    else { nuxtApp.vueApp.$nuxt.$metrika.reachGoal('error_promocode') }
  })
  $emitter.on('product-view', (data) => {
    const useData = {
      id: data.id,
      name: data.name,
      price: data.price,
      category: data.category,
      quantity: data.quantity,
    }
    publishGTM(cleanEcommerceGtm())
    publishGTM(productViewGtm(useData))
    if (data.status?.code === 'NOT_AVAILABLE')
      nuxtApp.vueApp.$nuxt.$metrika.reachGoal('vue_dev_net_v_nalichii')

    window.mindbox('sync', {
      operation: 'Website.ViewProduct',
      data: {
        customerAction: {
          customFields: {
            darkstoreAction: true,
          },
        },
        viewProduct: {
          product: {
            ids: {
              website: data.id,
            },
          },
        },
      },
    })
  })

  $emitter.on('view-category', (data) => {
    window.mindbox('sync', {
      operation: 'Website.ViewCategory',
      data: {
        customerAction: {
          customFields: {
            darkstoreAction: true,
          },
        },
        viewProductCategory: {
          productCategory: {
            ids: {
              website: data.id,
            },
          },
        },
      },
    })

    window.PopMechanicPageData = {
      type: 'category',
      category: {
        id: data.id,
        name: data.name,
      },
    }
  })

  $emitter.on('auth', (data) => {
    data.username?.includes('@')
      ? publishGTM(submitFormDataGtm({ email: data.username }))
      : publishGTM(submitFormDataGtm({ phone: data.username || data.phone }))
    nuxtApp.vueApp.$nuxt.$metrika.reachGoal('auth-sms-code')
    nuxtApp.vueApp.$nuxt.$metrika.reachGoal('vue_dev_user_logged')
    publishGTM(authorizationGtm('sms'))
    if (data.email)
      publishGTM(submitFormDataGtm({ email: data.email }))

    window.mindbox('sync', {
      operation: 'Website.AuthorizeCustomer',
      data: {
        customer: {
          mobilePhone: data.phone,
        },
        customerAction: {
          customFields: {
            darkstoreAction: true,
          },
        },
      },
    })
  })

  $emitter.on('product-list-view', (data) => {
    if (data.product)
      void $emitter.emit('product-view', data.product)
    publishGTM(cleanEcommerceGtm())
    publishGTM(productListViewGtm(data))
  })

  $emitter.on('order-refund', (data) => {
    publishGTM(orderRefundGtm(data))
  })

  $emitter.on('click-story-banner', (index) => {
    switch (index) {
      case 0:
        nuxtApp.vueApp.$nuxt.$metrika.reachGoal('click_stories_promo_first_order')
        break
      case 1:
        nuxtApp.vueApp.$nuxt.$metrika.reachGoal('click_stories_shop_hours')
        break
      case 2:
        nuxtApp.vueApp.$nuxt.$metrika.reachGoal('click_stories_free_shipping')
        break
    }
  })

  $emitter.on('click-notify-delivery-available', () => {
    publishGTM(sendDeliveryAreaAnalyticGtm())
  })

  $emitter.on('order-cancel-from-history', () => {
    publishGTM(orderCancelFromHistory())
  })

  $emitter.on('status-order-click-call-the-courier', (data: { status: string }) => {
    publishGTM(clickCallCourierFromOrderModal(data))
  })

  $emitter.on('status-order-click-cancel-the-order', (data: { status: string }) => {
    publishGTM(clickOrderCancelFromOrderModal(data))
  })

  $emitter.on('status-order-click-pay-for-the-order', (data: { status: string }) => {
    publishGTM(clickPayFromOrderModal(data))
  })

  $emitter.on('status-order-click-repeat-order', (data: { status: string }) => {
    publishGTM(clickOrderRepeatFromOrderModal(data))
  })

  $emitter.on('status-order-click-support-chat', (data: { status: string }) => {
    publishGTM(clickSupportChatFromOrderModal(data))
  })

  $emitter.on('open-modal-choose-address', () => {
    publishGTM(openChooseAddressModal())
  })
})

function setCartToMindbox(cart: { [key: string]: Product }) {
  window.mindbox('sync', {
    operation: 'Website.SetCart',
    data: {
      customerAction: {
        customFields: {
          darkstoreAction: true,
        },
      },
      productList: Object.keys(cart).map((id) => {
        return {
          product: {
            ids: {
              website: id,
            },
          },
          count: cart[id].quantity,
        }
      }),
    },
  })
}
