import Story from '~/serializer/Story'

export default {
  async getByGroup({ groupID = '01' }) {
    const { $axios } = useNuxtApp()

    const { data } = await $axios.get<{
      result: { [key: string]: any }[]
    }>(`/api/v1/darkstore/stories/${groupID}`)

    return Array.isArray(data.result) ? data.result.map(item => Story(item)) : []
  },
}
