<script setup lang="ts">
import { createPopper } from '@popperjs/core'
import hoverintent from 'hoverintent'
import { storeToRefs } from 'pinia'
import { useResizeStore } from '~/store/resize'

interface Props {
  active: boolean
  loading: boolean
  tips?: boolean
  type?: string
}
const props = withDefaults(defineProps<Props>(), {
  active: false,
  loading: false,
  tips: true,
  type: 'favorite',
})
const emit = defineEmits<{ (e: 'remove' | 'add'): void }>()

const POPOVER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 5],
    },
  },
  {
    name: 'preventOverflow',
    options: {
      padding: 20,
    },
  },
]
const isActiveHover = ref(false)
const toolHover = ref<ReturnType<typeof createPopper>>()
let $hoverintent: ReturnType<typeof hoverintent>
const initializedHover = ref(false)
const handlerEl = shallowRef<HTMLDivElement>()
const rootEl = shallowRef<HTMLDivElement>()
const contentHover = shallowRef<HTMLDivElement>()
const { isMobile } = storeToRefs(useResizeStore())

const content = computed(() => props.active ? 'Удалить из избранного' : 'Добавить в избранное')
const showHoverPopover = computed(() => isActiveHover.value && !props.loading && props.tips)

onMounted(() => {
  $hoverintent = hoverintent(handlerEl.value!, initHoverPopovers, destroyHoverPopovers)
})

onBeforeUnmount(() => {
  if ($hoverintent)
    $hoverintent.remove()

  destroyHoverPopovers()
})

function initHoverPopovers() {
  if (!isMobile.value) {
    if (!initializedHover.value) {
      toolHover.value = createPopper(rootEl.value!, contentHover.value!, {
        placement: 'top',
        modifiers: POPOVER_MODIFIERS,
      })
    }
    initializedHover.value = true
    isActiveHover.value = true
  }
}

function destroyHoverPopovers() {
  isActiveHover.value = false

  if (toolHover.value) {
    toolHover.value.destroy()
    toolHover.value = undefined
    initializedHover.value = false
  }
}

function handleClickTool() {
  emit(props.active ? 'remove' : 'add')
}
</script>

<template>
  <div ref="rootEl" class="user-tool user-tool_favorite">
    <div
      ref="handlerEl"
      class="user-tool__icon"
      :class="[{ 'user-tool__icon_active': active && !loading }, { 'user-tool__icon_loading': loading }]"
      @click.stop="handleClickTool"
    />
    <div
      ref="contentHover"
      class="pt-4 pb-6 user-tool__content"
      :class="[{ 'user-tool__content_active': showHoverPopover }]"
    >
      {{ content }}
    </div>
  </div>
</template>

<style lang="postcss">
.user-tool {

  &_favorite {

    .user-tool__icon {

      &:before {
        background-image: svg-load('./asset/favorite.svg', fill=#000);
      }
      @media (min-width: 800px) {

        &:hover:before {
          background-image: svg-load('./asset/favorite-new.svg', fill=#ee272d);
        }
      }

      &_active {

        &:before {
          background-image: svg-load('./asset/favorite-new.svg', fill=#ee272d);
        }
      }

      &_loading {

        &:before {
          background-image: svg-load('./asset/loading-oval.svg', stroke=#000, fill=transparent);
        }
      }
    }
  }

  &__icon {
    position: relative;
    cursor: pointer;

    &:before {
      display: block;
      width: 15px;
      height: 15px;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &:hover:before {
      transition: background-image 0.2s ease-out;
    }
  }

  &__content {
    position: absolute;
    z-index: 300;
    box-sizing: border-box;
    display: block;
    min-width: 150px;
    padding: 5px 9px;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    text-align: center;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.89);
    border-radius: 4px;
    outline: 0;
    opacity: 0;
    transition-property: visibility, opacity;

    &_with-button {
      min-width: 190px;
    }

    div {
      font-size: 11px;
      line-height: 15px;
    }

    &_active {
      display: block;
      visibility: visible;
      opacity: 1;
    }
    @media (max-width: 1000px) {

      display: none;
    }
  }
}
</style>
