<script setup lang="ts">
import debounce from 'lodash.debounce'

interface Props {
  quantity: number
  min?: number
  max?: number
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  quantity: 1,
  min: 1,
  max: Infinity,
  loading: false,
})
const emit = defineEmits<{ (e: 'decrease' | 'increase', value: number): void }>()

const emitDebounce = debounce(emit, 300)

function plus() {
  const newValue = props.quantity + 1
  emitDebounce('increase', updateValue(newValue))
}

function minus() {
  const newValue = props.quantity - 1
  emitDebounce('decrease', updateValue(newValue))
}

function updateValue(val: number) {
  if (val > props.max)
    return props.max
  else
    return val
}
</script>

<template>
  <div class="counter">
    <div class="counter__wrapper">
      <button
        type="button"
        class="counter__button-number counter__button-number_minus"
        @click="minus"
      />
      <div
        v-if="loading"
        class="icon-loading-oval"
      />
      <div
        v-else
        class="counter__number"
      >
        <slot name="quantity">
          {{ quantity }}
        </slot>
      </div>
      <button
        type="button"
        class="counter__button-number counter__button-number_plus"
        :class="[{ 'counter__button-number_disabled': quantity >= max }]"
        :disabled="quantity >= max"
        @click="plus"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.counter {

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__number {
    flex-shrink: 0;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  &__button-number {
    width: 20%;
    padding: 8px;
    cursor: pointer;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid transparent;

    &:active,
    &:focus {
      outline: none;
    }

    &_minus {
      background-image: svg-load('./asset/minus.svg', fill=#000);
      background-repeat: no-repeat;
      background-position: center left;

      &:hover {
        background-image: svg-load('./asset/minus.svg', fill=#ee272d);
        transition-duration: 0.2s;
      }
    }

    &_plus {
      background-image: svg-load('./asset/plus.svg', fill=#000);
      background-repeat: no-repeat;
      background-position: center right;

      &:hover {
        background-image: svg-load('./asset/plus.svg', fill=#ee272d);
        transition-duration: 0.2s;
      }
    }

    &_disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}
</style>
