import type { PaymentCard } from '~/type/PaymentCard'

interface PaymentCardResponse {
  [key: string]: any
  PAN?: string
}

export default function (el: PaymentCardResponse): PaymentCard {
  return {
    id: el.CARD_ID,
    rebillId: el.REBILL_ID,
    cardNumberShort: (el.PAN && el.PAN.substring(el.PAN.length - 4)) || '',
    type: el.CARD_TYPE,
  }
}
