<script setup lang="ts">
import { UI05Button, UI05Modal } from 'ui-05'
import { useCartChangedModal } from '~/store/modal/cartChanged'
import type { Product } from '~/type/product/Product'

defineProps<{
  productList: Product[]
}>()
const cartChangedModalStore = useCartChangedModal()

function onClose() {
  cartChangedModalStore.disable()
}
</script>

<template>
  <UI05Modal :show="cartChangedModalStore.active && !cartChangedModalStore.isChecked" @close="onClose">
    <template #title>
      <p class="modal__title">
        Корзина изменилась
      </p>
    </template>
    <template #default>
      <OrderProduct
        v-for="item in productList"
        :key="item.id"
        class="mt-20 mb-20"
        :product="item"
      />
      <UI05Button
        class="mt-20"
        fluid
        data-e2e="cart-changed-modal-close"
        size="49"
        theme="turquoise"
        @click="onClose"
      >
        Понятно
      </UI05Button>
    </template>
  </UI05Modal>
</template>

<style lang="postcss" scoped>
.modal {

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: #2C2E33;
  }
  @media (max-width: 1000px) {

    &__title {
      font-size: 18px;
      line-height: 21px;
    }
  }
}
</style>
