import { defineStore } from 'pinia'

export const useNotWorkingTimeModal = defineStore('notWorkingTimeModal', () => {
  const active = ref(false)

  // Показываем один раз уведомление о недоступности
  const isShowUnavailableModal = useCookie<boolean | undefined>('isShowUnavailableModal', {
    expires: new Date(),
    maxAge: 60 * 60 * 6,
  })

  function enable() {
    active.value = true
  }

  function disable() {
    active.value = false

    if (!isShowUnavailableModal.value)
      setIsShowModal(true)
  }

  function setIsShowModal(value: boolean) {
    isShowUnavailableModal.value = value
  }

  return { active, isShowUnavailableModal, enable, disable, setIsShowModal }
})
