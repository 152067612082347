export function formatPhone(val: string) {
  let phoneNumber = val.replace(/[^\d]/g, '')

  // если после +7 вставили полноценный номер телефона - вырезаем его и форматируем строку как нужно
  if (phoneNumber.length > 11)
    phoneNumber = phoneNumber.substring(1)

  // номер начинается с восьмерки - убираем её и заменяем на +7
  if (phoneNumber[0] === '8' || phoneNumber[0] === '7')
    phoneNumber = `7${phoneNumber.substring(1)}`
  else
    phoneNumber = `7${phoneNumber}`

  if (phoneNumber.length === 1)
    return `+${phoneNumber[0]}`

  else if (phoneNumber.length < 5)
    return `+${phoneNumber[0]}(${phoneNumber.substring(1)}`

  else if (phoneNumber.length < 8)
    return `+${phoneNumber[0]}(${phoneNumber.substring(1, 4)})${phoneNumber.substring(4)}`

  else if (phoneNumber.length < 10)
    return `+${phoneNumber[0]}(${phoneNumber.substring(1, 4)})${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7)}`

  else return `+${phoneNumber[0]}(${phoneNumber.substring(1, 4)})${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7, 9)}-${phoneNumber.substring(9, 11)}`
}
