<script setup lang="ts">
import { UI05Button, UI05Checkbox, UI05FormElement, UI05InputText, UI05Modal, UI05Textarea, UI05Tooltip } from 'ui-05'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { deliveryAvailableStore } from '~/store/modal/deliveryAvailable'
import { useUserStore } from '~/store/user'
import { formatPhone } from '~/helper/formatPhone'

const inputPhone = shallowRef<InstanceType<typeof UI05InputText>>()
const { $api } = useNuxtApp()
const deliveryAvailableModalStore = deliveryAvailableStore()
const { data: userData } = storeToRefs(useUserStore())

const agree = ref(true)
const schema = {
  phone: 'required|phone',
  comment: 'min:4|max:2000',
}

const { values, errors, handleSubmit, defineComponentBinds, setFieldValue } = useForm<{
  phone: string
  comment?: string
}>({
  validationSchema: computed(() => deliveryAvailableModalStore.active && schema),
})

const phone = defineComponentBinds('phone', {
  validateOnBlur: true,
  validateOnModelUpdate: false,
})

const comment = defineComponentBinds('comment', {
  validateOnBlur: true,
  validateOnModelUpdate: false,
})

const params = computed(() => ({
  address: deliveryAvailableModalStore.address,
  phone: values.phone,
  comment: values.comment,
}))

const sendDeliveryAnalytic = useActionEntity($api.map.sendDeliveryAreaAnalytic, {
  params,
  onSuccess() {
    const timer = setTimeout(() => {
      deliveryAvailableModalStore.disable()
      sendDeliveryAnalytic.changeStatus('initial')
      clearTimeout(timer)
    }, 1000)
  },
  onError({ serialized }) {
    UI05Tooltip({
      title: 'Ошибка при отправке',
      description: serialized?.description,
      type: 'error',
    })
  },
})

watch(() => deliveryAvailableModalStore.active, (isActive) => {
  if (isActive) {
    setFieldValue('phone', userData.value?.phone ? formatPhone(userData.value?.phone) : '')
    window.requestAnimationFrame(() => {
      (inputPhone.value?.$refs.input as HTMLInputElement).focus()
    })
  }
})

const onSubmit = handleSubmit(onSuccessValidation, onInvalidSubmit)

function onSuccessValidation() {
  nextTick(() => sendDeliveryAnalytic.request(params.value))
}

function onInvalidSubmit({ errors }: { errors: { [key: string]: any } }) {
  const firstError = Object.keys(errors)[0]
  const el = document.querySelector(`[name="${firstError}"]`)
  if (el) {
    scrollIntoView(el, {
      behavior: 'smooth',
      block: 'center',
    })
  }
}
</script>

<template>
  <div class="delivery-available">
    <UI05Modal
      class="ui-modal"
      :show="deliveryAvailableModalStore.active"
      max-width="500px"
      overlay-theme="light"
      @close="deliveryAvailableModalStore.disable"
    >
      <template #title>
        <ViewportToogle>
          <template #desktop>
            <h1 class="delivery-available__title">
              Сообщить о новой зоне доставки
            </h1>
          </template>
          <template #mobile>
            <h1 class="delivery-available__title">
              Зона доставки
            </h1>
          </template>
        </ViewportToogle>
      </template>
      <template #default>
        <UI05FormElement
          title="Телефон"
          :error="errors.phone"
        >
          <UI05InputText
            ref="inputPhone"
            v-bind="phone"
            :error="!!errors.phone"
            placeholder="Введите номер телефона"
            @update:model-value="setFieldValue('phone', formatPhone($event))"
          />
        </UI05FormElement>

        <UI05FormElement
          title="Комментарий"
          :error="errors.comment"
        >
          <UI05Textarea
            v-bind="comment"
            :error="!!errors.comment"
            :height="60"
            dynamical-height
            placeholder="Хочу доставку на этот адрес"
          />
        </UI05FormElement>
        <UI05FormElement title="">
          <UI05Checkbox v-model="agree" turquoise>
            Я согласен(а)
            <nuxt-link
              class="link link_blue link_wu"
              to="/about/privacy/"
              @click="deliveryAvailableModalStore.disable"
            >
              на обработку персональных данных
            </nuxt-link>
            и
            <nuxt-link
              class="link link_blue link_wu"
              to="/about/terms-of-use/"
              @click="deliveryAvailableModalStore.disable"
            >
              с пользовательским соглашением
            </nuxt-link>
          </UI05Checkbox>
        </UI05FormElement>
        <UI05Button
          class="delivery-available__btn"
          size="49"
          :disabled="!agree"
          :loading="sendDeliveryAnalytic.loading.value"
          :theme="sendDeliveryAnalytic.status.value === 'success' ? 'green' : 'turquoise'"
          fluid
          @click="onSubmit"
        >
          {{ sendDeliveryAnalytic.status.value === 'success' ? 'Отправлено' : 'Отправить' }}
        </UI05Button>
      </template>
    </UI05Modal>
  </div>
</template>

<style scoped lang="postcss">
.delivery-available {

  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }

  &__line {
    width: 100%;
    height: 1px;
    color: #F3F3F3;
  }

  @media (max-width: 800px) {

    &__btn {
      font-size: 16px;
      border-radius: 10px;
    }
  }
}

.ui-modal :deep(.modal) {
  padding: 25px;
  border-radius: 10px;

  &__head {
    border-bottom: 1px solid #F3F3F3;
  }
}
</style>
