<script setup lang="ts">
import { UI05Photoswipe } from 'ui-05'

interface Props {
  disabled?: boolean
  /**
   * Возможен ли выбор нескольких файлов
   */
  multiple?: boolean
  /**
   * MIME-форматы, которые можно выбрать в инпуте
   */
  accept?: string
  /**
   * Максимальный размер картинки (в байтах)
   */
  maxFileSize?: number
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  multiple: false,
  accept: 'image/*',
  maxFileSize: 5242880,
})

const emit
  = defineEmits<{
    (e: 'update', value: File[]): void
    (e: 'error', value: { message: string }): void
  }>()

const files = ref<File[]>([])
const items = ref<{ src: string; w: number; h: number }[] | undefined>([])
const photoSwipeIndex = ref(0)
const photoswipeEl = shallowRef<InstanceType<typeof UI05Photoswipe>>()
const photo = shallowRef<HTMLImageElement[]>()

const images = computed(() => {
  return files.value.map(file => (typeof file === 'string' ? file : URL.createObjectURL(file)))
})

function onClick(index: number) {
  if (items.value) {
    items.value = photo.value?.map((el) => {
      return {
        src: el.src,
        w: Number(el.naturalWidth),
        h: Number(el.naturalHeight),
      }
    })
    photoswipeEl.value?.open({ items: items.value!, options: { index: photoSwipeIndex.value } })
    photoSwipeIndex.value = index
  }
}

function removeImage(index: number) {
  items.value?.splice(index, 1)
  files.value.splice(index, 1)
  /**
  * Вызывается после удаления картинки
  */
  emit('update', files.value)
}

function onFileChange(fileList: File[]) {
  if (!fileList.length)
    return
  /**
  * Если размер картинка больше указанного в пропсе, отправляем ошибку
  */
  const isNotMaxFile = fileList.every(file => props.maxFileSize >= file.size)
  isNotMaxFile ? send({ fileList }) : emit('error', { message: 'Фотография превышает размер 5 MB' })
}

function send({ fileList }: { fileList: File[] }) {
  /**
  * Чтобы при добавление новых фотографий не удалялись старые
  */
  // eslint-disable-next-line prefer-spread
  files.value.push.apply(files.value, fileList)
  /**
  * Вызывается после выбора картинок
  * @property {array} fileList список выбранных файлов напрямую из инпута
  */
  emit('update', files.value)
}

function handleFileDrop(event: DragEvent) {
  event.preventDefault()
  const files = event.dataTransfer?.files
  if (files && files.length) {
    const fileList = Array.from(files)
    onFileChange(fileList)
  }
}

function handleFileChange(event: Event) {
  const input = event.target as HTMLInputElement
  const fileList = input.files
  if (fileList && fileList.length) {
    const files = Array.from(fileList)
    onFileChange(files)
  }
}
</script>

<template>
  <div class="photo-upload">
    <div class="photo-upload__wrapper">
      <div
        v-for="(el, i) in images"
        :key="i"
        class="photo-upload__photo-wrapper"
        @click="onClick(i)"
      >
        <img
          ref="photo"
          class="photo-upload__photo"
          :src="el"
        >
        <div
          class="photo-upload__photo-remove"
          title="Удалить"
          @click.stop="removeImage(i)"
        />
      </div>
      <div
        class="photo-upload__photo-wrapper photo-upload__file"
        :class="[{ 'photo-upload_disabled': disabled }]"
        @drop.prevent="handleFileDrop"
      >
        <label class="photo-upload__file-wrapper">
          <div class="photo-upload__file-icon" />
          <input
            type="file"
            class="photo-upload__file-input"
            :accept="accept"
            :disabled="disabled"
            :multiple="multiple"
            @change="handleFileChange"
          >
        </label>
      </div>
    </div>
    <UI05Photoswipe v-if="items" ref="photoswipeEl" />
  </div>
</template>

<style lang="postcss" scoped>
.photo-upload {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px -10px;
  }

  &__photo {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 3px;

    &-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      margin: 0 4px 10px;
    }

    &-remove {
      position: absolute;
      top: -6px;
      right: -6px;
      width: 21px;
      height: 21px;
      cursor: pointer;
      background-color: #fff;
      background-image: svg-load('./asset/close.svg', fill=#000);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 7px;
      border: 1px solid #f7f7f7;
      border-radius: 50%;

      &:hover {
        background-color: #e30613;
        background-image: svg-load('./asset/close.svg', fill=#fff);
      }
    }
  }

  &__file {
    position: relative;
    width: 70px;
    height: 70px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: #fbfbfb;
    }

    &:active {
      background-color: #f9f9f9;
    }

    &-icon {
      width: 30px;
      height: 40px;
      margin: auto;
      background-image: url('./asset/no-photo.svg');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
    }

    &-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }

    &-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 10px 14px 9px 12px;
    }

    &_disabled {
      color: #818181;
      cursor: not-allowed;
      background: repeating-linear-gradient(-45deg, #f4f4f4, #f4f4f4 6px, #fff 0, #fff 13px);
      border: 1px solid rgba(0, 0, 0, 0.13);

      &:active {
        border: 1px solid rgba(0, 0, 0, 0.13);
      }
    }
  }
  @media (max-width: 800px) {

    &__wrapper_photo {
      margin: 0 -6px -10px;
    }

    &__photo-wrapper {
      margin: 0 6px 10px;
    }
  }
}
</style>
