import type { CategoryResponseType } from './CategoryBase'
import CategoryBase from './CategoryBase'
import type { Category } from '~/type/Category'

export default function (el: CategoryResponseType): Category {
  return {
    ...CategoryBase(el),
    image: el.MAIN_PAGE_IMAGE,
    color: el.MAIN_PAGE_IMAGE_BG_COLOR,
  }
}
