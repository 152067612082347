<script setup lang="ts">
import type { Address } from '~/type/Address'

const emit = defineEmits<{ (event: 'onAddressDefinition', value: Address): void }>()
const { $error, $api } = useNuxtApp()

function findAddress() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const lat = position.coords.latitude
        const lon = position.coords.longitude
        try {
          const address = await $api.geo.getAddressByCoordinate({ lat, lon })
          emit('onAddressDefinition', address)
        }
        catch (error) {
          new $error.base({
            message: 'Не удалось получить подсказки к запросу через яндекс',
            native: error,
          })
        }
        try {
          const address = await $api.geo.getAddress({ count: 1, lat, lng: lon })
          emit('onAddressDefinition', address)
        }
        catch (e) {
          new $error.base({
            message: 'Не удалось получить подсказки к запросу через дадату',
            native: e,
          })
        }
      },
      (e) => {
        new $error.simple({
          name: 'Ошибка',
          native: e,
          message: 'Не удалось получить ваше местоположение, выберете его на карте или напишите адрес в поисковой строке',
        })
      },
    )
  }
}
</script>

<template>
  <div class="find-address">
    <button
      class="find-address__button"
      @click="findAddress"
    />
  </div>
</template>

<style lang="postcss" scoped>
.find-address {

  &__button {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    outline: none;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 17px;
      height: 15px;
      content: '';
      background-image: url(./asset/location.svg);
      background-repeat: no-repeat;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
