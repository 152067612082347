import debounce from 'lodash.debounce'
import { useResizeStore } from '~/store/resize'

export default defineNuxtPlugin(() => {
  let isMobile = window.innerWidth <= 800
  let resize = window.innerWidth
  const resizeStore = useResizeStore()

  resizeStore.setIsMobile(isMobile)
  resizeStore.setResizeWindow(resize)
  const commitIsMobile = debounce(resizeStore.setIsMobile, 150)
  const commitResizeWindow = debounce(resizeStore.setResizeWindow, 150)

  window.addEventListener('resize', () => {
    /**
     * В мобильных устройствах событие изменения размера запускается случайным образом при прокрутке.
     * Поэтому сначала проверяем что ширина окна действительно изменилась
     */
    if (window.innerWidth !== resize) {
      isMobile = window.innerWidth <= 800
      resize = window.innerWidth
      commitIsMobile(isMobile)
      commitResizeWindow(resize)
    }
  })
})
