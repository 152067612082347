import { defineStore } from 'pinia'
import type { Category } from '~/type/Category'

/*
  Стор для хранения списка категорий после начальной загрузки
*/
export const useCategoryStore = defineStore('category', () => {
  const categories = ref<Category[]>([])

  function setList(list: Category[]) {
    categories.value = list
  }

  return { categories, setList }
})
