import refiner from 'refiner-js'
import { useUserStore } from '~~/store/user'

export default defineNuxtPlugin(() => {
  const userStore = useUserStore()

  refiner('setProject', 'f0ea4370-36fa-11ec-b477-fd3314accdff')

  if (userStore.data) {
    refiner('identifyUser', {
      id: String(userStore.data.id),
      email: userStore.data.email || '',
      name: userStore.name,
      phone: userStore.data.phone || 'Нет номера телефона',
    })
  }
  else {
    userStore.$onAction((mutation) => {
      if (mutation.name === 'set') {
        refiner('identifyUser', {
          id: String(mutation.store?.data?.id),
          email: mutation.store?.data?.email || '',
          name: userStore.name,
          phone: mutation.store?.data?.phone || 'Нет номера телефона',
        })
      }
    })
  }

  return {
    provide: {
      refiner: {
        showForm({
          id,
          force,
        }: { id: string; force: boolean }) {
          refiner('showForm', id, force)
        },
      },
    },
  }
})
