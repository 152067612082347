import { stringify } from 'qs'
import ProductSerializer from '~/serializer/product/Product'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import type { Product } from '~/type/product/Product'

export default {
  async getByID({ type, id }: { type: string; id?: number }): Promise<{ result: { product: Product[] } }> {
    const { $axios } = useNuxtApp()

    const { data } = (
      await $axios.get<APIRequestSuccess<{ ITEMS: any[] }>>(
        `/api/v1/sliders?${stringify({
          type,
          section_id: id,
        })}`,
      )
    )

    return {
      result: {
        product: data.result?.ITEMS?.length ? data.result.ITEMS.map((item: any) => ProductSerializer(item)) : [],
      },
    }
  },
}
