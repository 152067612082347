import { defineStore } from 'pinia'

export const useSearchMobileStore = defineStore('searchMobile', () => {
  const active = ref(false)
  const initial = ref(true)

  function enable() {
    active.value = true
    // класс мы добавляем для того, чтобы сама страница не скроллилась при скролле окна
    document.body.classList.add('overflow-body')
  }

  function disable() {
    active.value = false
    initial.value = true
    document.body.classList.remove('overflow-body')
  }

  return { active, initial, enable, disable }
})
