import { useServiceStore } from '~~/store/service'

/**
 * Если проект запускается из под сайпреса, снимаем ограничения на рабочие часы,
 * добавляем $nuxt в window чтобы cypress мог обращаться к нему
 */
export default defineNuxtPlugin((nuxtApp) => {
  if (window.Cypress) {
    const serviceStore = useServiceStore()
    const { $axios } = useNuxtApp()

    serviceStore.setServiceUnavailable(false)
    serviceStore.schedule = {
      from: '00:01',
      to: '23:59',
    }

    $axios.interceptors.request.use((config) => {
      config.headers['x-testing-working-hours'] = 'working-hours'
      return config
    })

    // TODO: тайммаут для решения проблемы с тем что функции не подгружаются сразу
    // и после загрузки страницы не происходит никаких событий(клики итд)
    setTimeout(() => {
      window.$nuxt = nuxtApp
    }, 5000)
  }
})
