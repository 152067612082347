import Brand from '~/serializer/Brand'
import PropertyGroup from '~/serializer/product/PropertyGroup'
import Availability from '~/serializer/product/Availability'
import Delivery from '~/serializer/product/Delivery'
import Product from '~/serializer/product/Product'
import type { ProductExtended } from '~/type/product/Product'

interface ProductResponse {
  [key: string]: any
  ADDITIONAL?: {
    REMAINS: {
      ITEMS: any[]
    }
  }
  DELIVERIES?: {
    ITEMS: any[]
  }
  PAYMENTS?: {
    ITEMS: any[]
  }
  ACCESSORIES?: any[]
  MAIN_PROPERTIES?: any[]
  PROPERTIES?: any[]
}

export default function (el: ProductResponse): ProductExtended {
  function getComposition(props: any[]) {
    let composition
    const compositionName = 'Состав'

    props.forEach((el: any) => {
      if (el.PROPERTIES[compositionName])
        composition = el.PROPERTIES[compositionName]
    })

    return composition
  }

  function removeCompositionFromProperties(props: any) {
    const composition = 'Состав'

    if (props.PROPERTIES[composition])
      delete props.PROPERTIES[composition]

    return props
  }
  return {
    ...Product(el),
    code: el.CODE,
    brand: Brand(el.BRAND),
    availability: el.ADDITIONAL?.REMAINS?.ITEMS?.length ? el.ADDITIONAL.REMAINS.ITEMS.map((el: any) => Availability(el)) : [],
    delivery: el.DELIVERIES?.ITEMS?.length ? el.DELIVERIES.ITEMS.map((el: any) => Delivery(el)) : [],
    payment: el.PAYMENTS?.ITEMS?.length ? el.PAYMENTS.ITEMS.map((el: any) => ({ name: el.NAME, description: el.DESCRIPTION })) : [],
    thumb: el.IMAGES?.length ? el.IMAGES : [],
    description: el.DETAIL_TEXT,
    accessory: el.ACCESSORIES?.length ? el.ACCESSORIES?.map((el: any) => Product(el)) : [],
    guarantee: el.MAIN_PROPERTIES?.length ? el.MAIN_PROPERTIES.find((item: any) => item?.CODE === 'CML2_GARANT')?.VALUE : undefined,
    composition: el.PROPERTIES?.length ? getComposition(el.PROPERTIES) : undefined,
    propertyGroup: el.PROPERTIES?.length ? el.PROPERTIES.map((el: any) => PropertyGroup(removeCompositionFromProperties(el))) : [],
  }
}
