import { set } from 'date-fns'

/**
 * Устанавливает указанное время для переданной даты
 * @param time строка вида "08:00"
 */

export default function setTimeToDate(time: string, date: Date) {
  const [hours, minutes] = time.split(':').map(Number)
  const val = set(date, { hours, minutes, seconds: 0 })

  return val
}
