const requestSources: { [key: string]: AbortController } = {}

export function getAbortController(name: string) {
  if (requestSources[name])
    requestSources[name].abort()

  const controller = new AbortController()
  requestSources[name] = controller
  return requestSources[name]
}
