import type { StoryBanner } from '~/type/Story'

export default function (el: { [key: string]: any }): StoryBanner {
  return {
    storyId: el.CODE,
    banner: {
      desktop: el.DESKTOP_BANNER.BANNER,
      mobile: el.MOBILE_BANNER.BANNER,
    },
  }
}
