import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useResizeStore = defineStore('resize', () => {
  const isMobile = ref(false)
  const resize = ref<number>()

  function setIsMobile(payload: boolean) {
    isMobile.value = payload
  }

  function setResizeWindow(payload: number) {
    resize.value = payload
  }

  return { isMobile, resize, setIsMobile, setResizeWindow }
})
