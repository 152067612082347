import VueLazyload from 'vue-lazyload'
import blank from '~/components/ImgLazy/asset/blank.svg'
import empty from '~/components/ImgLazy/asset/empty.png'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueLazyload, {
    adapter: {
      loaded({ el }: { el: HTMLImageElement }) {
        el.srcset = el.dataset.srcset!
      },
      loading({ el, src }: { el: HTMLImageElement; src: string }) {
        el.srcset = blank
        el.src = src
      },
      error({ el, src }: { el: HTMLImageElement; src: string }) {
        el.srcset = empty
        el.src = src
      },
      preLoad: 1.8,
    },
  })
})
