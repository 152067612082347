import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Category } from '~/type/Category'

/*
  Стора для хранения списка подкатегорий на страницах просмотра товаров
*/
export const useSubCategoriesStore = defineStore('subcategory', () => {
  const categories = ref<Category[]>([])

  function setCategories(list: Category[]) {
    categories.value = list
  }

  return { categories, setCategories }
}, {
  persist: true,
})
