import type { Events } from '~~/plugins/5.emitter'

export default function productView(obj: Events['product-view']) {
  return {
    event: 'view_item',
    ecommerce: {
      currency: 'RUB',
      value: obj.price?.common?.value,
      items: [
        {
          item_name: obj.name,
          item_id: obj.id?.toString(),
          price: obj.price?.common?.value,
          // item_brand: obj.brand?.name, раньше передавался, но его нет в обычном товаре в каталоге и всём таком
          item_category: obj.category?.name,
          quantity: obj.quantity,
        },
      ],
    },
  }
}
