import { stringify } from 'qs'
import type { Address } from '~/type/Address'

interface DeliveryAreaAnalyticParams {
  phone: string
  address: string
  comment?: string
}

export default {
  setAddress(
    { lat, lon, city = 'Каспийск', district, street, house, areaCode }: Address,
  ): Promise<{ success: boolean; result: boolean }> {
    const { $axios } = useNuxtApp()

    function getStreet(district?: string, street?: string) {
      if (district && street)
        return `${district}, ${street}`
      else if (district)
        return district
      else if (street)
        return street
    }

    return $axios.put(
      '/api/v1/darkstore/delivery-address',
      {
        DELIVERY_AREA_CODE: areaCode ?? '03',
        ADDRESS: {
          COORDINATE: {
            LAT: lat,
            LON: lon,
          },
          CITY: city,
          STREET: getStreet(district, street),
          HOUSE: house,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  },
  sendDeliveryAreaAnalytic(
    { phone, address, comment }: DeliveryAreaAnalyticParams,
  ): Promise<{ success: boolean; result: boolean }> {
    const { $axios } = useNuxtApp()

    return $axios.post(`/api/v1/analytic/darkstore/delivery-address?${stringify({
      address,
      phone,
      comment,
    })}`)
  },
}
