import type { Category } from '~/type/Category'

export default function getBreadcrumbs(rootCategory: Category, path: string) {
  const incomingCategory = { ...rootCategory }
  const categoryItemsList: Category[] = [incomingCategory]
  incomingCategory.children = []

  function iteration(itemCategory: Category) {
    itemCategory.children.forEach((el) => {
      if (path?.includes(el.path)) {
        const item = el
        if (path === el.path) {
          categoryItemsList.push(item)
          item.children = []
        }
        else {
          categoryItemsList.push(item)
          iteration(item)
          item.children = []
        }
      }
    })
  }

  iteration(rootCategory)
  return categoryItemsList ?? []
}
