interface CreatedOrder {
  orderID: string
  payed: boolean
  paymentURL: string
}

export default function (el: { [key: string]: any } = {}): CreatedOrder {
  return {
    orderID: el.ORDER_ID,
    paymentURL: el.PAYMENT_URL,
    payed: el.ORDER_INFO?.PAYED,
  }
}
