<script setup lang="ts" generic="T">
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import 'swiper/css'

const props = withDefaults(defineProps<{
  list: T[]
  offsetBetweenElements?: number
  slidesPerGroup?: number
  overlay?: boolean
  hideControls?: boolean
}>(), {
  offsetBetweenElements: 0,
  slidesPerGroup: 1,
  overlay: false,
  hideControls: false,
})

const sliderEl = ref<HTMLElement>()
const slider = ref<Swiper | null>(null)

const buttonNext = ref<HTMLElement>()
const buttonPrev = ref<HTMLElement>()

const isShadow = ref<Boolean>(props.overlay)

function prev() {
  return slider.value?.slidePrev()
}
function next() {
  return slider.value?.slideNext()
}

defineExpose({
  prev,
  next,
})

onMounted(() => {
  slider.value = new Swiper(sliderEl.value!, {
    modules: [Navigation],
    spaceBetween: props.offsetBetweenElements,
    slidesPerView: 'auto',
    slidesPerGroup: props.slidesPerGroup,
    navigation: {
      nextEl: buttonNext.value,
      prevEl: buttonPrev.value,
    },
  })

  if (props.overlay) {
    slider.value.on('reachEnd', () => {
      isShadow.value = false
    })

    if (slider.value.isEnd)
      isShadow.value = false

    slider.value.on('fromEdge', () => {
      isShadow.value = props.overlay
    })
  }
})
</script>

<template>
  <div class="row-slider">
    <div ref="sliderEl" class="row-slider__slides">
      <div class="swiper-wrapper">
        <div
          v-for="(el, index) in list"
          :key="index"
          class="row-slider__slide swiper-slide"
          :style="{ 'margin-right': `${offsetBetweenElements}px` }"
        >
          <slot :el="el" />
          <slot
            v-if="index === list.length - 1"
            name="last"
          />
        </div>
      </div>
    </div>
    <div class="row-slider__nav" :class="{ 'row-slider__nav_hidden': hideControls }">
      <button ref="buttonNext" class="row-slider__button row-slider__button_next" />
      <button ref="buttonPrev" class="row-slider__button row-slider__button_prev" />
    </div>
    <div
      v-if="overlay"
      class="row-slider__overlay"
      :class="[{ 'row-slider__overlay_hide': !isShadow }]"
    />
  </div>
</template>

<style scoped lang="postcss">
.row-slider {
  position: relative;
  width: 100%;
  height: 100%;

  &:hover .row-slider__button {
    display: block;

    &:disabled {
      display: none;
    }
  }

  &__slides {
    width: 100%;
    overflow: hidden;
  }

  &__slide {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  &__nav {

    &_hidden {
      display: none;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    z-index: 3;
    display: none;
    width: 46px;
    height: 80px;
    user-select: none;
    background-color: #FAFAFAE5;
    border: none;
    border-radius: 2px 0 0 2px;

    &:hover {
      cursor: pointer;
    }

    &_prev,
    &_next {
      background-image: url('./asset/arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px;

      &:hover {
        background-image: svg-load('./asset/arrow.svg', fill=#EE272D);
      }
    }

    &_prev {
      left: -1px;
      transform: translateY(-50%) rotate(180deg);
    }

    &_next {
      right: 0;
      transform: translateY(-50%);
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: -1px;
    z-index: 2;
    width: 200px;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 1;
    transition: opacity 0.5s;

    &_hide {
      opacity: 0;
    }
  }
}
</style>
