<script setup lang="ts">
import { UI05Button, UI05Modal } from 'ui-05'
import { storeToRefs } from 'pinia'
import { useConfirmStore } from '~/store/modal/confirm'

const { active, title, desc, onConfirm, onReject } = storeToRefs(useConfirmStore())
</script>

<template>
  <UI05Modal
    :show="active"
    overlay-theme="light"
    @close="onReject"
  >
    <template #title>
      <span class="modal__title">{{ title }}</span>
    </template>
    <template #default>
      <p class="modal__desc">
        {{ desc }}
      </p>
      <div class="modal__btns">
        <UI05Button
          fluid
          theme="white"
          size="49"
          class="mr-14"
          @click="onReject"
        >
          Нет
        </UI05Button>
        <UI05Button
          fluid
          theme="turquoise"
          size="49"
          @click="onConfirm"
        >
          Да, отменить
        </UI05Button>
      </div>
    </template>
  </UI05Modal>
</template>

<style lang="postcss" scoped>
.modal {

  &__btns {
    display: flex;
  }

  &__title {
    font-size: 29px;
    font-weight: 500;
    line-height: 33px;
  }

  &__desc {
    max-width: 370px;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 21px;
  }
}
</style>
