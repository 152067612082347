import { defineStore } from 'pinia'

export const useChooseAddressStore = defineStore('chooseAddressModal', () => {
  const active = ref(false)
  const afterAddressSelect = ref<() => void>()

  function enable({ afterAddressSelect: afterAddressSelectParam }: { afterAddressSelect?: noop } = {}) {
    active.value = true
    if (afterAddressSelectParam)
      afterAddressSelect.value = afterAddressSelectParam
    document.body.classList.add('overflow-body')
  }
  function disable() {
    active.value = false
    afterAddressSelect.value = () => {}
    document.body.classList.remove('overflow-body')
  }

  return { active, afterAddressSelect, enable, disable }
})
