import { defineStore } from 'pinia'
import { isWithinInterval } from 'date-fns'
import setTimeToDate from '../helper/setTimeToDate'

export const useServiceStore = defineStore('service', () => {
  const serviceUnavailable = ref(false)

  /**
* Дата в формате "M/DD/YYYY, HH:MM:SS AM/PM" для 'Europe/Moscow'.
* Хранение строки обеспечивает корректное отображение времени работы Близко независимо от клиентской таймзоны.
*/
  const currentDate = ref<null | string>(null)
  const schedule = ref({
    from: '09:00',
    to: '21:00',
  })
  const traceParent = ref<string>()

  function setCurrentDate(payload: string) {
    currentDate.value = payload
  }

  function setServiceUnavailable(state: boolean) {
    serviceUnavailable.value = state
  }

  function setTraceParent(val: string) {
    traceParent.value = val
  }

  const isWorkingHours = computed(() => {
    if (currentDate.value) {
      const date = new Date(currentDate.value)

      const startTime = setTimeToDate(schedule.value.from, date)
      const endTime = setTimeToDate(schedule.value.to, date)

      const isInterval = isWithinInterval(date, { start: startTime, end: endTime })
      if (!isInterval || serviceUnavailable.value)
        return false
      else return true
    }
    else {
      return false
    }
  })

  return { currentDate, schedule, isWorkingHours, traceParent, setTraceParent, setCurrentDate, setServiceUnavailable, serviceUnavailable }
})
