<script setup lang="ts">
import debounce from 'lodash.debounce'
import { useResizeStore } from '~/store/resize'

const emit = defineEmits<{ (e: 'search', value: string): void; (e: 'stop-search'): void }>()
const route = useRoute()
const input = shallowRef<HTMLInputElement>()
const query = ref('')
const isMobile = computed(() => useResizeStore()?.isMobile)
const keyUp = ref(false)

defineExpose({
  blurInput,
  focusInput,
})

watch(() => keyUp.value, (value) => {
  if (value) {
    if (route.path.includes('search') || isMobile.value)
      query.value.length ? emit('search', query.value) : emit('stop-search')
    else
      query.value.length > 2 ? emit('search', query.value) : emit('stop-search')
  }
})

const setKeyUpDebounced = debounce(() => keyUp.value = true, 500)

function blurInput() {
  input.value!.value = ''
  input.value!.blur()
}

function focusInput() {
  input.value!.focus()
}

function setKeyDown() {
  if (query.value)
    keyUp.value = false
}
</script>

<template>
  <div class="search-bar">
    <div class="search-bar__main-field">
      <div class="search-bar__field-wrapper">
        <label class="search-field">
          <input
            ref="input"
            v-model.trim="query"
            type="text"
            class="search-field__input"
            placeholder="Поиск продуктов"
            @keydown="setKeyDown"
            @update:v-model="query = $event"
            @keyup="setKeyUpDebounced"
          >
          <div
            v-if="query"
            class="search-field__icon search-field__icon_close"
            @click="query = '', emit('stop-search')"
          />
          <span
            v-else
            class="search-field__icon search-field__icon_loupe"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.search-bar {
  display: flex;
  height: 45px;
  background-color: #fff;

  &__main-field {
    position: relative;
    flex-grow: 1;
  }

  &__field-wrapper {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
  }
}

.search-field{
  flex-grow: 1;
  margin-right: 69px;
  margin-left: 18px;
  border: none;
  outline: none;

  &__input{
    display: block;
    flex-grow: 1;
    width: 100%;
    height: 43px;
    font-size: 14px;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    border-top: none;
    border-right: 1px solid #eaeaea;
    border-bottom: none;
    border-left: none;
    outline: 0;

    &::-webkit-input-placeholder {
      color: #818181;
    }

    &::-moz-placeholder {
      color: #818181;
    }

    &:-ms-input-placeholder {
      color: #818181;
    }

    &:-moz-placeholder {
      color: #818181;
    }
  }

  &__icon {

    &_loupe {
      display: inline-block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background-image: svg-load('./asset/loupe.svg', fill=#1A1A1A);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 17px;
    }

    &_loupe,
    &_close{
      position: absolute;
      top: 50%;
      right: 24px;
      z-index: 2;
      cursor: text;
      transform: translateY(-50%);
    }

    &_close {
      display: inline-block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background-image: svg-load('./asset/close.svg', fill=#818181);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 13px;

      &:hover {
        background-image: svg-load('./asset/close.svg', fill=#eb0010);
      }
    }
  }
}

@media (max-width: 800px) {

  .search-bar{

    .search-field{

      margin: 0 12px;

      &__icon_close {
        right: 14px;
        display: block;
        background-size: 12px;
      }

      &__input{
        border-right: none;
      }

      &__icon_loupe {
        right: 14px;
        background-size: 15px;
      }
    }
  }
}
</style>
