<script setup lang="ts">
interface SupportItem {
  title: string
  href: string
}

const props = defineProps<{
  show: boolean
  items: SupportItem[]
}>()
const emit = defineEmits<{
  (e: 'overlay-close'): void
}>()

watch(() => props.show, (val) => {
  if (val) {
    document.body.classList.add('overflow-body')
  }
  else {
    if (document.body.classList.contains('overflow-body'))
      document.body.classList.remove('overflow-body')
  }
})
</script>

<template>
  <transition
    :duration="{ enter: 400, leave: 0 }"
    enter-active-class="fade-in-up-enter-active"
    leave-active-class="fade-out-down-leave-active"
  >
    <div
      v-if="show"
      class="support-modal__overlay"
      @click="emit('overlay-close')"
    >
      <div class="support-modal">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="support-modal__el support-modal__el_link"
        >
          <a
            :href="item.href"
            target="_blank"
            class="support-modal__link"
          >{{ item.title }}
          </a>
        </div>
        <div class="support-modal__el support-modal__el_close" @click="emit('overlay-close')">
          Закрыть
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.support-modal {
  box-sizing: border-box;
  width: 100%;
  max-width: 100% !important;
  margin-top: auto;
  background-color: transparent;
  border: none;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06);

  &__overlay {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: var(--z-above-modal);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    will-change: transform, opacity
  }

  &__link {
    color: #007AFF;
    text-decoration: none;
  }

  &__el {
    padding: 17px;
    font-size: 17px;
    line-height: 22px;
    color: #007AFF;
    text-align: center;
    background-color: #B3B3B3;
    border-bottom: 1px solid #383838;

    &_link {

      &:first-child {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }

      &:nth-child(3) {
        border-bottom-right-radius: 14px;
        border-bottom-left-radius: 14px;
      }
    }

    &_close {
      margin-top: 8px;
      font-weight: 500;
      border-radius: 14px;
    }
  }
}
</style>
