import { defineStore } from 'pinia'

export const useProductStore = defineStore('productModal', () => {
  const active = ref(false)
  const id = ref<string | null>(null)
  const path = ref<string | null>(null)

  function enable(data: { active: boolean; id: string; path?: string }) {
    active.value = data.active
    id.value = data.id
    if (data.path) {
      path.value = data.path
      history.pushState(null, '', data.path)
    }
    // класс мы добавляем для того, чтобы сама страница не скроллилась при скролле окна
    document.body.classList.add('overflow-body')
  }

  function disable(pathParam?: string) {
    active.value = false
    id.value = null
    path.value = pathParam || null
    document.body.classList.remove('overflow-body')
    history.pushState(null, '', pathParam)
  }

  return { active, id, path, enable, disable }
})
