<script setup lang="ts">
import ymaps from 'ymaps'
import type { Emitter } from 'mitt'
import type { Bounds, DivIconOptions, LatLng, LatLngBounds, LatLngTuple, Layer, Map } from 'leaflet'
import icon from './asset/marker-default.svg'
import icon2 from './asset/marker-selected.svg'
import { useResizeStore } from '~/store/resize'
import 'leaflet/dist/leaflet.css'
import type { Address } from '~/type/Address'
import type { MapsArea } from '~/type/MapsArea'

interface Props {
  /**
    * Массив маркеров, которые нужно поставить на карту, формат у каждого маркера такой же, как у пропса value
   */
  list?: { [ key: string ]: unknown }[]
  /**
    * Можно ли выбирать на карте любое место и на этом месте создавать новый маркер
  */
  allowSelectAny?: boolean
  center?: LatLngTuple
  zoom?: number
  zoomValueOnMarkerClick?: number
  isNotScroll?: boolean
  bounds?: Bounds[]
  zone?: MapsArea[]
  height?: string
  emitter?: Emitter
}

const props = withDefaults(defineProps<Props>(), {
  list: () => [],
  allowSelectAny: false,
  center: () => [42.88704, 47.63445],
  zoom: 12,
  zoomValueOnMarkerClick: 16,
  isNotScroll: true,
  height: '500px',
})
const emit = defineEmits<{ (event: 'change', address: Address): void }>()

const { $api, $error } = useNuxtApp()
const runtimeConfig = useRuntimeConfig()

const iconDefault = ref<DivIconOptions>()
const iconSelected = ref<DivIconOptions>()
const polygon = ref<LatLngBounds[]>([])
const mapReference = shallowRef<HTMLElement>()
let map: Map
let L: { [key: string]: any }

const isMobile = computed(() => useResizeStore().isMobile)

onMounted(async () => {
  await import('leaflet')
  try {
    await ymaps.load(`https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${runtimeConfig.public.yandexMap}`)
  }
  catch (e) {
    new $error.simple({
      message: 'Произошла ошибка при загрузке карты',
      description: 'Возможно сервис недоступен или исчерпан лимит обращений к апи',
      native: e,
    })
  }

  L = window.L // берем глобальный объект т.к. именно он модифицируется плагинами внешними

  await Promise.all([
    import('./wise-leaflet-pip.cjs'),
    import('./yandex.cjs'),
  ])

  window.requestAnimationFrame(() => {
    map = createMap()
    if (!map)
      return

    createLayer()
    createMarkers()
    if (props.zone) {
      props.zone.forEach((el) => {
        L.polygon(el?.polygon, { color: '#30C63F' }).addTo(map)
        polygon.value.push(L.polygon(el.polygon, { color: '#30C63F' }).addTo(map))
      })
    }

    const { lat, lng } = map.getCenter()
    const marker = L.marker([lat, lng], {
      icon: iconSelected.value,
    }).addTo(map)

    map.on('move', () => {
      marker.setLatLng(map.getCenter())
    })

    map.on('dragend', () => {
      marker.setLatLng(marker.getLatLng())
      onDragendStop({ latlng: marker.getLatLng() })
    })

    props.emitter?.on('changeMarkerPos', (latlng) => {
      marker.setLatLng(latlng)
      onDragendStop({ latlng })
      map.setView([latlng.lat, latlng.lng])
    })
  })
})

onUnmounted(() => {
  if (map) {
    map.off()
    map.remove()
  }
})

function createMarkers() {
  iconDefault.value = L.icon({
    iconUrl: icon,
    iconSize: [34, 55],
    iconAnchor: [17, 52],
  })

  iconSelected.value = L.icon({
    iconUrl: icon2,
    iconSize: [34, 55],
    iconAnchor: [17, 52],
  })
}

function createLayer(): Layer {
  return L.yandex().addTo(map)
}

function createMap(): Map {
  return L.map(mapReference.value, {
    attributionControl: false,
    gestureHandling: isMobile.value && props.isNotScroll,
    zoom: props.zoom,
    bounds: props.bounds,
    center: props.center,
  })
}

async function onDragendStop({ latlng }: { latlng: LatLng }) {
  try {
    const address = await $api.geo.getAddressByCoordinate({ lat: latlng.lat, lon: latlng.lng })
    emit('change', address)
  }
  catch (error) {
    new $error.base({
      message: 'Не удалось получить подсказки к запросу через яндекс',
      native: error,
    })
    try {
      const address = await $api.geo.getAddress({ count: 1, ...latlng })
      emit('change', address)
    }
    catch (e) {
      new $error.base({
        message: 'Не удалось получить подсказки к запросу через дадату',
        native: e,
      })
    }
  }
}
</script>

<template>
  <div
    ref="mapReference"
    :style="`height: ${height}`"
  />
</template>

<style lang="postcss">
/* по дефолту з-индекс слишком большой и он конфликтует с селектами и всем остальным */

.leaflet-pane {
  z-index: 1 !important;
}

.leaflet-overlay-pane {
  z-index: 2 !important;
}

.promo__delivery-map {

  .leaflet-left {
    left: 15px;
  }

  .ymaps-2-1-79-copyright {
    margin-right: 35px;
  }
}

.leaflet-bottom,
.leaflet-top {
  z-index: 2 !important;
}

.vue2leaflet-map {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-marker-pane {
  z-index: 2 !important;
}

.ymaps-2-1-78-map-copyrights-promo {
  display: none;
}

/* z-index оверлея для gesture-handling */

.leaflet-container:after {
  z-index: 1 !important;
}

@media (max-width: 800px) {

  .leaflet-control-container {
    display: none;
  }
}
</style>
