<script setup lang="ts">
import { useNotWorkingTimeModal } from '~/store/modal/notWorkingTime'
import { useServiceStore } from '~~/store/service'

withDefaults(defineProps<{
  type?: 'off-hours' | 'unavailable'
}>(), {
  type: 'off-hours',
})

const notWorkingTimeModalStore = useNotWorkingTimeModal()
const dateStore = useServiceStore()

function onOverlayClick(payload: Event) {
  const isOverlay = (payload.target as HTMLElement).classList.contains('unavailable-modal__overlay')
  if (isOverlay)
    notWorkingTimeModalStore.disable()
}
</script>

<template>
  <transition
    :duration="{ enter: 400, leave: 400 }"
    enter-from-class="fade-in-up-enter"
    enter-active-class="fade-in-up-enter-active"
    enter-to-class="fade-in-up-enter-to"
    leave-from-class="fade-out-down-leave"
    leave-active-class="fade-out-down-leave-active"
    leave-to-class="fade-out-down-leave-to"
  >
    <div
      v-if="notWorkingTimeModalStore.active && !notWorkingTimeModalStore.isShowUnavailableModal"
      class="unavailable-modal__overlay"
      @click="onOverlayClick"
    >
      <div class="unavailable-modal__modal">
        <div class="unavailable-modal__modal-close" @click="notWorkingTimeModalStore.disable" />
        <template v-if="type === 'unavailable'">
          <div class="unavailable-modal__image unavailable-modal__image_unavailable" />
          <div class="unavailable-modal__title">
            Сервис недоступен
          </div>
          <p class="unavailable-modal__text">
            Из-за технических неполадок, временно не принимаем заказы. Скоро мы все исправим
          </p>
        </template>
        <template v-else>
          <div class="unavailable-modal__image unavailable-modal__image_moon" />
          <div class="unavailable-modal__title">
            Ушли поспать до {{ dateStore.schedule.from }}
          </div>
          <p class="unavailable-modal__text">
            Пока мы не можем работать<br class="unavailable-modal__br">
            круглосуточно, но обязательно этому<br class="unavailable-modal__br">
            научимся. Вы сможете заказать<br class="unavailable-modal__br">
            продукты с {{ dateStore.schedule.from }}
          </p>
        </template>

        <button class="unavailable-modal__button" @click="notWorkingTimeModalStore.disable">
          Просто посмотрю что у вас есть
        </button>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.unavailable-modal {

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-above-modal);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* Оставляем 100vh, на случай, если не сработает -webkit-fill-available */
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding-right: 20px;
    margin-right: -20px;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1;
    will-change: transform, opacity;
  }

  &__modal {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 425px;
    padding: 35px 40px 40px;
    margin: auto;
    overflow-y: scroll;
    color: #fff;
    background: #2e2e2e;
    border-radius: 20px;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__image {
    width: 240px;
    height: 220px;
    margin: 0 auto 45px;
    background-repeat: no-repeat;
    background-position: 50% 58%;

    &_moon {
      background-image: url('./asset/moon.png');
    }

    &_unavailable {
      margin: 0 auto;
      background-image: url('./asset/close.png');
      background-size: 100%;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: 29px;
    font-weight: 500;
    line-height: 33px;
  }

  &__text {
    max-width: 350px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 20px;
    color: #A2A2A2;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 35px;
    font-size: 18px;
    line-height: 16px;
    color: #1a1a1a;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__modal-close {
    position: absolute;
    top: 25px;
    right: 25px;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    cursor: pointer;
    background-image: svg-load('./asset/close.svg', fill=#fff);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 12px;
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__br {
    display: none;
  }

  @media (max-width: 900px) {

    &__overlay {
      top: inherit;
    }

    &__modal {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      min-height: 100%;
      padding: 35px 15px 15px;
      margin-bottom: 0;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      border-radius: 20px 20px 0px 0px;
    }

    &__modal-close {
      display: none;
    }

    &__image {
      width: 180px;
      height: 180px;
      margin-bottom: 15px;
      background-position: 50% 70%;
      background-size: 280px 280px;

      &_unavailable {
        width: inherit;
        height: 200px;
        background-size: 250px 266px;
      }
    }

    &__title {
      margin-bottom: 15px;
      font-size: 19px;
      line-height: 21px;
    }

    &__text {
      max-width: 100%;
      margin-bottom: 15px;
      font-size: 14px;
    }

    &__button {
      font-size: 16px;
    }

    &__br {
      display: block;
    }
  }

  @media (max-width: 800px) {

    &__modal {
      margin: initial;
      /* Стиль для того чтобы прибивать модалки к низу на мобильном разрешении */
      margin-top: auto;
    }
  }
}

/* Кастомная анимация появления */
@keyframes customFadeInUp {

  from {
    transform: translateY(70px);
  }

  to {
    transform: translateY(0);
  }
}

/* Кастомная анимация исчезновения */
@keyframes customFadeOutDown {

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(70px);
  }
}

.fade-out-down-leave-active,
.fade-in-up-enter-active {
  transition: opacity 0.4s;

  .unavailable-modal__modal {
    animation: 0.3s customFadeInUp;
  }
}

.fade-in-up-enter,
.fade-out-down-leave-active {
  transition: opacity 0.5s;

  .unavailable-modal__modal {
    animation: 0.5s customFadeOutDown;
  }
}
</style>
