<script setup lang="ts">
interface Props {
  interactive?: boolean
  isActive?: number
  showStatus?: boolean
  size?: '12' | '15' | '20' | '22' | '24' | '32' | '45'
  mobileSize?: '12' | '15' | '18' | '20' | '22' | '24' | '25' | '32' | '45'
  yellow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  interactive: false,
  isActive: 0,
  showStatus: false,
  size: '15',
  yellow: false,
})

const emit = defineEmits<{ (e: 'update:modelValue', value: number): void }>()

const count = ref(props.isActive)
const hoverCount = ref(props.isActive)

const status = computed(() => {
  switch (count.value) {
    case 1:
      return 'Плохо'
    case 2:
      return 'Не понравился'
    case 3:
      return 'Понравился'
    case 4:
      return 'Хорошо'
    case 5:
      return 'Рекомендует этот товар'
    default:
      return ''
  }
})

watch(() => props.isActive, () => {
  count.value = props.isActive
  hoverCount.value = props.isActive
})

function onClick(i: number) {
  count.value = i
  hoverCount.value = i
  emit('update:modelValue', count.value)
}

function onMouseove(i: number) {
  hoverCount.value = count.value ? count.value : i
}
</script>

<template>
  <div
    class="product-rating"
    :class="[
      `product-rating_h-${size}`,
      `product-rating_mobile-h-${mobileSize ?? size}`,
      { 'product-rating_interactive': interactive },
    ]"
    @mouseout="interactive && (hoverCount = count)"
  >
    <div
      v-for="el in 5"
      :key="el"
      class="product-rating__el-wrap"
      @click="interactive && onClick(el)"
      @mousemove="interactive && onMouseove(el)"
    >
      <div
        class="product-rating__el"
        :class="[
          { 'product-rating__el_hover': hoverCount >= el, 'product-rating__el_active': count >= el, 'product-rating__el_yellow': yellow },
        ]"
      />
    </div>
    <div
      v-if="showStatus"
      class="product-rating__text"
      :class="[{ 'product-rating__text_orange': isActive === 5 }]"
    >
      {{ status }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.product-rating {
  display: flex;

  &_interactive {
    cursor: pointer;

    ^&__el {
      text-align-last: left;
    }
  }

  &_h-12 {

    ^&__el {
      width: 12px;
      height: 12px;

      &-wrap {
        padding-right: 2px;
      }
    }

    ^&__text {
      line-height: 13px;
    }
  }

  &__el {
    box-sizing: content-box;
    flex-shrink: 0;
    background-image: svg-load('./asset/rating.svg', fill=#e0e0e0);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &_yellow {
      background-image: svg-load('./asset/rating.svg', fill=#eee7ca);
    }

    &_hover {
      background-image: svg-load('./asset/rating.svg', fill=#F6DCA5);
    }

    &_active {
      background-image: svg-load('./asset/rating.svg', fill=#FDCA54);
    }

    &-wrap {
      flex-shrink: 0;
    }

    &-wrap:last-child {
      padding-right: 0;
    }
  }

  &_h-15 &__el {
    width: 15px;
    height: 15px;

    &-wrap {
      padding-right: 2px;
    }
  }

  &_h-20 &__el {
    width: 20px;
    height: 20px;

    &-wrap {
      padding-right: 3px;
    }
  }

  &_h-22 &__el {
    width: 22px;
    height: 22px;

    &-wrap {
      padding-right: 3px;
    }
  }

  &_h-24 &__el {
    width: 24px;
    height: 24px;

    &-wrap {
      padding-right: 3px;
    }
  }

  &_h-32 &__el {
    width: 32px;
    height: 32px;

    &-wrap {
      padding-right: 4px;
    }
  }

  &_h-45 &__el {
    width: 45px;
    height: 45px;

    &-wrap {
      padding-right: 10px;
    }
  }

  &__text {
    position: relative;
    display: inline-block;
    margin-left: 3px;
    font-size: 11px;
    font-style: italic;
    line-height: 17px;
    opacity: 0.5;

    &_orange {
      color: #e2ad33;
      opacity: 1;
    }
  }
  @media (max-width: 800px) {

    &_mobile-h-18 &__el {
      width: 18px;
      height: 18px;

      &-wrap {
        padding-right: 4px;
      }
    }

    &_mobile-h-25 &__el {
      width: 25px;
      height: 25px;

      &-wrap {
        padding-right: 5px;
      }
    }

    &__el-wrap:last-child {
      padding-right: 0;
    }
  }
}
</style>
