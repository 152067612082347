<script setup lang="ts">
import { UI05Button, UI05Form, UI05FormElement, UI05Modal } from 'ui-05'
import { storeToRefs } from 'pinia'
import type { Form } from 'vee-validate'
import { useAuthStore } from '~/store/modal/auth'
import { useTokenStore } from '~/store/token'
import type { Token } from '~/type/Token'
import type { User } from '~/type/User'
import Auth from '~/helper/Auth'
import { injectRecaptcha } from '~/helper/injectRecaptcha'

const { $emitter, $api, $error } = useNuxtApp()
const inputPhone = shallowRef<{ $el: HTMLInputElement }>()
const inputCode = shallowRef<{ $el: HTMLInputElement }>()
const formRefPhone = shallowRef<typeof Form>()

const runtimeConfig = useRuntimeConfig()

const authData = ref({
  code: '',
})

const phone = ref('')
const activeTypeBlock = ref<'phone' | 'code'>('phone')
const { active } = storeToRefs(useAuthStore())
const { token } = storeToRefs(useTokenStore())
const convertedCode = computed(() => authData.value.code?.replace(/\D/g, ''))

const login = useActionEntity($api.auth.login, {
  params: { ...authData, accessToken: token.value!.accessToken.value },
  onSuccess,
})

const sendConfirmCode = useActionEntity($api.auth.sendConfirmCode, {
  params: { phone: phone.value, short: true },
  onSuccess: () => activeTypeBlock.value = 'code',
  onError: () => {
    const resetWatcher = watch(phone, () => {
      // сброс ошибки при изменении номера
      sendConfirmCode.changeStatus('initial')
      resetWatcher()
    })
  },
})

const checkCodeAndLogin = useActionEntity($api.auth.checkCodeAndLogin, {
  params: { code: convertedCode.value, type: 'sms', accessToken: token.value!.accessToken.value },
  onSuccess,
})

onMounted(() => {
  injectRecaptcha(runtimeConfig.public.googleRecaptchaSiteKey)
})

watch(active, (isModalShow) => {
  if (isModalShow) {
    if (activeTypeBlock.value === 'phone') {
      setTimeout(() => {
        nextTick(() => focus(inputPhone.value?.$el))
      }, 300)
    }
  }
  else {
    // обнуляем данные composable-ов при скрытии модалки
    const apiList = [login, sendConfirmCode, checkCodeAndLogin]
    apiList.forEach(apiMethod => apiMethod.changeStatus('initial'))
  }
})

watch(activeTypeBlock, (type) => {
  if (type === 'phone')
    nextTick(() => focus(inputPhone.value?.$el))
  else if (type === 'code')
    nextTick(() => focus(inputCode.value?.$el))
})

const { disable: disableAuthModal } = useAuthStore()

function onSubmit({ form }:
{ form: typeof formRefPhone.value },
) {
  if (runtimeConfig.public.deployEnvironment === 'production') {
    grecaptcha.ready(() => {
      grecaptcha.execute(runtimeConfig.public.googleRecaptchaSiteKey, { action: 'LOGIN' })
        .then((token: string) => {
          form && form?.validate().then(async ({ valid }: { valid: boolean }) => {
            if (valid) {
              await sendConfirmCode.request({
                phone: phone.value,
                short: true,
                recaptchaToken: token,
              })
            }
          })
        })
        .catch((e: unknown) => {
          new $error.simple({
            message: 'Ошибка отправки кода подтверждения',
            native: e,
          })
        })
    })
  }
  else {
    form && form?.validate().then(async ({ valid }: { valid: boolean }) => {
      if (valid) {
        await sendConfirmCode.request({
          phone: phone.value,
          short: true,
        })
      }
    })
  }
}

function close(state = false) {
  disableAuthModal(state)
}

function reset() {
  activeTypeBlock.value = activeTypeBlock.value === 'code' ? 'phone' : activeTypeBlock.value
  phone.value = ''
  authData.value = {
    code: '',
  }
}

function focus(el?: HTMLInputElement) {
  window.requestAnimationFrame(() => {
    window.requestAnimationFrame(() => {
      el?.focus()
    })
  })
}

async function onSuccess(
  { data }: { data: { token: Token; user: User; favorite: { [key: string]: boolean } } }) {
  Auth({ response: data })

  $emitter.emit('auth', {
    phone: phone.value,
    activeTypeBlock: activeTypeBlock.value,
  })

  window.setTimeout(() => {
    close(true)
    reset()
  }, 300)
}

function onCodeInput() {
  (convertedCode.value?.length === 4 && token.value)
    && checkCodeAndLogin.request(
      { code: convertedCode.value, type: 'sms', accessToken: token.value.accessToken.value },
    )
}

function getTimeSendConfirmCode(seconds: number) {
  return seconds < 10 ? `0:0${seconds}` : `0:${seconds}`
}
</script>

<template>
  <KeepAlive>
    <UI05Modal
      v-if="active"
      max-width="380px"
      :show="true"
      class="auth-modal"
      :mobile-fullscreen="true"
      @close="close"
    >
      <template #title>
        <button
          v-if="activeTypeBlock === 'code'"
          type="button"
          class="auth-modal__backlink"
          name="back-to-phone"
          @click="activeTypeBlock = 'phone'"
        />

        <h4 class="h4 auth-modal__title">
          {{ activeTypeBlock === 'code' ? 'Введите код' : 'Авторизация' }}
        </h4>
      </template>
      <UI05Form
        v-if="activeTypeBlock === 'code'"
        type="vertical"
      >
        <p class="p auth-code__note">
          Код для авторизации отправлен на номер:
          <span class="auth-code__phone-text">
            {{ phone }}
          </span>
        </p>
        <div class="auth-modal__input-code-wrapper">
          <UI05FormElement :error="sendConfirmCode.errorValue.value?.description">
            <InputCode
              ref="inputCode"
              data-e2e="login-code-input"
              class="auth-code__input"
              :model-value="authData.code"
              :loading="checkCodeAndLogin.loading.value"
              :error="checkCodeAndLogin.error.value"
              :success="checkCodeAndLogin.success.value"
              @update:model-value="$event && (authData.code = $event), onCodeInput()"
            />
            <ErrorNote
              v-if="checkCodeAndLogin.error.value && checkCodeAndLogin.errorValue.value"
              :error="checkCodeAndLogin.errorValue.value"
              class="mt-20 p"
            />
            <template #helper>
              <span class="auth-code__input-note">Не получили код в течение 10 минут? Повторите запрос</span>
            </template>
          </UI05FormElement>
        </div>

        <Timer v-slot="{ time, reactivate }" :time="59">
          <UI05Button
            size="49"
            :disabled="time !== 0"
            fluid
            theme="turquoise"
            name="get-new-code"
            @click="
              reactivate(),
              sendConfirmCode.request({ phone, short: true })"
          >
            Получить новый код
            <span v-if="time !== 0" class="ml-5">
              {{ getTimeSendConfirmCode(time) }}
            </span>
          </UI05Button>
        </Timer>
      </UI05Form>
      <div v-if="activeTypeBlock === 'phone'">
        <p class="p auth-phone__note">
          Введите номер телефона, и мы отправим код для авторизации
        </p>
        <ValidationForm
          ref="formRefPhone"
          v-slot="{ handleSubmit, setFieldValue }"
          as="div"
        >
          <UI05Form
            type="vertical"
            @submit="handleSubmit($event, onSubmit({
              form: formRefPhone,
            }))"
          >
            <ValidationField
              v-slot="{ errors, handleChange, field }"
              rules="required|phone"
              name="phone"
            >
              <UI05FormElement
                class="auth-phone__input"
                :error="errors[0] || sendConfirmCode.errorValue.value?.description"
              >
                <InputPhone
                  ref="inputPhone"
                  :model-value="phone"
                  data-e2e="login-number-input"
                  @submit="
                    setFieldValue('phone', phone),
                    handleSubmit($event, onSubmit({
                      form: formRefPhone,
                    }))"
                  @update:model-value="
                    (phone = $event),
                    field.value ? handleChange(phone) : ''"
                  @blur="handleChange(phone)"
                />
              </UI05FormElement>
            </ValidationField>
            <div class="mb-12">
              <UI05Button
                v-if="sendConfirmCode.loading.value"
                theme="turquoise"
                loading
                size="49"
                fluid
              />
              <UI05Button
                v-else
                theme="turquoise"
                fluid
                size="49"
                name="get-code"
              >
                Получить код
              </UI05Button>
            </div>
          </UI05Form>
        </ValidationForm>
      </div>
      <div class="auth-modal__terms">
        Нажимая кнопку "Получить код", Вы соглашаетесь с условиями
        <nuxt-link
          class="link link_blue link_wu"
          to="/about/privacy/"
          @click="close"
        >
          конфиденциальности
        </nuxt-link>
        и
        <nuxt-link
          class="link link_blue link_wu"
          to="/about/terms-of-use/"
          @click="close"
        >
          обработки персональных данных
        </nuxt-link>
      </div>
    </UI05Modal>
  </KeepAlive>
</template>

<style lang="postcss" scoped>
.auth-modal {

  &__backlink {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: transparent;
    background-image: svg-load('./asset/arrow-left.svg', fill=#818181);
    background-repeat: no-repeat;
    background-position: left 4px center;
    background-size: auto 12px;
    border: none;

    &:hover {
      background-image: svg-load('./asset/arrow-left.svg', fill=#ee272d);
      transition: background-image 0.2s ease-out;
    }
  }

  &__title {
    flex-grow: 1;
  }

  &__input-code-wrapper {
    margin-top: 14px;
  }

  &__terms {
    margin-top: 20px;
    margin-bottom: 14px;
    font-size: 13px;
    line-height: 14px;
  }

  @media (max-width: 800px) {

    &__title {
      font-size: 23px;
      line-height: 25px;
    }
  }
}

.auth-phone {

  &__note {
    margin-bottom: 8px;
  }

  &__input{
    margin-bottom: 10px;
  }

  @media (max-width: 800px) {

    &__note {
      margin-bottom: 39px;
    }

    &__input {
      margin-bottom: 29px;
    }
  }
}

.auth-code {

  &__note {
    margin-bottom: 14px;
  }

  &__input-note {
    display: inline-block;
    margin-top: 5px;
  }

  &__phone-text {
    display: inline-block;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 500;
  }

  @media (max-width: 800px) {

    &__note {
      margin-bottom: 6px;
    }

    &__terms {
      margin-top: 17px;
      margin-bottom: 10px;
    }

    &__actions {
      margin-top: -4px;
    }
  }
}
</style>

<style lang="postcss">
.auth-modal{

  .modal__head{
    align-items: center;
    margin-bottom: 16px;
  }

  .modal__close{
    opacity: 0.5;
  }
}
</style>
