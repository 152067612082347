import Thumbnail from './Thumbnail'
import type { Category } from '~/type/Category'

export interface CategoryResponseType {
  [key: string]: any
  THUMBNAILS?: any[]
  CHILDREN?: any[]
  SECTION_PAGE_PATH: string
  PRODUCTS_COUNT: number
  ADDITIONAL_IMAGES: { [key: string]: any }
}

export default function CategorySerializer(el: CategoryResponseType): Category {
  return {
    id: el?.ID,
    /**
     * На странице товара, el приходит с апи в виде строки с названием категории. На остальных страницах в виде объекта
     * Поэтому делаем проверку, если это объект, то берем у него свойство NAME, иначе просто el
     */
    name: el?.NAME || el,
    thumbnail: Thumbnail(el?.THUMBNAILS),
    image: el?.IMAGE,
    path: el?.sale ? `/sale${el?.SECTION_PAGE_PATH}` : el?.SECTION_PAGE_PATH,
    children: el?.CHILDREN?.length ? el.CHILDREN.map((item: any) => CategorySerializer({ ...item, sale: el.sale })) : [],
    productsCount: el?.PRODUCTS_COUNT,
    categoryImages: {
      mobile: {
        small: el?.ADDITIONAL_IMAGES?.MOB_SMALL,
        large: el?.ADDITIONAL_IMAGES?.MOB_LARGE,
      },
      desktop: {
        small: el?.ADDITIONAL_IMAGES?.DESK_SMALL,
        medium: el?.ADDITIONAL_IMAGES?.DESK_MEDIUM,
        large: el?.ADDITIONAL_IMAGES?.DESK_LARGE,
      },
    },
  }
}
