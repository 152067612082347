import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Product } from '~/type/product/Product'
import type { Checkout, CheckoutPayment } from '~/type/order/Checkout'
import type { PaymentCard } from '~/type/PaymentCard'
import type { Cart } from '~/type/Cart'

interface Total {
  delivery: number
  product: number
  sale: number
  total: number
}

export const useCartStore = defineStore('cart', () => {
  const state = ref<Cart>()
  const price = ref<Total>({
    delivery: 0,
    sale: 0,
    product: 0,
    total: 0,
  })
  const freeDeliveryPrice = ref(0)
  const payment = ref<CheckoutPayment[]>([])
  const promocode = ref<string>()
  const replacement = ref<string>()
  const cards = ref<PaymentCard[]>([])
  const otherProduct = ref<Product[]>([])
  /* оверлей при удалениие товара из корзины */
  const showOverlay = ref(false)

  const list = computed<Product[]>(() => state.value ? Object.values(state.value) : [])

  const cartAmount = computed<number>(() => state.value
    ? Object.values(state.value).reduce((acc, product) => {
      return (acc += product.quantity)
    }, 0)
    : 0)

  const cartChanged = computed(() => list.value.length
    ? list.value.some(
      product => (typeof product.oldQuantity === 'number' && product.oldQuantity !== product.quantity) || product.limit === 0,
    )
    : false)

  const changedList = computed(() => list.value.length
    ? list.value.filter(product => (typeof product.oldQuantity === 'number' && product.oldQuantity !== product.quantity) || product.limit === 0)
    : [])

  function isProductExistByID(id: string) {
    return !!state.value?.[id]
  }

  function getProductQuantityByID(id: string) {
    return state.value?.[id]?.quantity || 0
  }

  function getProductByID(id: string) {
    return state.value?.[id] || null
  }

  function setCart(data: Checkout) {
    state.value = data.product
    price.value = data.price
    promocode.value = data.promocode
    cards.value = data.cards
    replacement.value = data?.replacement[0]?.id
    freeDeliveryPrice.value = data.freeDeliveryPrice
    payment.value = data.payment
    otherProduct.value = data.otherProduct
  }

  function add({ product }: { product: Product }) {
    state.value = {
      ...state.value,
      [product.id]: product,
    }
  }

  function reset() {
    state.value = {}
    price.value = {
      delivery: 0,
      sale: 0,
      product: 0,
      total: 0,
    }
    promocode.value = undefined
  }

  return { state, list, showOverlay, price, promocode, cards, replacement, cartAmount, freeDeliveryPrice, payment, otherProduct, cartChanged, changedList, isProductExistByID, getProductQuantityByID, setCart, add, reset, getProductByID }
}, {
  share: {
    enable: true,
  },
})
