import { stringify } from 'qs'
import SliderSerializer from '~/serializer/Slider'
import MetaBlockSerializer from '~/serializer/MetaBlock'
import ProductPageSerializer from '~/serializer/page/ProductPage'
import CategorySerializer from '~/serializer/CategoryBase'
import ProductSerializer from '~/serializer/product/Product'
import Pagination from '~/serializer/Pagination'
import SortSerializer from '~/serializer/Sort'
import type { Product } from '~/type/product/Product'
import type Sort from '~/type/Sort'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import getBreadcrumbs from '~/helper/getBreadcrumbs'

export default {
  async getByID(
    { id, path, reviewSize = 1, questionSize = 1 }: { id: string; path: string; reviewSize?: number; questionSize?: number },
  ) {
    const { $axios } = useNuxtApp()
    // параметр code добавили, чтобы не возникало коллизий, если есть товары с одинаковыми id и code
    const { data } = await $axios.get(
      `/api/v1/catalog/products/${id}?${stringify({
        reviews_size: reviewSize,
        questions_size: questionSize,
        not_load: 'BASKET_AMOUNT,IN_BASKET,IN_FAVORITES,IN_COMPARE', // у нас есть пользовательские списки, инфа с бэка не нужна
      })}`,
    )

    function getCategory(current: any): { id: string; name: string } {
      return {
        id: current?.IBLOCK_SECTION_ID,
        name: current?.SECTION,
      }
    }

    return {
      product: ProductPageSerializer(data.result?.PRODUCT),
      category: getCategory(data.result?.PRODUCT),
      breadcrumb: getBreadcrumbs(CategorySerializer(data.result?.PRODUCT.SECTION_NAV_CHAIN?.[0]), path),
      similar: SliderSerializer(data.result?.PRODUCT?.ADDITIONAL_PRODUCTS?.[0]),
      viewed: SliderSerializer(data.result?.PRODUCT?.ADDITIONAL_PRODUCTS?.[1]),
      meta: MetaBlockSerializer(data.result?.PRODUCT?.META),
    }
  },
  async getNewProducts({ page = 1, size = 36, sort }: { page?: number; size?: number; sort?: string }) {
    const { $axios } = useNuxtApp()

    const { data } = await $axios.get<APIRequestSuccess<{ PAGINATION: any; ITEMS: any[]; SORT_TYPES: any[] }>>(
      `/api/v1/darkstore/new-products?${stringify({
        page,
        size,
        sort,
      })}`,
    )

    const list: Product[] = data.result?.ITEMS?.length ? data.result.ITEMS.map((el: any) => ProductSerializer(el)) : []
    const pagination = Pagination(data.result?.PAGINATION)
    const sortList: Sort[] = data.result?.SORT_TYPES?.map((el: any) => SortSerializer(el)) ?? []

    return {
      title: 'Новинки',
      list,
      pagination,
      sort: sortList,
    }
  },
  async getPreviouslyOrderProducts() {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get<APIRequestSuccess<any[]>>('/api/v1/darkstore/user/orders/products')

    const productList: Product[] = Array.isArray(data?.result) ? data.result.map((el: any) => ProductSerializer(el)) : []
    return { productList }
  },
}
