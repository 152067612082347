<script setup lang="ts">
import type Sort from '~/type/Sort'

defineProps<{
  list: Sort[]
  value: Sort
}>()

const emit = defineEmits<{ (e: 'sort', value: Sort): void }>()

function onSort(el: Sort) {
  emit('sort', el)
}
</script>

<template>
  <div class="sort">
    <div class="sort__title">
      Сортировать:
    </div>
    <div
      v-for="el in list"
      :key="el.value"
      class="sort__el"
      :class="[{ sort__el_active: value.value === el.value }]"
      data-e2e="sort-el"
      @click="onSort(el)"
    >
      {{ el.name }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.sort {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -2px;

  &__title {
    display: flex;
    margin-right: 16px;
    line-height: 19px;
  }

  &__el {
    margin-right: 14px;
    line-height: 19px;
    cursor: pointer;

    &:hover {
      color: #eb0010;
    }

    &_active {
      color: #eb0010;
    }
  }

  @media (max-width: 800px) {

    &__title {
      width: 100%;
      font-weight: 500;
    }

    &__el {
      margin-right: 9px;
    }
  }
}
</style>
