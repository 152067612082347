import search from './search.api'
import auth from './auth.api'
import cart from './cart.api'
import order from './order.api'
import catalog from './catalog'
import constructor from './constructor.api'
import product from './product.api'
import map from './map.api'
import slider from './slider.api'
import user from './user.api'
import geo from './geo.api'
import story from './story.api'
import selection from './selection.api'
import favorite from './favorite.api'
import token from './token.api'
import recipe from './recipe.api'

export default function generateApi() {
  return {
    token,
    search,
    auth,
    cart,
    map,
    order,
    favorite,
    catalog,
    constructor,
    product,
    recipe,
    slider,
    user,
    geo,
    story,
    selection,
  }
}
