import type { Events } from '~~/plugins/5.emitter'

export default function cartProductRemoveList(obj: Events['cart-product-remove-list']) {
  return {
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'RUB',
      value: obj.list?.length
        ? obj.list.reduce((acc, sum) => { return acc = acc + (sum?.price?.common?.value || 0) }, 0)
        : 0,
      items: obj.list?.length
        ? obj.list.map((product) => {
          const path = product.path?.match(/\/([^/]+)\/?$/)
          return {
            item_name: product.name,
            item_id: product.id || (path && path[1]),
            price: product.price?.common?.value,
            // item_brand: product.brand?.name, раньше передавался, но его нет в обычном товаре в каталоге и всём таком
            item_category: product.category?.name,
            quantity: product.quantity,
          }
        })
        : [],
    },
  }
}
