import type { RecipeStep } from '~/type/Recipe'

export function RecipeStepSerializer(el: { [key: string]: any }): RecipeStep {
  return {
    number: el.STEP_NUM,
    description: el.DESCRIPTION,
    ingredients: el.USED_INGREDIENTS,
    image: {
      desktop: el.DESKTOP_BANNER.BANNER,
      mobile: el.MOBILE_BANNER.BANNER,
    },
    thumbnails: {
      desktop: {
        x1: el.DESKTOP_BANNER.THUMBNAILS['1X'],
        x2: el.DESKTOP_BANNER.THUMBNAILS['2X'],
      },
      mobile: {
        x1: el.MOBILE_BANNER.THUMBNAILS['1X'],
        x2: el.MOBILE_BANNER.THUMBNAILS['2X'],
      },
    },
  }
}
