import { defineStore } from 'pinia'
import { removeCookie } from 'tiny-cookie'
import { useFavoriteStore } from './favorite'
import type { User } from '~/type/User'
import { useTokenStore } from '~/store/token'

export const useUserStore = defineStore('user', () => {
  const tokenStore = useTokenStore()
  const favoriteStore = useFavoriteStore()
  const config = useRuntimeConfig()
  const data: Ref<null | User> = ref(null)

  function set(payload: User) {
    data.value = payload
  }

  async function logout() {
    const { $api } = useNuxtApp()
    try {
      const newToken = await $api.token.reset({ refreshToken: tokenStore.token!.refreshToken.value })
      tokenStore.set(newToken)
      favoriteStore.setList({ list: {} })
    }
    catch (e) {
      throw new Error('Ошибка при сбросе токена')
    }

    $reset()

    /**
     * удаляем расшаренный токен, если он есть, потому что мы только что разлогинились и он больше не актуален
     * интересно что universal-cookie не удаляет эту куку, поэтому используем сторонний пакет
     *
     * TODO избавить от лишней зависимости при переходе на Nuxt 3
     */
    removeCookie('user-access-token', {
      domain: config.public.crossAuthDomain,
    })
  }

  function $reset() {
    data.value = null
    favoriteStore.setList({ list: {} })
  }

  const name = computed(() => data.value?.name || data.value?.login || `Пользователь ${data.value?.id || ''} `)
  const isGuest = computed(() => !data.value)
  const isUser = computed(() => !!data.value)

  return { data, name, isUser, isGuest, set, $reset, logout }
}, {
  persist: true,
  share: {
    enable: true,
  },
})
