import { AxiosError } from 'axios'
import { UI05Tooltip } from 'ui-05'
import * as Sentry from '@sentry/vue'
import { ErrorSerializer } from '~/serializer/Error'
import type ErrorType from '~/type/Error'
import { useUserStore } from '~/store/user'

type ErrorConstrutorParam = ErrorType & { loggerTitle?: string }

export default defineNuxtPlugin((nuxtApp) => {
  const $config = useRuntimeConfig()
  const userStore = useUserStore()

  nuxtApp.vueApp.config.errorHandler = (e) => {
    if (e instanceof AxiosError) {
      const error = ErrorSerializer(e)
      new BaseError({ ...error, loggerTitle: 'Ошибка в глобальном обработчике вью' })
    }
    else {
      new BaseError({ native: e, message: 'Ошибка в глобальном обработчике вью', loggerTitle: 'Ошибка в глобальном обработчике вью' })
    }
  }

  if (process.client) {
    window.onunhandledrejection = (e: any) => {
      if (e instanceof AxiosError) {
        const error = ErrorSerializer(e)
        new BaseError({ ...error, loggerTitle: 'Ошибка в глобальном обработчике window.onunhandledrejection' })
      }
      else {
        new BaseError({ native: e, message: 'Ошибка в глобальном обработчике window.onunhandledrejection', loggerTitle: 'Ошибка в глобальном обработчике window.onunhandledrejection' })
      }
    }
  }

  class BaseError extends Error {
    constructor(arg: ErrorConstrutorParam) {
      super(arg.message)
      Error.captureStackTrace?.(this, BaseError)

      this.name = 'BaseError'
      this.sendErrorToSentry(arg)
      this.log(arg)
    }

    sendErrorToSentry(e: ErrorConstrutorParam) {
      if ($config.public.deployEnvironment !== 'production')
        return

      if (Sentry
        && typeof Sentry?.setUser === 'function'
        && typeof Sentry?.setContext === 'function'
        && typeof Sentry?.captureException === 'function') {
        if (userStore?.data) {
          const { name, login, email, id } = userStore?.data

          Sentry.setUser({
            name,
            login,
            email,
            id,
          })
        }

        Sentry.setContext('Error description', e)
        Sentry.captureException(e?.message || 'произошла ошибка')
      }
      else {
        console.log('Sentry is not initialized, cannot send the error to it:', e)
      }
    }

    log(e: ErrorConstrutorParam) {
      console.log(`%c${e?.loggerTitle || 'Произошла ошибка'}`, 'font-size:17px;color:red')
      console.log(e)
      if (e?.native) {
        if (process.client)
          console.error(e.native)
        else
          console.log(e.native)
      }
    }
  }

  class PageError extends BaseError {
    constructor({ type, name, message, code = 500, native }: ErrorConstrutorParam) {
      super({ type, name, message, code, native, loggerTitle: 'Ошибка на странице' })
      this.name = 'PageError'
      showError({ statusCode: code, message })
    }
  }

  class SimpleError extends BaseError {
    constructor({ type, name = 'Произошла ошибка', message = 'Информация отправлена разработчикам', code, description, native }: ErrorConstrutorParam) {
      super({ type, name, message, description, code, native })
      this.name = 'SimpleError'
      if (process.client) {
        UI05Tooltip({
          type: 'error',
          title: name,
          description,
        })
      }
    }
  }

  return {
    provide: {
      error: {
        base: BaseError,
        simple: SimpleError,
        page: PageError,
      },
    },
  }
})
