import { stringify } from 'qs'
import { getFormData } from '@artmizu/utils'
import UserSerializer from '~/serializer/User'
import Question from '~/serializer/Question'
import PaymentCard from '~/serializer/PaymentCard'

interface UserPersonalType {
  name?: string
  lastName?: string
  birthday?: string
  gender?: string
  email?: string
  socials?: {
    telegram?: string
  }
}

export default {
  async get() {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.get<{ result: { FAVORITE_PRODUCTS: { [key: string]: string } } }>('/api/v2/user', {
      baseURL: runtimeConfig.public.baseUrl,
    })

    const favoriteList: { [key: string]: boolean } = {}
    Object.values(data.result?.FAVORITE_PRODUCTS || {}).forEach(el => favoriteList[el] = true)
    const favorite: { [key: string]: boolean } = favoriteList

    return {
      result: {
        user: UserSerializer(data.result || {}),
        favorite,
      },
    }
  },

  changeEmail(data: { email: string }) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    return $axios.post(
      '/api/v2/user',
      stringify({
        email: data.email,
      }),
      {
        baseURL: runtimeConfig.public.baseUrl,
      },
    )
  },

  addUserAvatar({ data }: { data?: File }) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()

    return $axios.post('/api/v2/user', getFormData({ photo: data }), {
      baseURL: runtimeConfig.public.baseUrl,
      headers: {
        post: undefined,
      },
    })
  },

  removeUserAvatar() {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()

    return $axios.post('/api/v2/user', getFormData({ photo: new File([''], '') }), {
      baseURL: runtimeConfig.public.baseUrl,
      headers: {
        post: undefined,
      },
    })
  },

  changeUserPersonal({ data }: { data: UserPersonalType }) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    return $axios.post(
      '/api/v2/user',
      stringify({
        name: data.name,
        last_name: data.lastName,
        date: data.birthday,
        gender: data.gender,
        email: data.email,
        telegram: data.socials?.telegram,
      }),
      {
        baseURL: runtimeConfig.public.baseUrl,
      },
    )
  },

  async getQuestions() {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get<{ result: any[] }>('/api/v1/darkstore/faq')
    return data.result?.map(item => Question(item)) || []
  },

  async getPaymentCard() {
    const { $axios } = useNuxtApp()

    const { data } = await $axios.get<{ result: any[] }>('/api/v1/user/bank-cards')

    return data.result?.map(item => PaymentCard(item)) || []
  },

  removePaymentCard({ id }: { id: string }) {
    const { $axios } = useNuxtApp()

    return $axios.delete(`/api/v1/user/bank-cards/${id}`)
  },
}
