import type { StorageLike } from 'pinia-plugin-persistedstate'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import type { Pinia } from 'pinia'
import type { CookieOptions } from '#app'
import { callWithNuxt } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const { cookieOptions, debug } = useRuntimeConfig().public.persistedState
  const pinia = nuxtApp.$pinia as Pinia

  pinia.use(createPersistedState({
    storage: usePersistedstateCookies(cookieOptions),
    debug,
  }))

  function usePersistedstateCookies(cookieOptions?: Omit<CookieOptions<string>, 'encode' | 'decode'>) {
    return ({
      getItem: (key: string) => {
        return useCookie<string>(key, {
          ...cookieOptions,
          encode: encodeURIComponent,
          decode: decodeURIComponent,
        }).value
      },
      setItem: (key: string, value: any) => {
        void callWithNuxt(nuxtApp, () => {
          useCookie<string>(key, {
            ...cookieOptions,
            encode: encodeURIComponent,
            decode: decodeURIComponent,
          }).value = value
        })
      },
    }) as StorageLike
  }
})
