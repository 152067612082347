import { defineStore } from 'pinia'

export const useConfirmRemovePaymentCardStore = defineStore('confirmRemovePaymentCardModal', () => {
  const active = ref(false)
  const onConfirm = ref<() => void>()
  const onReject = ref<() => void>()

  function enable(data: { onConfirm: noop; onReject: noop }) {
    active.value = true
    onConfirm.value = data.onConfirm
    onReject.value = data.onReject
  }

  function disable() {
    active.value = false
    onConfirm.value = undefined
    onReject.value = undefined
  }

  return { active, enable, disable, onConfirm, onReject }
})
