import { defineStore } from 'pinia'

export const useConfirmStore = defineStore('confirmModal', () => {
  const active = ref(false)
  const title = ref<string | null>(null)
  const desc = ref<string | null>(null)
  const onConfirm = ref<() => void>()
  const onReject = ref<() => void>()

  function enable(data: { title: string; desc: string; onConfirm: noop; onReject: noop }) {
    active.value = true
    title.value = data.title
    desc.value = data.desc
    onConfirm.value = data.onConfirm
    onReject.value = data.onReject
  }

  function disable() {
    active.value = false
    title.value = null
    desc.value = null
    onConfirm.value = undefined
    onReject.value = undefined
  }

  return { active, title, desc, enable, disable, onConfirm, onReject }
})
