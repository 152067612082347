<script setup lang="ts">
import type { Product } from '~/type/product/Product'

interface Props {
  product: Product
  disabledPhotoLazyload?: boolean
  size?: 'small' | 'middle'
  listName?: string
}

const props = withDefaults(defineProps<Props>(), {
  disabledPhotoLazyload: true,
  size: 'middle',
})

const emit = defineEmits<{
  (e: 'click', value: Product): void
}>()

const actionCart = useActionCart(props.product.id)
const actionFavorite = useActionFavorite(props.product.id, props.product.price?.common?.value || 0)
</script>

<template>
  <div
    data-e2e="product-card"
    :data-e2e-product-card-not-in-favorite="!actionFavorite.active.value"
    class="product-card"
    :class="[`product-card_${size}`]"
    @click="
      emit('click', product),
      $emitter.emit('product-select', { ...props.product, listName: props.listName })"
  >
    <div
      class="product-card__wrapper"
    >
      <div class="product-card__tools">
        <div class="product-card__tool">
          <UserTool
            :active="actionFavorite.active.value"
            :loading="actionFavorite.loading.value"
            @add="actionFavorite.add"
            @remove="actionFavorite.remove"
          />
        </div>
      </div>
      <div class="product-card__photo-wrapper">
        <div
          v-if="product?.price?.percentDelta"
          class="product-card__percent"
        >
          <PercentDiscountMarker
            :percent="product.price.percentDelta"
          />
        </div>
        <ImgLazy
          :img-path="product.thumbnail.x1 || product.image"
          :srcset="product.thumbnail.x2"
          :alt="product.name"
          :disable="disabledPhotoLazyload"
          class="product-card__photo"
        />
      </div>
      <div class="product-card__title">
        <a
          :href="product.path"
          data-e2e="product-card__title"
          class="link link_wu"
          @click.prevent
        >
          {{ product.name }}
        </a>
      </div>
      <div class="product-card__bottom">
        <div
          v-if="product.price"
          class="product-card__price-wrap"
        >
          <Price :price="product.price" size="big" />
          <!-- ждем данные с апи -->
          <!-- <span class="product-card__product-info grey-text">300 г. в 1 шт</span> -->
        </div>
        <div
          v-if="product.status.code === 'NOT_AVAILABLE'"
          class="product-card__button-disabled"
        >
          Нет в наличии
        </div>
        <div
          v-else
          class="product-card__button-wrap"
          @click.stop="actionCart.active.value ? null : actionCart.add(actionCart.quantity.value + 1, listName)"
        >
          <Counter
            v-if="actionCart.active.value"
            :quantity="actionCart.quantity.value"
            :max="product.limit"
            :loading="actionCart.loading.value"
            @decrease="actionCart.decreaseAmount()"
            @increase="actionCart.increaseAmount({ listName })"
          />
          <div
            v-else
            class="product-card__cart-tool b"
          >
            <div class="link-icon link-icon_black product-card__cart-button">
              <span v-if="!actionCart.loading.value">В корзину</span>
              <span
                v-else
                :class="[{ 'icon-loading-oval': actionCart.loading.value }]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.product-card {

  &_small {

    ^&__wrapper {
      padding: 7px 5px 5px;
    }

    ^&__photo-wrapper {
      height: 95px;
    }

    ^&__price-wrap {
      height: 16px;
      margin-bottom: 6px;
    }

    ^&__title {
      min-height: 28px;
      max-height: 28px;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__cart-button {
    text-decoration: none;
  }

  &__tools {
    display: flex;
    justify-content: flex-end;
  }

  &__wrapper {
    padding: 10px 10px 10px;
    cursor: pointer;
    background-color: #f6f6f6;
    border-radius: 10px;
  }

  &__photo-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    margin-bottom: 8px;
  }

  &__percent {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__photo {
    max-width: 100%;
    max-height: 100%;
    mix-blend-mode: multiply;
  }

  &__title {
    min-height: 32px;
    max-height: 32px;
    margin-bottom: 5px;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    text-overflow: ellipsis;
  }

  &__product-info {
    font-size: 10px;
  }

  &__price-wrap {
    display: flex;
    column-gap: 5px;
    align-items: center;
    height: 15px;
    min-height: 14px;
    margin-bottom: 12px;
  }

  &__price {
    font-size: 16px;
  }

  &__button-disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    color: rgba(0, 0, 0, 0.26);
    cursor: not-allowed;
    background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 6px, transparent 0, transparent 13px);
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 7px;
  }

  &__button-wrap {
    height: 42px;
    padding: 12px;
    line-height: 16px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f3f3f3;
    border-radius: 7px;
  }

  &__cart-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__cart-icon {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 15px;
    height: 16px;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 15px;
  }

  &__loading-oval {
    display: block;
    width: 17px;
    height: 17px;
    margin: 0px auto;
    background: url(./asset/loading-oval.svg) no-repeat center;
    background-size: 17px;
  }

  @media (max-width: 1000px) {

    &__wrapper {
      padding: 7px 5px 5px 5px;
    }

    &__photo-wrapper {
      height: 95px;
      margin-bottom: 8px;
    }

    &__title {
      min-height: 28px;
      max-height: 28px;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 14px;
    }

    &__price-wrap {
      margin-bottom: 6px;
    }

    &__button-disabled {
      height: 34px;
    }
  }
}
</style>
