<script lang="ts" setup>
import type { Category } from '~/type/Category'

const props = defineProps<{
  list: Category[]
  currentCategory?: Category
}>()
const emit = defineEmits<{
  (e: 'click', category?: Category): void
}>()
const route = useRoute()

const isMoreActive = ref(false)
const currentList = computed(() => isMoreActive.value ? props.list : props.list.slice(0, 7))
</script>

<template>
  <div class="category-list" @click.self="emit('click')">
    <button
      v-for="item in currentList"
      :key="item.id"
      class="category-list__link"
      :class="{ 'category-list__link_active': currentCategory?.id === item.id || route.query?.section === item.id }"
      @click="emit('click', (currentCategory?.id === item.id || route.query?.section === item.id) ? undefined : item)"
    >
      <img
        src="./asset/close-green.svg"
        alt="Выбрать или убрать категорию"
        class="category-list__close"
      >
      <span class="category-list__name">{{ item.name }}</span>
      <span class="category-list__count">{{ item.productsCount }}</span>
    </button>
    <div
      v-if="list.length > 7 && !isMoreActive"
      class="category-list__link"
      @click="isMoreActive = true"
    >
      <span>Ещё</span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.category-list {
  width: 100%;

  &__link {
    position: relative;
    display: inline-block;
    padding: 12px 14px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    background-color: white;
    border: 1px solid #f3f3f3;
    border-radius: 100px;

    &_active {
      background-color: #00B0B8;
      border: none;

      & > span {
        color: white;
      }

      .category-list__close {
        position: absolute;
        top: -3px;
        right: -4px;
        display: block;
      }

      .category-list__count {
        display: none;
      }

      .category-list__name {
        margin-right: 20px;
      }
    }
    @media (min-width: 1000px) {

      &:hover {
        background-color: #00B0B8;
        border: none;

        & > span {
          color: white;
        }
      }
    }
  }

  &__count {
    color: darkgray;
  }

  &__name {
    margin-right: 5px;
  }

  &__close {
    display: none;
  }

  @media (max-width: 800px) {

    &__link {
      padding: 10px;
      margin-right: 6px;
    }
  }
}
</style>
