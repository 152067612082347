<script setup lang="ts">
import type Sort from '~/type/Sort'

interface Props {
  placeholder?: string
  width?: string
  fontSize?: string
  list: Sort[]
  value: Sort
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'Сортировать по',
  width: '116px',
  fontSize: '14px',
  list: () => [],
})

const emit = defineEmits<{ (e: 'change', value: Sort): void }>()

function onSelect(payload: Event) {
  const currentTarget = payload.currentTarget as HTMLSelectElement
  const el = props.list.find(el => el.value === currentTarget.value)
  if (el)
    emit('change', el)
}
</script>

<template>
  <div
    class="sort-select"
    :style="{ width }"
  >
    <select
      class="sort-select__select"
      :value="value.value"
      :style="{ fontSize }"
      @change="onSelect"
    >
      <option
        :selected="!value.value"
        disabled
      >
        {{ placeholder }}
      </option>
      <option
        v-for="el in list"
        :key="el.value"
        :selected="value.value === el.value"
        :value="el.value"
      >
        {{ el.name }}
      </option>
    </select>
  </div>
</template>

<style lang="postcss" scoped>
.sort-select {
  padding-right: 12px;
  background: url(./asset/arrow-bottom-thin.svg) top 10px right no-repeat;
  background-size: 7px 4px;

  &__select {
    width: 100%;
    line-height: 18px;
    text-overflow: ellipsis;
    background-color: #ffffff;
    border: none;
    outline: none;
  }
}
</style>
