import type { Delivery } from '~/type/product/Product'

export default function (el: { [key: string]: any }): Delivery {
  return {
    name: el.NAME,
    date: el.PERIOD_TEXT,
    cost: el.PRICE_FORMATED,
    store: el.STORE_LIST.length,
  }
}
