<script setup lang="ts">
import type { Category } from '~/type/Category'

interface Props {
  list: Category[]
  isLastLinkActive?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  isLastLinkActive: false,
})

const emit = defineEmits<{
  (e: 'click'): void
}>()

const config = useRuntimeConfig()

const structuredList = computed(() => {
  return props.list.map((el, index) => ({
    '@type': 'ListItem',
    'position': index + 1,
    'name': el.name,
    'item': config.public.projectUrl + el.path,
  }))
})

// для SEO проставляем доп. мета-теги
useHead({
  script: [
    {
      innerHTML: {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': structuredList,
      },
      type: 'application/ld+json',
    },
  ],
})
</script>

<template>
  <div class="breadcrumb">
    <div class="breadcrumb__list">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="breadcrumb__item"
      >
        <template v-if="isLastLinkActive">
          <nuxt-link
            :to="item.path"
            class="breadcrumb__link"
            @click="emit('click')"
          >
            <span class="breadcrumb__link-text">{{ item.name }}</span>
          </nuxt-link>
        </template>

        <template v-else>
          <nuxt-link
            v-if="index !== list.length - 1"
            :to="item.path"
            class="breadcrumb__link"
            @click="emit('click')"
          >
            <span class="breadcrumb__link-text">{{ item.name }}</span>
          </nuxt-link>
          <div v-else>
            {{ item.name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.breadcrumb {
  position: relative;
  z-index: 1;
  font-size: 12px;
  line-height: 16px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -6px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    &:after {
      position: relative;
      top: 1px;
      display: inline-block;
      width: 5px;
      height: 9px;
      margin-right: 10px;
      margin-left: 10px;
      content: '';
      background: svg-load('./asset/arrow-right.svg', fill=#000) no-repeat center;
      background-size: 5px auto;
    }

    &:last-child:after {
      display: none;
    }
  }

  &__link {
    color: #000;
    text-decoration: none;

    &_icon:before {
      position: relative;
      top: 3px;
      display: inline-block;
      width: 11px;
      height: 13px;
      margin-right: 3px;
      vertical-align: top;
      content: '';
      background: svg-load('./asset/category.svg', fill=#000) no-repeat center;
      background-size: 10px;
    }

    &:hover {
      color: #eb0010;
      transition: color 0.3s ease-out;

      &.breadcrumb__link_icon:before {
        background-image: svg-load('./asset/category.svg', fill=#eb0010);
        transition: background-image 0.3s ease-out;
      }
    }
  }
  @media (max-width: 800px) {
    display: flex;

    &__link {

      &-text {
        border-bottom: none;
      }
    }

    &__item {
      display: none;
    }

    &__item:after {
      top: 0;
    }

    &__item:first-child,
    &__item:nth-last-child(-n+2){
      display: flex;

    }

    &__item:nth-last-child(2):before {
      display: none;

      width: 8px;
      height: 8px;
      margin-right: 5px;
      content: '';
      background: svg-load('./asset/arrow-left.svg', fill=#000) no-repeat center;
    }

    &__item:nth-last-child(2):after {
      display: block;
    }
  }
}
</style>
