import { formatAddress } from '@artmizu/utils'
import type { Address } from '~/type/Address'

export default function (el: { [key: string]: any } = {}) {
  const result: Address = {
    city: el.data.city,
    district: el.data.city_district,
    street: el.data.street,
    house: el.data.house,
    region: el.data.region,
    lat: +el.data.geo_lat,
    lon: +el.data.geo_lon,
    formatted: '',
  }

  result.formatted = formatAddress(result)!
  return result
}
