import type { Events } from '~~/plugins/5.emitter'

export default function cartAction(obj: Events['cart-product-add']) {
  const path = obj.product.path?.match(/\/([^/]+)\/?$/)
  return {
    event: 'add_to_cart',
    ecommerce: {
      currency: 'RUB',
      value: obj.product.price?.common?.value,
      items: [
        {
          item_name: obj.product.name,
          item_id: obj.product.id || (path && path[1]),
          price: obj.product.price?.common?.value,
          // item_brand: obj.brand?.name, раньше передавался, но его нет в обычном товаре в каталоге и всём таком
          item_category: obj.product.category?.name,
          item_list_name: obj.listName,
          quantity: obj.product.quantity,
        },
      ],
    },
  }
}
