import { AxiosError, isCancel } from 'axios'
import { ErrorSerializer } from '~~/serializer/Error'
import type ErrorType from '~~/type/Error'
import type Pagination from '~~/type/Pagination'
import type Sort from '~~/type/Sort'

export type Status = 'loading' | 'error' | 'initial' | 'success'

interface Param<A, R> {
  /**
   * Объект с параметрами для конкретного запроса, из-за того что поддерживается
   * только объект, в модуле апи нет смысла создавать функции с несколькими параметрами
   * потому что вы не сможете их использовать вместе с этими компоузабалами
   */
  params: Ref<A> | A
  /**
   * Если передано true, то будет создан вотч, который отслеживает изменения в параметрах
   * и если они произошли - будет осуществлен запрос. Обратите внимание,
   * чтобы вотч работал, нужно передавать params в виде ref'a
   */
  watchParam?: boolean
  /**
   * Тип ошибки, прокидывается напрямую в слой ошибок, поэтому сюда передаем ошибки, которые
   * актуальны в этом слое
   */
  errorType?: 'simple' | 'base' | 'page'
  requestPriority?: 'first' | 'last'
  onLoading?: () => void
  onSuccess?: ({ data }: { data: R }) => void
  onError?: ({ serialized, native }: { serialized?: ErrorType; native: unknown }) => void
}

/**
 * Функция которая определяет какой ref стоит использовать,
 * который SSR-сейф или обычный
 */
function useRef<D>(
  value: D,
  key?: string,
) {
  if (key)
    return useState(key, () => value)
  else
    return ref(value)
}

export function useActionEntity<A, R>(fetch: (args: A) => Promise<R>, {
  key,
  params,
  watchParam = false,
  errorType = 'base',
  requestPriority = 'first',
  onLoading = () => { },
  onSuccess = () => { },
  onError = () => { },
}: Param<A, R> & {
  /**
   * Если передано это значение, то внутри компоузабла будут
   * использовать useState вместо обычных ref'ов и в них будет
   * передан этот ключ. Требования к ключу соответствующие:
   * ключ должен быть уникален на уровне всего приложения
   */
  key?: string
}) {
  const { $error } = useNuxtApp()
  const success = useRef(false, key ? `${key}-success` : undefined)
  const error = useRef(false, key ? `${key}-error` : undefined)
  const loading = useRef(false, key ? `${key}-loading` : undefined)
  const initial = useRef(true, key ? `${key}-initial` : undefined)
  const status = useRef<Status | undefined>(undefined, key ? `${key}-status` : undefined)
  const response = useRef<R | undefined>(undefined, key ? `${key}-response` : undefined)
  const errorValue = useRef<ErrorType | undefined>(undefined, key ? `${key}-error-value` : undefined)

  onUnmounted (() => {
    changeStatus ('initial')
    response.value = undefined
    errorValue.value = undefined
  })
  if (watchParam && isRef(params)) {
    watch(params, () => {
      void request()
    }, {
      deep: true,
    })
  }

  async function request(functionParam?: A) {
    /**
     * Если во время осуществления запрося пользователь пытается сделать еще один,
     * смотрим на приоритет запросов, если приоритет у первого - ничего больше не делаем,
     * возвращаем старый ответ
     */
    if (requestPriority === 'first' && status.value === 'loading')
      return response

    changeStatus('loading')
    onLoading()

    try {
      response.value = await fetch(functionParam || (isRef(params) ? params.value : params))
      changeStatus('success')
      onSuccess({ data: response.value })
      return response
    }
    catch (error) {
      /**
       * Если запрос был отменен (например тем, что пользователь вызвал еще один запрос и приоритет выбран
       * для последнего запроса), переключаем статус на загрузку чтобы для отмененных запросов не
       * появлялись визуальные ошибки
       */
      if (isCancel(error) && requestPriority === 'last') {
        changeStatus('loading')
      }
      else {
        changeStatus('error')

        if (error instanceof AxiosError) {
          const serialized = ErrorSerializer(error)
          errorValue.value = serialized
          onError({
            serialized,
            native: error,
          })
          new $error[errorType](serialized)
        }
        else {
          onError({
            native: error,
          })
          throw error
        }
      }
    }
  }

  function changeStatus(newStatus: Status, value = true) {
    resetStatus()
    errorValue.value = undefined
    status.value = newStatus
    if (newStatus === 'error')
      error.value = value

    else if (newStatus === 'initial')
      initial.value = value

    else if (newStatus === 'loading')
      loading.value = value

    else
      success.value = value
  }

  function resetStatus() {
    initial.value = false
    loading.value = false
    success.value = false
    error.value = false
  }

  return {
    data: response,
    changeStatus,
    request,
    status,
    initial,
    loading,
    success,
    error,
    errorValue,
  }
}

export async function useDataEntity<A, R>(key: string, fetch: (args: A) => Promise<R>, {
  params,
  requestPriority = 'first',
  watchParam = false,
  active = true,
  errorType = 'base',
  onSuccess = () => { },
  onLoading = () => { },
  onError = () => { },
}: Param<A, R> & { active?: boolean | Ref<boolean> },
) {
  const actionEntity = useActionEntity(fetch, {
    key,
    params,
    requestPriority,
    watchParam,
    errorType,
    onLoading,
    onSuccess,
    onError,
  })

  /**
   * При начальном рендере компоузабла смотрим активен ли он, если он активен
   * то делаем запрос, если не активен - не делаем, при этом если параметр active
   * это ref то подписываемся на его изменения и если он переключился из false в true
   * то активируем запрос
   */
  if (isRef(active)) {
    const stop = watch(active, (value) => {
      if (value) {
        void actionEntity.request()
        stop()
      }
    })
  }
  else {
    if (active && !actionEntity.data.value)
      await actionEntity.request()
  }

  return actionEntity
}

export async function useDataEntityList<
  A extends { page?: number; size?: number; sort?: string }, R extends { list: unknown[]; pagination: Pagination; sort?: Sort[] },
>(key: string, fetch: (args: A) => Promise<R>, {
  params,
  requestPriority = 'first',
  watchParam = false,
  /**
   * Этот параметр контролирует, нужно ли ожидать загрузку первого
   * запроса при первоначальном рендере или нет, если поставить false
   * то переход на страницу с этим компоузаблом произойдет сразу,
   * но на странице данных не будет т.к. они еще не загружены
   */
  waitForFirstRequest = true,
  active = true,
  errorType = 'page',
  onSuccess = () => { },
  onLoading = () => { },
  onError = () => { },
}: Param<A, R> & { active?: boolean | Ref<boolean>; waitForFirstRequest?: boolean }) {
  const localParams = useState<A | undefined>(`${key}-params`, () => isRef(params) ? params.value : params)

  const actionEntity = useActionEntity(fetch, {
    // TODO: Кому: Сабурову Александру. Проблема типизации params, который может принимать значение undefined.
    // @ts-expect-error: Unreachable code error
    params: localParams,
    errorType,
    requestPriority,
    onLoading,
    onSuccess,
    onError,
    key,
  })
  const localResult = useState<R | undefined>(`${key}-result`)
  const localList = useState<R['list'] | undefined>(`${key}-list`)
  const localSortList = useState<Sort[]>(`${key}-sort-list`)
  const localActiveSort = useState<Sort>(`${key}-sort-active`)
  const localPagination = useState<Pagination >(`${key}-pagination`)

  /**
   * При удалении компонента, очищаем локальные переменные
   */
  onUnmounted (() => {
    localParams.value = undefined
    localResult.value = undefined
  })
  /**
   * При обновлении параметров в родители полностью локально их обнровляем
   */
  if (watchParam && isRef(params)) {
    watch(params, (val) => {
      localParams.value = val
      void request()
    }, {
      deep: true,
    })
  }

  if (isRef(active) && !active.value) {
    const stop = watch(active, (val) => {
      if (val && !localResult.value) {
        void request()
        stop()
      }
    })
  }
  else {
    if (!localResult.value) {
      if (waitForFirstRequest)
        await request()
      else
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        request()
    }
  }

  async function request(strategy: 'append' | 'replace' = 'replace', newParams?: A) {
    const response = await actionEntity.request(newParams ?? localParams.value)
    if (response?.value) {
      localResult.value = response.value as R
      const newList = response.value.list

      if (strategy === 'append')
        localList.value?.push(...newList)
      else
        localList.value = newList

      if (response.value?.sort) {
        localSortList.value = response.value.sort
        localActiveSort.value = getActiveSort(response.value.sort)!
      }

      const newPagination = response.value.pagination
      localPagination.value = newPagination
    }
  }

  function getActiveSort(list: Sort[]) {
    return list.find(el => el.active)
  }

  function loadMore() {
    if (localPagination.value?.current < localPagination.value?.count && !actionEntity.loading?.value) {
      const num = localPagination.value.current + 1
      localPagination.value.current = num
      if (localParams.value)
        localParams.value.page = num
      void request('append')
    }
  }

  function sort(newSort: Sort) {
    /**
     * при сортировке скидываем страницу на первую, потому что не правильно оставаться
     * на той же странице, если пользователь отсортировал что-то - ему интересен список с самого начала
     */
    localPagination.value.current = 1
    if (localParams.value)
      localParams.value.page = 1

    localActiveSort.value = newSort
    if (localParams.value)
      localParams.value.sort = newSort.value
    void request()
  }

  function changePage(page: number) {
    if (page !== localPagination.value.current && !actionEntity.loading.value) {
      if (localParams.value)
        localParams.value.page = page
      localPagination.value.current = page
      void request()
    }
  }

  return {
    ...actionEntity,
    data: localResult,
    list: localList,
    request,
    loadMore,
    sort,
    changePage,
    pagination: localPagination,
    activeSort: localActiveSort,
    sortList: localSortList,
  }
}
