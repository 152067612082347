import { defineStore } from 'pinia'

export const useAuthStore = defineStore('authModal', () => {
  const active = ref(false)
  const title = ref()
  const subtitle = ref()

  let onAuthComplete: noop

  function enable(data: { onComplete?: noop; title?: string; subtitle?: string } = {}) {
    active.value = true
    if (data.title)
      title.value = data.title
    if (data.subtitle)
      subtitle.value = data.subtitle
    if (data.onComplete)
      onAuthComplete = data.onComplete
  }

  function disable(data: boolean) {
    active.value = false
    title.value = undefined
    subtitle.value = undefined
    /**
     * Если при открытии модалки, передали коллбэк и также если авторизовались,
     *  то вызываем функцию onAuthComplete
     */
    if (typeof onAuthComplete === 'function' && data)
      onAuthComplete()
  }

  return { active, title, subtitle, enable, disable }
})
