import { stringify } from 'qs'
import OrderCheckoutSerializer from '~/serializer/OrderCheckout'
import type { Product } from '~/type/product/Product'

/**
 * Десериализуем аш формат товаров в формат, который может переварить АПИ 05
 */
function convertProducts(data?: Product[] | null) {
  return data
    ? Object.values(data).map(el => ({
      ID: el.id,
      QUANTITY: el.quantity,
    }))
    : []
}

export default {
  async sync({ data: productList, cart }: { data?: Product[] | null; cart?: Product[] | null }) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.put(
      `/api/v1/darkstore/basket?${stringify({ RESPONSE: 'ORDER' })}`,
      {
        PRODUCTS: [...convertProducts(productList), ...convertProducts(cart)],
      },
    )
    return {
      result: OrderCheckoutSerializer(data.result),
    }
  },
  async add({ id, quantity = 1 }: { id: string; quantity?: number }) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.put(`/api/v1/darkstore/basket/${id}?${stringify({ QUANTITY: quantity, RESPONSE: 'ORDER' })}`)
    return {
      result: OrderCheckoutSerializer(data.result),
    }
  },
  async remove({ id }: { id: string }) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.delete(`/api/v1/darkstore/basket/${id}?${stringify({ RESPONSE: 'ORDER' })}`, {
      'axios-retry': {
        retries: 2,
      },
    })
    return {
      result: OrderCheckoutSerializer(data.result),
    }
  },
  async removeAll() {
    const { $axios } = useNuxtApp()
    const { data } = (await $axios.delete(`/api/v1/darkstore/basket/all?${stringify({ RESPONSE: 'ORDER' })}`, {
      'axios-retry': {
        retries: 2,
      },
    }))
    return {
      result: OrderCheckoutSerializer(data.result),
    }
  },
}
