import type Price from '~/type/Price'

export default function (el: any): Price {
  let discount: number | undefined, common: number | undefined

  /* С API данные цены приходят по разному */
  if (Array.isArray(el)) {
    discount = el.find((item: any) => item.CODE === 'PROMO')?.PRICE
    common = el.find((item: any) => item.CODE === 'DEFAULT')?.PRICE
  }
  else {
    common = el?.CURRENT?.PRICE
    discount = el?.OLD?.PRICE
  }

  return {
    common:
      typeof common === 'number'
        ? {
            value: common,
          }
        : undefined,
    discount:
      typeof discount === 'number'
        ? {
            value: discount,
          }
        : undefined,
    percentDelta: (typeof common === 'number' && typeof discount === 'number') ? getDelta(common, discount) : undefined,
  }
}

function getDelta(common: number, discount: number) {
  return Math.round(((discount - common) / discount) * 100)
}
