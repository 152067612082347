<script setup lang="ts">
const props = withDefaults(defineProps<{
  time?: number
}>(), {
  time: 5,
})
const emit = defineEmits<{ (e: 'end'): void }>()

const innerTime = ref(props.time)
const timer = ref<NodeJS.Timer>()

if (process.client)
  activate()

onUnmounted(() => deactivate())

function countdown() {
  innerTime.value--
  if (innerTime.value <= 0 && timer.value) {
    clearInterval(timer.value)
    timer.value = undefined
    emit('end')
  }
}

function activate() {
  timer.value = setInterval(countdown, 1000)
}

function deactivate() {
  if (timer.value) {
    clearInterval(timer.value)
    timer.value = undefined
  }
}

function reactivate() {
  innerTime.value = props.time
  activate()
}
</script>

<template>
  <div>
    <slot
      :activate="activate"
      :reactivate="reactivate"
      :deactivate="deactivate"
      :time="innerTime"
    />
  </div>
</template>
