import type { Pinia } from 'pinia'
import { PiniaSharedState } from 'pinia-shared-state'

export default defineNuxtPlugin((_) => {
  const pinia: Pinia = usePinia()

  pinia.use(
    PiniaSharedState({
      enable: false,
    }),
  )
})
