<script setup lang="ts">
import { UI05StatusBlock } from 'ui-05'
import type { Story } from '~/type/Story'

interface Props {
  stories: Story[]
  active: boolean
  error: boolean
}

const props = withDefaults(defineProps<Props>(), {
  stories: () => [],
})
const emit = defineEmits<{ (e: 'close'): void }>()

const indexSelected = ref(0)
const difference = ref(0)
const key = ref(0)
const percent = ref(0)
const timer = ref(0)
const progress = ref<NodeJS.Timeout>()
const interval = ref<NodeJS.Timeout>()
const isPaused = ref(false)
const timeDuration = ref(0)
const pausePer = ref(0)
const isCurrentAllImagesEnd = computed(() => {
  return key.value >= props.stories.length - 1
})

onMounted(() => play())

function onAllStoriesEnd() {
  difference.value = 0
  indexSelected.value = 0
  key.value = 0
  reset()
  emit('close')
}

function onCurrentAllImagesEnd(index: number) {
  difference.value += index - (index + 1)
  indexSelected.value++
}

function onCurrentImageEnd() {
  key.value++
}

function next(index: number) {
  isPaused.value = false
  if (isCurrentAllImagesEnd.value) {
    setTimeout(() => {
      onCurrentAllImagesEnd(index)
    })
  }
  else {
    key.value++
  }
  reset()
}

function prev(index: number) {
  isPaused.value = false
  if (indexSelected.value <= 0 && key.value <= 0) {
    key.value = 0
  }
  else if (key.value <= 0) {
    setTimeout(() => {
      difference.value += index - (index - 1)
      indexSelected.value--
    })
  }
  else {
    key.value--
  }
  reset()
}

function autoPlay() {
  if (isCurrentAllImagesEnd.value) {
    onAllStoriesEnd()
  }
  else {
    onCurrentImageEnd()
    reset()
  }
}

function play() {
  timer.value = new Date().getTime()
  progress.value = setInterval(() => {
    const time = new Date().getTime()
    if (timeDuration.value > 0)
      percent.value = pausePer.value + Math.floor((100 * (time - timer.value)) / props.stories[key.value]?.duration)
    else
      percent.value = Math.floor((100 * (time - timer.value)) / props.stories[key.value]?.duration)
  }, props.stories[key.value]?.duration / 100)
  if (timeDuration.value > 0)
    interval.value = setInterval(autoPlay, timeDuration.value)
  else
    interval.value = setInterval(autoPlay, props.stories[key.value]?.duration)
}

function reset() {
  percent.value = 0
  if (interval.value)
    clearInterval(interval.value)
  if (progress.value)
    clearInterval(progress.value)
  timeDuration.value = 0
  play()
}

function pauseStory() {
  isPaused.value = true
  pausePer.value = percent.value
  if (interval.value)
    clearInterval(interval.value)
  if (progress.value)
    clearInterval(progress.value)
  timeDuration.value = props.stories[key.value]?.duration - (pausePer.value * props.stories[key.value]?.duration) / 100
}

function playStory() {
  isPaused.value = false
  play()
}
</script>

<template>
  <div v-if="active" class="stories">
    <div class="stories__overlay" @click.self="onAllStoriesEnd">
      <div class="stories__modal">
        <div class="stories__wrapper">
          <template v-if="error">
            <UI05StatusBlock
              size="m"
              type="error"
              title="При загрузке сторис возникла ошибка"
            />
            <div class="close" @click.self="onAllStoriesEnd" />
          </template>
          <div v-else class="story">
            <div class="story__source">
              <div class="story__overlay">
                <div @click="prev(1)" />
                <div @touchstart="pauseStory()" @touchend="playStory()" />
                <div @click="key === stories.length - 1 ? onAllStoriesEnd() : next(1)" />
              </div>
              <img
                :src="stories[key]?.image"
                :srcset="`${stories[key]?.image} 2x`"
                alt="Darkstore Story"
                class="story__source-img"
                @contextmenu="false"
              >
              <div class="story__header">
                <div class="time">
                  <div
                    v-for="(_, i) in stories.length"
                    :key="i"
                    class="time__item"
                  >
                    <div
                      class="time__fill"
                      :style=" i === key ? `width: ${percent}%` : key > i ? `width:100%` : `width:0%`"
                    />
                  </div>
                </div>
                <div class="story__top">
                  <div class="logo">
                    <img src="./asset/logo-stories.svg" alt="Logo Darkstore">
                  </div>
                  <div class="close" @click.self="onAllStoriesEnd" />
                </div>
              </div>
              <nuxt-link
                v-if="stories[key]?.linkTo"
                :to="stories[key]?.linkTo"
                class="story__link-btn"
                @click="emit('close')"
              >
                Перейти
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.stories {

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -moz-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-modal);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(26, 26, 26, 0.8);
    opacity: 1;
  }

  &__modal {
    box-sizing: border-box;
    width: 100%;
    max-width: 375px;
    height: 734px;
    margin: auto;
    overflow: hidden;
    background: rgba(26,26,26,.8);
    border-radius: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: 900px) {

    &__modal {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 415px;
      background-color: rgba(26,26,26,.8);
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
    }
  }
  @media (max-width: 800px) {

    &__modal {
      max-width: initial;
      height: 100%;
      margin: initial;
      border-radius: 0;
    }
  }
}

.story {
  position: absolute;
  cursor: pointer;
  outline: 0;
  transition: transform .2s cubic-bezier(0.4, 0, 1, 1);
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__link-btn {
    position: absolute;
    bottom: 25px;
    left: 50%;
    z-index: 4;
    padding: 14px 34px;
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;
    color: white;
    text-decoration: none;
    background: #E30613;
    border-radius: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    transform: translateX(-50%);
  }

  &__overlay {
    position: absolute;
    z-index: 3;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__overlay * {
    flex: 1;
  }

  &__source {
    position: relative;
    width: 415px;
    height: 734px;
    background: #000000;
    background-size: contain;
    border-radius: 16px;
    @media screen and (max-width: 800px) {
      flex: 1 1 auto;
      width: 100%;
      height: auto;
      border-radius:0px;

      &__header {
        border-radius: 0;

        &-img {
          border-radius: 0;
        }
      }
    }

    &-img {
      z-index: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 12px;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 4;
    padding: 15px;
    border-radius: 12px;
  }

  &__time {
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
  }

  &__top {
    display: flex;
    align-items: center;
  }
}

.logo {
  display: flex;
  align-items: center;
}

.time {
  display: flex;
  margin-bottom: 14px;

  &__item {
    position: relative;
    width: 100%;
    height: 3px;
    margin-right: 5px;
    background: hsla(0,0%,100%,0.2);
    border-radius: 2px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__fill {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #ffffff;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;

  &:after{
    width: 15px;
    height: 15px;
    content: '';
    background: svg-load('./asset/close-white.svg', fill=transparent) no-repeat center;
  }
}
</style>
