import { type FeatureApiResponse, GrowthBook } from '@growthbook/growthbook'
import { nanoid } from 'nanoid'
import NodeCache from 'node-cache'
import { useCartStore } from '~/store/cart'
import { useFavoriteStore } from '~/store/favorite'
import { useUserStore } from '~/store/user'

// Время (час), через которое будет делаться повторный запрос, после последнего неудачного запроса
const checkInterval = 360000
let lastCheckTime: number | null = null
let growthbookPromise: Promise<{ data: FeatureApiResponse }> | null = null
const nodeCache = new NodeCache({ stdTTL: 3600 })

export default defineNuxtPlugin(async (nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const growthbookData = useState<FeatureApiResponse['features'] | null>('growthbookData')
  const timeToCheck = lastCheckTime ? Date.now() - lastCheckTime >= checkInterval : true

  if (!runtimeConfig.public.growthbookUrl) {
    console.warn('Growthbook: нет переменных окружения для инициализации')
    return
  }

  if (process.server) {
    const remain = nodeCache.getTtl('growthbookFeatures') || 0
    if (nodeCache) {
      if (nodeCache.has('growthbookFeatures')) {
        if (remain < (60 * 2) && timeToCheck) {
        /**
           * Загрузка вариантов реализована так, что она не блочит загрузку
           * страниц проекта, потому что нет ожидания этого запроса. В итоге когда запрос завершится
           * он просто обновит значение вариантов в кеше, чтобы уже последующие клиенты получили
           * более свежие варианты тестов
           */
          growthbookPromise = growthbookPromise || nuxtApp.vueApp.$nuxt.$axios.get(runtimeConfig.public.growthbookUrl)
          growthbookPromise.then((response) => {
            nodeCache.set('growthbookFeatures', response.data)
            lastCheckTime = null
          }).catch((e) => {
            lastCheckTime = Date.now()
            new nuxtApp.vueApp.$nuxt.$error.base({
              message: 'Не удалось получить варианты АБ тестов',
              native: e,
            })
          })
        }

        growthbookData.value = nodeCache.get('growthbookFeatures')!
      }
      else {
        try {
          if (timeToCheck) {
            growthbookPromise = growthbookPromise || nuxtApp.vueApp.$nuxt.$axios.get(runtimeConfig.public.growthbookUrl)
            const result = (await growthbookPromise).data.features
            nodeCache.set('growthbookFeatures', result)
            lastCheckTime = null
          }
        }
        catch (e) {
          lastCheckTime = Date.now()
          new nuxtApp.vueApp.$nuxt.$error.base({
            message: 'Не удалось получить варианты АБ тестов',
            native: e,
          })
        }
      }
    }
    else {
      try {
        if (timeToCheck) {
          growthbookPromise = growthbookPromise || nuxtApp.vueApp.$nuxt.$axios.get(runtimeConfig.public.growthbookUrl)
          const result = (await growthbookPromise).data.features
          growthbookData.value = result
          lastCheckTime = null
        }
      }
      catch (e) {
        lastCheckTime = Date.now()
        new nuxtApp.vueApp.$nuxt.$error.base({
          message: 'Не удалось получить варианты АБ тестов',
          native: e,
        })
      }
    }
  }

  const uuid = useCookie<string>('DarkstoreFrontEndUserUuid', {
    maxAge: 1000 * 60 * 24 * 30,
    path: '/',
  })

  if (!uuid.value)
    uuid.value = nanoid()

  const userStore = useUserStore()
  const cartStore = useCartStore()
  const favoriteStore = useFavoriteStore()

  const gb = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: 'sdk-fyEmzSyQujmGak',
    enableDevMode: runtimeConfig.public.deployEnvironment !== 'production' || false,
    subscribeToChanges: false,
    attributes: {
      id: uuid.value,
      email: userStore.data?.email,
      isAuth: userStore?.isUser,
      gender: userStore.data?.gender,
      birthday: userStore.data?.birthday,
      cartProductCount: cartStore.cartAmount || 0,
      favoriteProductCount: favoriteStore.amount || 0,
    },
  })

  if (growthbookData.value)
    await gb.loadFeatures(growthbookData.value)

  nuxtApp.provide('growthbook', gb)
  growthbookPromise = null
})
