import type { AxiosError } from 'axios'
import type ErrorType from '~/type/Error'

export function ErrorSerializer(event: AxiosError): ErrorType {
  const error: ErrorType = {
    name: 'Произошла ошибка',
    message: 'Попробуйте позже',
    type: 'unknown',
    native: event,
  }

  const data = event?.response?.data as any
  error.code = event.response?.status
  error.message = data?.error?.message
  error.description = data?.error?.description
  error.fields = data?.error?.fields

  // if (event && typeof event === 'object') {
  // let api

  // if ('request' in event)
  //   api = !!event.request

  // if ('name' in event && typeof event.name === 'string') {
  //   const syntax = ['TypeError', 'SyntaxError', 'RangeError', 'InternalError', 'ReferenceError'].includes(event.name)
  //   error.name = event.name
  //   error.type = api ? 'api' : syntax ? 'syntax' : 'unknown'
  // }
  // error.code = event.response?.status

  // if (typeof event.status === 'number')
  //   error.code = event.status

  // if ('status' in event && typeof event.status === 'number')
  //   error.code = event.response?.status

  // if (typeof data?.error?.description === 'string')
  //   error.message = data.error.description
  // }

  return error
}
