Компонент для "правильного" lazy-load'a по этой методолгии https://vc.ru/seo/169540-kak-lazy-load-ubivaet-vashi-sayty-i-chto-s-etim-delat
Для лейзи лоада атрибут src стоит для "правильного" лейзи-лоада, data-src нужен плагину, чтобы прокинуть в него картинки для разной
плотности пикселей. В итоговом HTMLe data-src равен какой-то хрени (исчезает x1 изображение), видимо плагин модифицирует атрибут в момент
выполнения, поэтому на итоговый HTML не ориентируемся

<script setup lang="ts">
import blank from './asset/blank.svg'
import empty from './asset/empty.png'

interface Props {
  imgPath?: string
  srcset?: string
  alt?: string
  disable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disable: true,
})

const srcSetPath = computed(() => {
  if (props.imgPath && props.srcset)
    return `${props.imgPath}, ${props.srcset} 2x`
  else
    return props.imgPath
})
</script>

<template>
  <img
    v-if="disable && imgPath"
    :src="imgPath"
    :alt="alt"
    :srcset="srcset && `${srcset} 2x`"
  >
  <img
    v-else-if="imgPath"
    v-lazy="imgPath"
    :alt="alt"
    :src="imgPath"
    :srcset="blank"
    :data-srcset="srcSetPath"
  >
  <img
    v-else
    :src="empty"
    :alt="alt"
  >
</template>
