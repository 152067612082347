import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Token } from '~/type/Token'

export const useTokenStore = defineStore('token', () => {
  const token = ref<Token | null>(null)

  function set(newToken: Token) {
    token.value = newToken
  }

  function isAccessExpire() {
    if (!token.value)
      throw new Error('У пользователя нет токена')

    return token.value.accessToken.expire * 1000 < Date.now() + 2 * 60 * 60 * 1000 // Если токен сгорит через 2 часа (2 * 60 * 60 * 1000) вернётся true
  }

  function isRefreshExpire() {
    if (!token.value)
      throw new Error('У пользователя нет токена')

    return token.value.refreshToken.expire * 1000 < Date.now() + 2 * 60 * 60 * 1000 // Если токен сгорит через 2 часа (2 * 60 * 60 * 1000) вернётся true
  }

  function $reset() {
    token.value = null
  }

  return { set, token, isAccessExpire, isRefreshExpire, $reset }
}, {
  persist: true,
  share: {
    enable: true,
  },
})
