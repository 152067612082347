<script setup lang="ts">
defineProps<{
  active: boolean
}>()
const emit = defineEmits<{ (e: 'openMap'): void }>()
</script>

<template>
  <div
    v-if="active"
    class="demo-note"
    @click="emit('openMap')"
  >
    <div class="demo-note__main">
      <p class="demo-note__title">
        <span class="mr-8">🥑</span>Это демо-каталог.
      </p>
      <span class="demo-note__subtitle">Укажите адрес, чтоб увидеть актуальный каталог</span>
    </div>
    <img
      src="./asset/right-arrow.svg"
      alt="Установить адрес для доставки"
      class="demo-note__icon"
    >
  </div>
</template>

<style scoped lang="postcss">
.demo-note {
  position: fixed;
  bottom: 50px;
  left: 50%;
  z-index: var(--z-below-blackout);
  display: flex;
  justify-content: space-between;
  min-width: 454px;
  padding: 15px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 12px 48px 0px rgba(50, 50, 71, 0.08), 0px 12px 12px 0px rgba(50, 50, 71, 0.02);
  transform: translate(-50%, 0);

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #8E8E8E;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
  }

  @media (max-width: 800px) {
    bottom: 75px;
    min-width: 95%;

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 14px;
    }
  }
}
</style>
