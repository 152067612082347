import { RecipeSerializer } from '~/serializer/recipe/Recipe'

export default {
  async getByID(id: string) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get(`/api/v1/darkstore/recipes/${id}`)

    if (data.result?.ITEMS === null)
      return null

    return RecipeSerializer(data.result)
  },
}
