<script lang="ts" setup>
import { UI05Modal } from 'ui-05'
import success from './asset/success.svg'
import error from './asset/error.svg'
import { useActionStatusStore } from '~/store/modal/actionStatus'

const actionStore = useActionStatusStore()
const statusImage = computed(() => actionStore.status === 'success' ? success : error)

function close() {
  actionStore.onReject?.()
}
</script>

<template>
  <UI05Modal
    :show="actionStore.active"
    max-width="500px"
    title=""
    @close="close"
  >
    <template #default>
      <div class="action-status-modal">
        <div class="action-status-modal__img-block">
          <img
            :src="statusImage"
            alt="status"
            class="action-status-modal__img-status"
          >
        </div>
        <div class="action-status-modal__desc-block">
          <h3 class="action-status-modal__title">
            {{ actionStore.title }}
          </h3>
          <span class="action-status-modal__desc">{{ actionStore.desc }}</span>
        </div>
      </div>
    </template>
  </UI05Modal>
</template>

<style lang="postcss" scoped>
.action-status-modal {
  height: 100%;
  margin: 36px 0;

  &__img-block {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }

  &__img-status {
    width: 75px;
  }

  &__desc-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 260px;
    margin: 0 auto;
    text-align: center;
  }

  &__title {
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  &__desc {
    font-size: 16px;
    line-height: 22px;
    color: #818181;
  }

  @media (max-width: 800px) {
    margin: 14px 0;
  }
}
</style>
