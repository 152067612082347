<script setup lang="ts">
import { UI05Button } from 'ui-05'
import { useProductStore } from '~/store/modal/product'

const props = defineProps<{
  error?: {
    statusCode: number
    description: string
  }
  // компонент ошибки NuxtErrorBoundary возвращает ref с строкой
  nuxtErrorBoundary?: string
}>()

const emit = defineEmits<{
  (e: 'error'): void
}>()
const productModalStore = useProductStore()
const { $error } = useNuxtApp()

/*
По клику на window очищаем ошибку. В Nuxt 3 необходимо вручную очишать ошибки, иначе на странице перестают работать корректно
 скрипты.
*/
onMounted(() => {
  window.addEventListener('click', clearError)

  /**
   * Когда рендерится эта страница - значит чего-то пошло не так, сообщаем
   * сентри об этом
   */
  new $error.base({
    code: props.error?.statusCode ?? 500,
    message: props.error?.description ?? (props.nuxtErrorBoundary ?? ''),
  })
})

onUnmounted(() => {
  window.removeEventListener('click', clearError)
  productModalStore.disable()
})

function clearError() {
  emit('error')
}

async function reload() {
  await navigateTo('/')
  location.reload()
}
</script>

<template>
  <div class="error-block">
    <h1 class="error-block__title">
      {{ error?.statusCode || 500 }}
    </h1>
    <h3 class="error-block__subtitle b">
      <template v-if="error?.description">
        {{ error.description }}
      </template>
      <template v-else-if="error?.statusCode === 401">
        Проблема с токеном API
      </template>
      <template v-else-if="error?.statusCode === 503">
        Ошибка доступа
      </template>
      <template v-else-if="nuxtErrorBoundary">
        {{ nuxtErrorBoundary }}
      </template>
      <template v-else>
        Упс, что-то сломалось
      </template>
    </h3>
    <div class="p">
      Попробуйте обновить страницу или воспользоваться сайтом позднее. Если проблема не решается, напишите нам
      <a href="mailto:support@05.ru" class="link">support@05.ru</a>
    </div>
    <UI05Button
      size="39"
      theme="white"
      fluid
      class="mt-26"
      @click="reload"
    >
      Перейти на главную
    </UI05Button>
  </div>
</template>

<style lang="postcss" scoped>
.error-block {
  width: 400px;
  padding: 50px 60px;
  text-align: center;
  background: url(./asset/left-bg.jpg) top 59px left no-repeat, url(./asset/right-bg.jpg) top 59px right no-repeat;
  border: 1px solid #f2f2f2;
  border-radius: 4px;

  &__title {
    margin-bottom: 30px;
    font-size: 105px;
    font-weight: 500;
    line-height: 88px;
  }

  &__subtitle {
    margin-bottom: 20px;
    overflow: hidden;
    font-size: 23px;
    line-height: 27px;
    text-overflow: ellipsis;
  }

  @media (max-width: 500px) {
    padding: 30px 15px 35px;
    background-image: none;

    &__title {
      margin-bottom: 13px;
      font-size: 70px;
      line-height: 66px;
    }
  }
}
</style>
