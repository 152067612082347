import type { RecipeSelection } from '~/type/Recipe'
import ProductSerializer from '~/serializer/product/Product'

interface SelectionResponse {
  [key: string]: any
  ITEMS: any[]
}

export function RecipeSelectionSerializer(el: SelectionResponse): RecipeSelection {
  return {
    name: el.NAME,
    quantity: el.QUANTITY,
    items: el.ITEMS.length ? el.ITEMS.map((el: any) => ProductSerializer(el)) : [],
  }
}
