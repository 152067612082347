<script setup lang="ts">
import { UI05Note } from 'ui-05'
import type Error from '~/type/Error'

interface Props {
  error: Error
  customSerializer?: (e: Error) => { [key: string]: any }
}

const props = defineProps<Props>()

const innerError = computed(() => {
  if (props.customSerializer)
    return props.customSerializer(props.error)
  else
    return serialize(props.error)
})

function serialize(data: Error) {
  return {
    code: data.code,
    title: data.description,
    field: getField(data?.fields || {}),
  }
}

function getField(fields: { [key: string]: string }) {
  const arr = []
  for (const prop in fields) {
    arr.push({
      field: prop,
      description: fields[prop],
    })
  }
  return arr
}
</script>

<template>
  <UI05Note theme="reddish" class="p">
    <div v-if="innerError.title">
      {{ innerError.title }}
    </div>
    <div v-if="innerError.field.length > 0" class="dash-list mt-3">
      <div
        v-for="(e, index) in innerError.field"
        :key="index"
        class="dash-list__item"
      >
        <b class="b">{{ e.field }}</b>
        : {{ e.description }}
      </div>
    </div>
  </UI05Note>
</template>
