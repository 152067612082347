import type { Events } from '~~/plugins/5.emitter'

export default function afterCheckout({ product, price, id, coupon, paymentMethod }: Events['after-checkout']['order']) {
  return {
    event: 'purchase',
    ecommerce: {
      transaction_id: id,
      value: price?.total,
      shipping: price?.delivery,
      currency: 'RUB',
      coupon: Array.isArray(coupon) ? coupon?.map(el => el.COUPON).join(', ') : coupon,
      items: product.length
        ? product.map(el => ({
          item_id: el.id.toString(),
          item_name: el.name,
          price: el.price?.common?.value,
          item_category: el.category?.name,
          quantity: el.quantity,
        }))
        : [],
      payment_type: paymentMethod,
    },
  }
}
