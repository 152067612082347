/**
 * Компонент занимается лишь тем, что проверяет и валидирует токены, при необходимости обновляет
 */
import type { Token } from '~/type/Token'

let refreshTokenPromise: Promise<Token> | null = null
let baseTokenPromise: Promise<Token> | null = null

interface TokenErrorParam {
  message: string
  code?: number
  native?: unknown
}

export class TokenError extends Error {
  code?: number
  native?: unknown

  constructor(arg: TokenErrorParam) {
    super(arg.message)
    Error.captureStackTrace?.(this, TokenError)

    this.name = 'TokenError'
    this.code = arg.code
    this.native = arg.native
  }
}

async function resetBaseToken({ request, error }: {
  request: () => Promise<Token>
  error?: unknown
}) {
  if (process.server) {
    try {
      if (!baseTokenPromise)
        baseTokenPromise = request().finally(() => baseTokenPromise = null)

      const newToken = await baseTokenPromise
      return newToken
    }
    catch (e) {
      throw new TokenError({
        message: 'Ошибка получения базового токена на сервере',
        code: 503,
        native: e,
      })
    }
  }
  else {
    throw new TokenError({
      message: 'Ошибка при попытке обновить токен на клиенте',
      code: 503,
      native: error,
    })
  }
}

export async function TokenCheck({
  token,
  requestRefreshAccess,
  requestBaseToken,
  onTokenReset,
}: {
  token: Token | null
  requestRefreshAccess: (refreshToken: string) => Promise<Token>
  requestBaseToken: () => Promise<Token>
  /**
   * Вызывается при сброса токенов, когда текущая пара ацес/рефреш токена
   * больше не валидна или при их обновлении произошла ошибка
   */
  onTokenReset: () => void
}) {
  if (!token) {
    return resetBaseToken({
      request: requestBaseToken,
    })
  }

  if (isTokenExpire(token.accessToken.expire)) {
    /**
     * Если протухли оба - на клиенте можно прокидывать ошибку,
     * там мы не можем обновить токены из-за соображения безопасноти,
     * на сервере получаем новую пару токенов
     */
    if (isTokenExpire(token.refreshToken.expire)) {
      onTokenReset()
      if (process.client) {
        throw new TokenError({
          message: 'Рефреш токен просрочен на клиенте',
          code: 503,
        })
      }
      else {
        try {
          /**
           * Одновременно несколько частей приложения могут совершать запросы и вся эта
           * пачка запросов может наткнуться на то, что токены нужно обновить,
           * чтобы каждый этот запрос не слал свой запрос на обновление токенов,
           * мы отправляем запрос на обновление только у первого запроса и создаем промис,
           * на который подписываются все последующий запросы
           */
          if (!baseTokenPromise)
            baseTokenPromise = requestBaseToken().finally(() => baseTokenPromise = null)

          const newToken = await baseTokenPromise
          return newToken
        }
        catch (e) {
          throw new TokenError({
            message: 'Не удалось получить базовый токен',
            code: 503,
            native: e,
          })
        }
      }
    }
    else {
      try {
        if (!refreshTokenPromise)
          refreshTokenPromise = requestRefreshAccess(token.refreshToken.value).finally(() => refreshTokenPromise = null)

        const newToken = await refreshTokenPromise
        return newToken
      }
      catch (e) {
        onTokenReset()
        return resetBaseToken({
          request: requestBaseToken,
          error: e,
        })
      }
    }
  }

  /**
   * Если токен не обновился, просто возвращаем текущий токен, значит всё с ним ок
   */
  return token
}

export function isTokenExpire(expire: number) {
  return expire * 1000 < Date.now() + 2 * 60 * 60 * 1000 // 2 часа
}
