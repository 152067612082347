import { default as indexEp5YHjSVpJMeta } from "/app/pages/about/privacy/index.vue?macro=true";
import { default as indexjEY7ijpBrmMeta } from "/app/pages/about/terms-of-use/index.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as index5DkNXPfUMvMeta } from "/app/pages/favorite/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexo7yatTVg0SMeta } from "/app/pages/new-product/index.vue?macro=true";
import { default as index8ge3YOuhn9Meta } from "/app/pages/previously-order/index.vue?macro=true";
import { default as _id20Eg74HnVNMeta } from "/app/pages/product/_id.vue?macro=true";
import { default as indexkwdd20Vk1dMeta } from "/app/pages/promo/index.vue?macro=true";
import { default as indexfSyTVaJtRhMeta } from "/app/pages/recipes/[id]/index.vue?macro=true";
import { default as indexHVbCWNFgttMeta } from "/app/pages/sale/index.vue?macro=true";
import { default as indexP0q8KYV1WMMeta } from "/app/pages/search/index.vue?macro=true";
import { default as index2T3gJkFwJkMeta } from "/app/pages/selection/[id]/index.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
import { default as indexsWUW9BfC10Meta } from "/app/pages/user/card/index.vue?macro=true";
import { default as indexjrSoIzsTv3Meta } from "/app/pages/user/order/[id]/index.vue?macro=true";
import { default as index0tGHhWKBNaMeta } from "/app/pages/user/order/index.vue?macro=true";
import { default as indexv2pDoNS5o8Meta } from "/app/pages/user/question/index.vue?macro=true";
import { default as indexm4x0TPAzP7Meta } from "/app/pages/user/setting/index.vue?macro=true";
import { default as userCXh5rkXRCzMeta } from "/app/pages/user.vue?macro=true";
export default [
  {
    name: indexEp5YHjSVpJMeta?.name ?? "about-privacy",
    path: indexEp5YHjSVpJMeta?.path ?? "/about/privacy",
    meta: indexEp5YHjSVpJMeta || {},
    alias: indexEp5YHjSVpJMeta?.alias || [],
    redirect: indexEp5YHjSVpJMeta?.redirect,
    component: () => import("/app/pages/about/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexjEY7ijpBrmMeta?.name ?? "about-terms-of-use",
    path: indexjEY7ijpBrmMeta?.path ?? "/about/terms-of-use",
    meta: indexjEY7ijpBrmMeta || {},
    alias: indexjEY7ijpBrmMeta?.alias || [],
    redirect: indexjEY7ijpBrmMeta?.redirect,
    component: () => import("/app/pages/about/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexAacQV8oPDMMeta?.name ?? "cart",
    path: indexAacQV8oPDMMeta?.path ?? "/cart",
    meta: indexAacQV8oPDMMeta || {},
    alias: indexAacQV8oPDMMeta?.alias || [],
    redirect: indexAacQV8oPDMMeta?.redirect,
    component: () => import("/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexvJKqjSbZLzMeta?.name ?? "catalog",
    path: indexvJKqjSbZLzMeta?.path ?? "/catalog",
    meta: indexvJKqjSbZLzMeta || {},
    alias: indexvJKqjSbZLzMeta?.alias || [],
    redirect: indexvJKqjSbZLzMeta?.redirect,
    component: () => import("/app/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: index5DkNXPfUMvMeta?.name ?? "favorite",
    path: index5DkNXPfUMvMeta?.path ?? "/favorite",
    meta: index5DkNXPfUMvMeta || {},
    alias: index5DkNXPfUMvMeta?.alias || [],
    redirect: index5DkNXPfUMvMeta?.redirect,
    component: () => import("/app/pages/favorite/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexo7yatTVg0SMeta?.name ?? "new-product",
    path: indexo7yatTVg0SMeta?.path ?? "/new-product",
    meta: indexo7yatTVg0SMeta || {},
    alias: indexo7yatTVg0SMeta?.alias || [],
    redirect: indexo7yatTVg0SMeta?.redirect,
    component: () => import("/app/pages/new-product/index.vue").then(m => m.default || m)
  },
  {
    name: index8ge3YOuhn9Meta?.name ?? "previously-order",
    path: index8ge3YOuhn9Meta?.path ?? "/previously-order",
    meta: index8ge3YOuhn9Meta || {},
    alias: index8ge3YOuhn9Meta?.alias || [],
    redirect: index8ge3YOuhn9Meta?.redirect,
    component: () => import("/app/pages/previously-order/index.vue").then(m => m.default || m)
  },
  {
    name: _id20Eg74HnVNMeta?.name ?? "product-_id",
    path: _id20Eg74HnVNMeta?.path ?? "/product/_id",
    meta: _id20Eg74HnVNMeta || {},
    alias: _id20Eg74HnVNMeta?.alias || [],
    redirect: _id20Eg74HnVNMeta?.redirect,
    component: () => import("/app/pages/product/_id.vue").then(m => m.default || m)
  },
  {
    name: indexkwdd20Vk1dMeta?.name ?? "promo",
    path: indexkwdd20Vk1dMeta?.path ?? "/promo",
    meta: indexkwdd20Vk1dMeta || {},
    alias: indexkwdd20Vk1dMeta?.alias || [],
    redirect: indexkwdd20Vk1dMeta?.redirect,
    component: () => import("/app/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: indexfSyTVaJtRhMeta?.name ?? "recipes-id",
    path: indexfSyTVaJtRhMeta?.path ?? "/recipes/:id()",
    meta: indexfSyTVaJtRhMeta || {},
    alias: indexfSyTVaJtRhMeta?.alias || [],
    redirect: indexfSyTVaJtRhMeta?.redirect,
    component: () => import("/app/pages/recipes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHVbCWNFgttMeta?.name ?? "sale",
    path: indexHVbCWNFgttMeta?.path ?? "/sale",
    meta: indexHVbCWNFgttMeta || {},
    alias: indexHVbCWNFgttMeta?.alias || [],
    redirect: indexHVbCWNFgttMeta?.redirect,
    component: () => import("/app/pages/sale/index.vue").then(m => m.default || m)
  },
  {
    name: indexP0q8KYV1WMMeta?.name ?? "search",
    path: indexP0q8KYV1WMMeta?.path ?? "/search",
    meta: indexP0q8KYV1WMMeta || {},
    alias: indexP0q8KYV1WMMeta?.alias || [],
    redirect: indexP0q8KYV1WMMeta?.redirect,
    component: () => import("/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: index2T3gJkFwJkMeta?.name ?? "selection-id",
    path: index2T3gJkFwJkMeta?.path ?? "/selection/:id()",
    meta: index2T3gJkFwJkMeta || {},
    alias: index2T3gJkFwJkMeta?.alias || [],
    redirect: index2T3gJkFwJkMeta?.redirect,
    component: () => import("/app/pages/selection/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: testL3AVDvhyXeMeta?.name ?? "test",
    path: testL3AVDvhyXeMeta?.path ?? "/test",
    meta: testL3AVDvhyXeMeta || {},
    alias: testL3AVDvhyXeMeta?.alias || [],
    redirect: testL3AVDvhyXeMeta?.redirect,
    component: () => import("/app/pages/test.vue").then(m => m.default || m)
  },
  {
    name: userCXh5rkXRCzMeta?.name ?? "user",
    path: userCXh5rkXRCzMeta?.path ?? "/user",
    meta: userCXh5rkXRCzMeta || {},
    alias: userCXh5rkXRCzMeta?.alias || [],
    redirect: userCXh5rkXRCzMeta?.redirect,
    component: () => import("/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: indexsWUW9BfC10Meta?.name ?? "user-card",
    path: indexsWUW9BfC10Meta?.path ?? "card",
    meta: indexsWUW9BfC10Meta || {},
    alias: indexsWUW9BfC10Meta?.alias || [],
    redirect: indexsWUW9BfC10Meta?.redirect,
    component: () => import("/app/pages/user/card/index.vue").then(m => m.default || m)
  },
  {
    name: indexjrSoIzsTv3Meta?.name ?? "user-order-id",
    path: indexjrSoIzsTv3Meta?.path ?? "order/:id()",
    meta: indexjrSoIzsTv3Meta || {},
    alias: indexjrSoIzsTv3Meta?.alias || [],
    redirect: indexjrSoIzsTv3Meta?.redirect,
    component: () => import("/app/pages/user/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index0tGHhWKBNaMeta?.name ?? "user-order",
    path: index0tGHhWKBNaMeta?.path ?? "order",
    meta: index0tGHhWKBNaMeta || {},
    alias: index0tGHhWKBNaMeta?.alias || [],
    redirect: index0tGHhWKBNaMeta?.redirect,
    component: () => import("/app/pages/user/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexv2pDoNS5o8Meta?.name ?? "user-question",
    path: indexv2pDoNS5o8Meta?.path ?? "question",
    meta: indexv2pDoNS5o8Meta || {},
    alias: indexv2pDoNS5o8Meta?.alias || [],
    redirect: indexv2pDoNS5o8Meta?.redirect,
    component: () => import("/app/pages/user/question/index.vue").then(m => m.default || m)
  },
  {
    name: indexm4x0TPAzP7Meta?.name ?? "user-setting",
    path: indexm4x0TPAzP7Meta?.path ?? "setting",
    meta: indexm4x0TPAzP7Meta || {},
    alias: indexm4x0TPAzP7Meta?.alias || [],
    redirect: indexm4x0TPAzP7Meta?.redirect,
    component: () => import("/app/pages/user/setting/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _id20Eg74HnVNMeta?.name ?? "product-page",
    path: _id20Eg74HnVNMeta?.path ?? "/catalog/:pathMatch(.*)/:id(\\d+)",
    meta: _id20Eg74HnVNMeta || {},
    alias: _id20Eg74HnVNMeta?.alias || [],
    redirect: _id20Eg74HnVNMeta?.redirect,
    component: () => import("/app/pages/product/_id.vue").then(m => m.default || m)
  },
  {
    name: indexvJKqjSbZLzMeta?.name ?? "catalog-route",
    path: indexvJKqjSbZLzMeta?.path ?? "/catalog/:pathMatch(.*)",
    meta: indexvJKqjSbZLzMeta || {},
    alias: indexvJKqjSbZLzMeta?.alias || [],
    redirect: indexvJKqjSbZLzMeta?.redirect,
    component: () => import("/app/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "payment-complete",
    path: indexN6pT4Un8hYMeta?.path ?? "/payment-complete/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]