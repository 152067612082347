<script lang="ts" setup>
interface Props {
  error?: boolean
  loading?: boolean
  success?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  error: false,
  loading: false,
  success: false,
  disabled: false,
})

const emit = defineEmits<{
  (e: 'update:model-value', value: string): void
}>()

const count = 4

const root = shallowRef()
const data = ref(Array(count).fill(''))

function handleInput(index: number, e: Event) {
  if (e.target instanceof HTMLInputElement) {
    if (e.target.value.length <= 1)
      data.value[index] = e.target.value

    if (e.target.value !== '')
      focusNextInput(index)

    if (data.value.length === count)
      submitData()
  }
}

function submitData() {
  emit('update:model-value', data.value.join(''))
}

function handleKeyPress(e: KeyboardEvent) {
  // Проверка на ввод только цифр
  const nonDigitPattern = /\D/

  if (nonDigitPattern.test(e.key) || props.disabled)
    e.preventDefault()
}

function handleKeyDown(index: number, e: KeyboardEvent) {
  // Удаление символов через backspace если пользователь ошибся в вводе
  if (e.key === 'Backspace' && data.value[index] === '')
    focusPreviousInput(index)
}

function handlePaste(e: ClipboardEvent) {
  // Если пользователь решил вставить код из буфера обмена, вместо того чтобы ввести
  e.preventDefault()

  if (props.disabled)
    return

  data.value.map((_, index) => root.value[index].disabled = false)
  const copiedData = e.clipboardData?.getData('text/plain').substring(0, 4)
  root.value[data.value.length - 1].focus()
  nextTick(() => {
    if (copiedData) {
      data.value = copiedData.split('')

      if (data.value.length === count)
        submitData()
    }
  })
}

function focusNextInput(index: number) {
  if (index < data.value.length - 1) {
    root.value[index + 1].disabled = false
    root.value[index + 1].focus()
    root.value[index].disabled = true
  }
}

function focusPreviousInput(index: number) {
  if (index > 0) {
    root.value[index - 1].disabled = false
    root.value[index - 1].focus()
    root.value[index].disabled = true
  }
}
</script>

<template>
  <label
    class="input-code"
    :class="[
      { 'input-code_error': error },
      { 'input-code_loading': loading },
      { 'input-code_success': success },
    ]"
  >
    <input
      v-for="(_, index) in count"
      :key="index"
      ref="root"
      :value="data[index]"
      type="number"
      autocomplete="one-time-code"
      class="input-code__input"
      :disabled="disabled || index > 0"
      maxlength="1"
      @input="handleInput(index, $event)"
      @keypress="handleKeyPress($event)"
      @keydown="handleKeyDown(index, $event)"
      @paste="handlePaste"
    >
  </label>
</template>

<style scoped lang="postcss">
.input-code {
  display: flex;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    display: none;
  }

  &__input {
    width: 40px;
    height: 50px;
    padding: 5px 12px 12px;
    margin-right: 16px;
    font-size: 29px;
    line-height: 33px;
    text-align: center;
    background-color: #fff;
    border: none;
    border-bottom: 2px solid #e5e5e5;
    border-radius: 0;
    outline: none;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px #fffdf4 inset;
    }

    &:disabled {
      color: #000
    }

  }

  &_error {

    .input-code__input {
      color: #e70c0c;
      border-color: #e70c0c
    }
  }

  &_loading {

    .input-code__input {
      opacity: 0.3;
      transition: opacity, 0.3s;
    }
  }

  &_success {

    .input-code__input {
      color: #22a767;
      border-color: #22a767
    }
  }
}
</style>
