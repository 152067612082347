import { useCartStore } from '~/store/cart'
import { useCartChangedModal } from '~/store/modal/cartChanged'

export default defineNuxtPlugin(() => {
  const cartStore = useCartStore()
  const cartChangedModalStore = useCartChangedModal()

  if (cartStore.cartChanged)
    cartChangedModalStore.enable()
})
