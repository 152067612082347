declare global {
  let PopMechanic: { update?: () => {}; watchLocation: boolean }
  let mindbox: { (type: string, value: {
    endpointId: string
  }): void
  queue: any[] }
  interface Window {
    PopMechanic: typeof PopMechanic
    mindbox: (type: string, value: {
      operation: string
      data: unknown
    }) => void
    PopMechanicPageData: {
      type: string
      category: {
        id: string
        name: string
      }
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  // PopMechanic
  const script = document.createElement('script')
  script.src = 'https://static.popmechanic.ru/service/loader.js?c=31066'
  script.async = true
  script.id = 'popmechanic-script'

  script.onload = () => {
    nuxtApp.vueApp.$nuxt.$router.afterEach(() => {
      /**
       * Надо вызывать при изменении урла
       * в режиме SPA
       */
      if (typeof PopMechanic.update === 'function')
        PopMechanic.update()
    })
  }
  /**
   * Режим SPA для mindbox/PopMechanic
   */

  window.PopMechanic = { watchLocation: false }

  document.body.appendChild(script)

  // Mindboxs
  window.mindbox
    = window.mindbox
    || function (...args) {
      mindbox.queue.push(...args)
    }
  mindbox.queue = mindbox.queue || []
  mindbox('create', {
    endpointId: '05ru-website',
  })

  const scriptMindbox = document.createElement('script')
  scriptMindbox.src = 'https://api.mindbox.ru/scripts/v1/tracker.js'
  script.async = true
  document.getElementsByTagName('head')[0].appendChild(scriptMindbox)
})
