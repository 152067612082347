import type { PropertyGroup } from '~/type/product/Product'

export default function (el: { [key: string]: any } = {}): PropertyGroup {
  function getNutritions(props: any): { name: string; value: string }[] {
    const energy = 'Энергетическая ценность'
    const names = ['Белки', 'Жиры', 'Углеводы', energy]

    return names.reduce((acc: any[], name: string) => {
      const obj: { [ key in 'name' | 'value' ]?: any } = {}

      Object.keys(props).forEach((propName) => {
        if (propName.includes(name)) {
          obj.name = name
          obj.value = props[propName]
          if (name === energy)
            obj.name = 'Ккал'

          acc.push(obj)
        }
      })
      return acc
    }, [])
  }

  return {
    title: el?.GROUP_NAME,
    list: Object.entries<string>(el.PROPERTIES).map(([name, value]) => ({ name, value })),
    nutritionList: getNutritions(el.PROPERTIES),
  }
}
