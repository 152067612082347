import ProductPage from './page/ProductPage'
import type Slider from '~/type/Slider'

interface SliderResponse {
  [key: string]: any
  ITEMS?: any[]
}

export default function (el: SliderResponse): Slider {
  return {
    name: el?.GROUP_NAME,
    list: el?.ITEMS ? el.ITEMS.map((item: any) => ProductPage(item)).filter((_: any, index: number) => index < 20) : [],
  }
}
