import ThumbnailSerializer from '../Thumbnail'
import { RecipeIngredientSerializer } from './RecipeIngredient'
import { RecipeStepSerializer } from './RecipeStep'
import { RecipeSelectionSerializer } from './RecipeSelection'
import type { Recipe, RecipeBase } from '~/type/Recipe'
import ProductSerializer from '~/serializer/product/Product'
import type { Product } from '~/type/product/Product'

export function BaseRecipeSerializer(el: { [key: string]: any }): RecipeBase {
  return {
    id: el.ID,
    name: el.NAME,
    time: el.COOKING_TIME,
    thumbnails: {
      desktop: ThumbnailSerializer(el.DESKTOP_BANNER.THUMBNAILS),
      mobile: ThumbnailSerializer(el.MOBILE_BANNER.THUMBNAILS),
    },
    image: {
      desktop: el.DESKTOP_BANNER.BANNER,
      mobile: el.MOBILE_BANNER.BANNER,
    },
  }
}

interface RecipeResponse {
  [key: string]: any
  INGREDIENTS: any[]
  COOKING_STEPS: any[]
  PRODUCTS: any[]
}

interface SelectionResponse {
  [key: string]: any
  ITEMS: any[]
}

export function RecipeSerializer(el: RecipeResponse): Recipe {
  return {
    ...BaseRecipeSerializer(el),
    description: el.DESCRIPTION,
    ingredients: el.INGREDIENTS.length ? el.INGREDIENTS.map((el: any) => RecipeIngredientSerializer(el)) : [],
    steps: el.COOKING_STEPS.length ? el.COOKING_STEPS.map((el: any) => RecipeStepSerializer(el)) : [],
    selection: el.PRODUCTS.length ? el.PRODUCTS.map((el: any) => RecipeSelectionSerializer(el)) : [],
    products: (Array.isArray(el.PRODUCTS) && el.PRODUCTS.length)
      ? el.PRODUCTS.reduce((acc: Product[], el: SelectionResponse) => {
        const products = (Array.isArray(el.ITEMS) && el.ITEMS.length) ? el.ITEMS.map(el => ProductSerializer(el)) : []
        return acc.concat(products)
      }, [])
      : [],
  }
}
