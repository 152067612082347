import { formatAddress } from '@artmizu/utils'
import type { Address } from '~/type/Address'

interface GeoObject {
  GeoObject: {
    [key: string]: any
    Point: {
      pos: string
    }
  }
}

export default function (el: GeoObject) {
  function convertToObject(arr: { kind: string; name: string }[]) {
    const tmp: { [key: string]: string } = {}
    arr?.forEach((el) => {
      if (el?.kind)
        tmp[el?.kind] = el?.name
    })
    return tmp
  }

  const address = el?.GeoObject?.metaDataProperty?.GeocoderMetaData?.Address || {}
  const tmp = convertToObject(address?.Components)
  const coords = el?.GeoObject?.Point?.pos?.split(' ')
  const result: Address = {
    city: tmp.locality,
    street: tmp.street,
    district: tmp.district,
    house: tmp.house,
    // TODO: убрал пока регион, т.к информация дублируется
    // region: el?.GeoObject?.description,
    region: '',
    lat: +coords[1],
    lon: +coords[0],
    formatted: '',
  }

  result.formatted = formatAddress(result)!
  return result
}
