<script setup lang="ts">
import { useProductStore } from '~/store/modal/product'
import type { Product, ProductExtended } from '~/type/product/Product'

interface Props {
  data: ProductExtended
  similar: { list: ProductExtended[]; name: string }
}

const props = defineProps<Props>()

const { $emitter } = useNuxtApp()

const productModalStore = useProductStore()

const price = computed(() => props.data?.price)
const similarTitle = 'С этим товаром смотрят'
const actionCart = useActionCart(props.data.id)

onMounted(() => {
  $emitter.emit('product-list-view', { list: props.similar?.list, listName: props.similar?.name, product: { ...props.data } })
})

function onClickProduct(data: Product) {
  productModalStore.disable()
  /**
    *  Дожидаемся пока сработает мутация на закрытие модального окна
  */
  nextTick(() => {
    productModalStore.enable({ active: true, id: data.id, path: data.path })
  })
}
</script>

<template>
  <div class="product">
    <div class="product__content">
      <div class="product__header">
        <div class="product__slider">
          <ProductSlider
            :images="data.thumb"
            :alt="data.name"
          />
        </div>
        <div class="product__info">
          <h1 class="product__title">
            {{ data.name }}
          </h1>
          <div
            v-if="data.propertyGroup.length && data.propertyGroup[0].nutritionList.length"
            class="product__note"
          >
            <div class="product__note-title">
              Пищевая ценность на 100 г
            </div>
            <div class="product__note-row">
              <div
                v-for="(item, index) in data.propertyGroup[0].nutritionList"
                :key="index"
                class="product__note-col"
              >
                <div class="product__note-col-name">
                  {{ item.name }}
                </div>
                <div class="product__note-col-value">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
          <div class="product__cart-block">
            <div
              v-if="price"
              class="product__price-wrapper"
            >
              <Price
                :price="price"
                size="medium"
              />
              <PercentDiscountMarker
                v-if="price.percentDelta"
                :percent="price.percentDelta"
              />
            </div>

            <div
              v-if="price && price.common"
              class="product__button"
            >
              <CartButton
                :price="price.common.value"
                :active="actionCart.active.value"
                :quantity="actionCart.quantity.value"
                :max="data.limit"
                :loading="actionCart.loading.value"
                @decrease="actionCart.decreaseAmount"
                @increase="actionCart.increaseAmount({ listName: 'Карточка товара' })"
                @click="actionCart.active.value ? null : actionCart.add(actionCart.quantity.value + 1, 'Карточка товара')"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- ждем данные с апи -->
      <!-- <div class="product__desc">
        <h5 class="h5 product__desc-title">Описание</h5>
        <p class="p">
          Новая деревня — самая широкая линейка молочных продуктов, выпускаемая предприятием. В ней представлены как базовые молочные
          продукты (молоко, кефир, масло, сметана и др.), так и десертные (молочные коктейли, злаковые йогурты). Продукция данной марки
          рассчитана на современных людей, для которых в приоритете натуральность и экологичность.
        </p>
      </div> -->
      <div
        v-if="Array.isArray(similar.list) && similar.list.length"
        class="product__row-slider"
      >
        <div class="product__row-slider-title">
          {{ similarTitle }}
        </div>
        <ViewportToogle>
          <template #desktop>
            <RowSlider
              v-slot="{ el }"
              :list="similar.list"
              :offset-between-elements="10"
              :slides-per-group="2"
            >
              <ProductCard
                :key="el.id"
                :product="el"
                size="small"
                :list-name="similarTitle"
                :style="{ width: '140px' }"
                @click="onClickProduct"
              />
            </RowSlider>
          </template>
          <template #mobile>
            <RowSlider
              v-if="similar?.list?.length > 4"
              v-slot="{ el }"
              :list="similar.list"
              :offset-between-elements="10"
              :slides-per-group="1"
            >
              <ProductCard
                :key="el.id"
                :product="el"
                size="small"
                :list-name="similarTitle"
                :style="{ width: '140px' }"
                @click="onClickProduct"
              />
            </RowSlider>
            <ProductGrid v-else :list="similar.list">
              <template #product="{ product }">
                <ProductCard
                  :key="product.id"
                  :product="product"
                  :list-name="similarTitle"
                  @click="onClickProduct"
                />
              </template>
            </ProductGrid>
          </template>
        </ViewportToogle>
      </div>
      <div class="product__props-wrapper product__props-wrapper_hidden">
        <div class="product__props">
          <template v-if="data.composition">
            <h2 class="product__props-title">
              Состав
            </h2>
            <div class="product__props-composition p">
              {{ data.composition }}
            </div>
          </template>
          <Specifications
            v-for="(item, index) in data.propertyGroup"
            :key="index"
            :title="item.title"
            :properties="item.list"
            class="mb-20"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.product {

  &__cart-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__header {
    display: flex;
    margin-bottom: 25px;
  }

  &__slider {
    flex-shrink: 0;
    width: 310px;
    margin-right: 25px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__note {
    padding: 12px 15px 14px;
    margin-bottom: 18px;
    background: #fffae5;
    border-radius: 10px;

    &-row {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      margin-bottom: 9px;
      font-size: 14px;
      line-height: 16px;
    }

    &-col {
      flex: 1;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-col-name {
      margin-bottom: 3px;
      font-size: 14px;
      line-height: 100%;
      color: #9e9675;
    }

    &-col-value {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      color: #2c2e33;
    }
  }

  &__desc {
    margin-bottom: 25px;

    &-title {
      margin-bottom: 6px;
    }
  }

  &__title {
    margin-bottom: 14px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    word-wrap: break-word;
  }

  &__row-slider-title {
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 500;
    line-height: 21px;
  }

  &__price-wrapper {
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin-bottom: 14px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  &__props {
    margin-top: 25px;
    column-count: 2;

    &-composition {
      overflow: hidden;
      color: #111111;
    }

    &-title {
      margin-bottom: 15px;
      font-size: 19px;
      font-weight: 500;
      line-height: 21px;
    }

    &-button {
      display: none;
    }
  }

  @media (max-width: 800px) {

    &__header {
      flex-direction: column;
      margin-bottom: 0;
    }

    &__slider {
      width: 100%;
      margin: 0 0 13px;
    }

    &__info {
      width: 100%;
      padding-bottom: 0;
    }

    &__title {
      order: 0;
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 21px;
    }

    &__note {
      order: 4;
      margin-bottom: 15px;
    }

    &__count {
      order: 2;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 21px;
    }

    &__button {
      order: 3;
      margin: 0 0 25px;
    }

    &__price {
      display: none;
    }

    &__desc {
      margin-bottom: 21px;

      &-title {
        display: none;
      }
    }

    &__row-slider-title {
      margin-bottom: 15px;
    }

    &__props {
      column-count: auto;
      margin-top: 21px;

      &-composition {
        margin-bottom: 20px;
      }

      &-button {
        display: inline-block;
        color: #085eb1;
        cursor: pointer;

        &-icon {
          position: relative;
          top: 2px;
          display: inline-block;
          width: 9px;
          height: 14px;
          vertical-align: top;
          background-image: svg-load('./asset/arrow-bottom-thin.svg', fill=#085eb1);
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 9px auto;
        }
      }
    }
  }
}
</style>
