import type { Events } from '~~/plugins/5.emitter'

export default function productListView(obj: Events['product-list-view']) {
  return {
    event: 'view_item_list',
    ecommerce: {
      items: obj.list
        ? obj.list.map((el, i) => {
          const path = el.path?.match(/\/([^/]+)\/?$/)
          return {
            item_id: (path && path[1])?.toString(),
            item_name: el.name,
            price: el.price?.common?.value,
            item_category: el.category?.name,
            item_list_name: obj.listName,
            index: i + 1,
            quantity: 1,
          }
        })
        : [],
    },
  }
}
