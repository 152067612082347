<script setup lang="ts">
withDefaults(defineProps<{
  modelValue: boolean
  disabled?: boolean
}>(), {
  disabled: false,
})

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void
}>()
</script>

<template>
  <label class="toggler-wrapper toggler-button" :class="{ 'toggler-wrapper_disabled': disabled }">
    <input
      type="checkbox"
      :disabled="disabled"
      :checked="modelValue"
      @change="emit('update:model-value', !modelValue)"
    >
    <div class="toggler-slider">
      <div class="toggler-knob" />
    </div>
  </label>
</template>

<style lang="postcss" scoped>
.toggler-wrapper {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 51px;
  height: 32px;
  cursor: pointer;

  &_disabled{
    opacity: 0.3;
  }

  & .toggler-knob {
    position: absolute;
    filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15));
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  &.toggler-button .toggler-knob {
    top: 50%;
    left: 3px;
    width: 27px;
    height: 27px;
    background-color: #fff;
    border-radius: 100%;
    transform: translateY(-50%);
  }

  & .toggler-slider {
    position: absolute;
    top: 2px;
    left: 0;
    width: 51px;
    height: 32px;
    overflow: hidden;
    background-color: #78788029;
    border-radius: 20px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  & input[type='checkbox'] {
    display: none;

    &:checked + .toggler-slider {
      background-color: #11C86F;

      .toggler-knob {
        left: calc(100% - 26px - 3px);
      }
    }
  }
}
</style>
