<script lang="ts" setup>
import { useSearchMobileStore } from '~/store/modal/searchMobile'
import { useCategoryStore } from '~/store/category'
import SearchBar from '~/components/SearchBar/SearchBar.vue'
import { useSubCategoriesStore } from '~/store/subCategory'
import type { Category } from '~/type/Category'

const searchMobileStore = useSearchMobileStore()
const subCategoryStore = useSubCategoriesStore()
const categoryStore = useCategoryStore()
const searchBar = shallowRef<InstanceType<typeof SearchBar>>()
const { $api, $emitter } = useNuxtApp()

type Params = Parameters<typeof $api.search.byQuery>[0]

const activeQuery = ref('')
const params = ref<Params>({
  query: activeQuery.value,
  size: 25,
  page: 1,
})
const currentCategory = ref<Category>()
const isDataEntityActive = ref(false)

const search = await useDataEntityList(`searchPage-${activeQuery.value}`, $api.search.byQuery, {
  params,
  waitForFirstRequest: false,
  active: isDataEntityActive,
  requestPriority: 'last',
  onSuccess: (data) => {
    if (!currentCategory.value?.id)
      subCategoryStore.setCategories(data.data.category ?? [])
    $emitter.emit('product-list-view', { list: data.data.list, listName: 'Результаты поиска' })
    $emitter.emit('search', { searchTerm: `${activeQuery.value}` })
  },
})

watch(() => activeQuery.value, (query) => {
  if (query) {
    isDataEntityActive.value = true
    params.value.query = query
    params.value.section = undefined
    currentCategory.value = undefined
    nextTick(() => search.request('replace', params.value))
  }
  else {
    params.value = {
      query: '',
      size: 25,
      page: 1,
    }
  }
})

useHead(
  computed(() => ({
    title: `Поиск ${activeQuery.value}`,
  })),
)

onMounted(() => {
  searchBar.value?.focusInput()
})

function pushToMainPage() {
  searchMobileStore.disable()
  stopSearch()
}

function searchData(query: string) {
  if (query) {
    activeQuery.value = query
    searchMobileStore.initial = false
  }
}

function stopSearch() {
  searchMobileStore.initial = true
  activeQuery.value = ''
}

function loadMore() {
  if (search.pagination.value.current < search.pagination.value.count) {
    if (params.value.page) {
      params.value.page += 1
      nextTick(() => search.request('append', params.value))
    }
  }
}

function onCategoryClick(category?: Category) {
  currentCategory.value = category
  params.value.section = category?.id
  params.value.page = 1
  nextTick(() => search.request('replace', params.value))
}
</script>

<template>
  <transition
    :duration="{ enter: 400, leave: 400 }"
    enter-from-class="custom-fade-in-up-enter"
    enter-active-class="custom-fade-in-up-enter-active"
    enter-to-class="custom-fade-in-up-enter-to"
    leave-from-class="$custom-fade-out-down-leave"
    leave-active-class="$custom-fade-out-down-leave-active"
    leave-to-class="$custom-fade-out-down-leave-to"
  >
    <div v-if="searchMobileStore.active" class="modal-overlay modal-overlay_full modal-overlay_mobile-fullscreen">
      <div class="search-modal">
        <main class="search-modal__main">
          <header class="search-modal__header">
            <img
              src="./asset/logo.svg"
              class="search-modal__logo"
              @click="pushToMainPage"
            >
            <div class="search-modal__search-bar">
              <SearchBar
                ref="searchBar"
                @search="searchData"
                @stop-search="stopSearch"
              />
            </div>
          </header>
          <div v-if="searchMobileStore.initial" class="search-modal__categories">
            <SearchCategoryItem
              v-for="(item, i) in categoryStore.categories"
              :key="i"
              :data="item"
              @click="searchMobileStore.disable"
            />
          </div>
          <div v-else class="search-modal__search">
            <SearchPage
              :api-response="search"
              :active-query="activeQuery"
              :current-category="currentCategory"
              @load-more="loadMore"
              @category-click="onCategoryClick"
            />
          </div>
        </main>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.search-modal {
  width: 100%;
  height: 100%;
  padding: 13px 15px 0;
  background-color: #fff;

  &__loading {
    margin-bottom: 100px;
  }

  &__search {
    padding-bottom: 20px;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 13px 15px;
    margin: -13px -15px 0;
    background: #fff;
    border-bottom: 1px solid #f8f8f8;
  }

  &__search-bar {
    width: 100%;
  }

  &__categories {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    display: flex;
    flex-shrink: 0;
    width: 43px;
    height: 43px;
    margin-right: 10px;
  }
}

.modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  z-index: var(--z-modal);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 20px 30px 0;
  margin-right: -20px;
  overflow-y: scroll;
  background-color: #fff;
  opacity: 1;
  will-change: transform, opacity;

  &_full {
    position: fixed;
    bottom: 0;
    left: 0;
    /* Оставляем 100vh, на случай, если не сработает -webkit-fill-available */
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background-color: rgba(255, 255, 255, 0.88);
  }

  @media (max-width: 800px) {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
  }
}

/* Кастомная анимация появления */
@keyframes customFadeInUp {

  from {
    transform: translateY(70px);
  }

  to {
    transform: translateY(0);
  }
}

/* Кастомная анимация исчезновения */
@keyframes customFadeOutDown {

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(70px);
  }
}

.custom-fade-out-down-leave-active,
.custom-fade-in-up-enter-active {
  transition: opacity 0.4s;

  .modal {
    animation: 0.3s customFadeInUp;
  }
}

.custom-fade-in-up-enter,
.custom-fade-out-down-leave-active {
  opacity: 0;

  .modal {
    animation: 0.3s customFadeOutDown;
  }
}
</style>
