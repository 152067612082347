<script setup lang="ts">
import { formatPrice } from '@artmizu/utils'
import type Price from '~/type/Price'

interface Props {
  price: Price
  size?: 'small' | 'medium' | 'big'
  changedPrice?: number
}

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
})
const beautifyChangedPrice = computed(() => props?.changedPrice && formatPrice(props.changedPrice))
const beautifyPriceCommon = computed(() => props.price.common?.value && formatPrice(props.price.common.value))
const beautifyPriceDiscount = computed(() => props.price.discount?.value && formatPrice(props.price.discount.value))
</script>

<template>
  <div class="price" :class="[`price_${size}`]">
    <div class="price__cols">
      <div class="price__col">
        <div class="price__common-value" :class="[`price__common_${size}`]">
          {{ beautifyPriceCommon }}₽
        </div>
      </div>
      <div v-if="price.discount?.value" class="price__col">
        <div class="price__discount-value" :class="[`price__discount_${size}`]">
          {{ beautifyPriceDiscount }}₽
        </div>
      </div>
      <div v-else-if="changedPrice" class="price__col">
        <div class="price__changed-value" :class="[`price__changed_${size}`]">
          {{ beautifyChangedPrice }}₽
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.price {

  &__cols {
    display: flex;
    column-gap: 5px;
    align-items: center;
  }

  &__common {

    &_small {
      font-size: 16px;
      line-height: 16px;
    }

    &_big {
      font-size: 18px;
      line-height: 18px;
    }

    &_medium {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  &__discount-value {
    position: relative;
    color: #818181;

    &::before {
      position: absolute;
      top: 50%;
      left: -2px;
      width: calc(100% + 5px);
      height: 1px;
      content: '';
      background-color: #818181;
    }
  }

  &__changed {

    &_small {
      font-size: 18px;
      line-height: 16px;
    }

    &_big {
      font-size: 18px;
      line-height: 18px;
    }

    &_medium {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  &__changed-value {
    position: relative;
    margin-left: 6px;
    color:  #CCCCCC;

    &::before {
      position: absolute;
      top: 50%;
      left: -2px;
      width: calc(100% + 5px);
      height: 1px;
      content: '';
      background-color: #e30613;
      transform: rotate(-17deg);
    }
  }

  &__discount {

    &_small {
      font-size: 12px;
      line-height: 12px;
    }

    &_big {
      font-size: 16px;
      line-height: 18px;
    }

    &_medium {
      font-size: 16px;
      line-height: 12px;
    }
  }
  @media (max-width: 768px) {

    &__common_small, &__changed_small {
      font-size: 14px;
    }

    &__common_big,
    &__discount_big,
    &__changed_big {
      line-height: 16px;
    }
  }
}
</style>
