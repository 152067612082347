import { defineStore } from 'pinia'
import { ref } from 'vue'

export const deliveryAvailableStore = defineStore('deliveryAvailable', () => {
  const active = ref(false)
  const address = ref('')

  function enable(newAddress: string) {
    address.value = newAddress
    active.value = true
  }

  function disable() {
    active.value = false
  }

  return { active, address, enable, disable }
})
