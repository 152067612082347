import { defineStore } from 'pinia'
import type { Product } from '~/type/product/Product'

export const useOrderReviewModal = defineStore('orderReview', () => {
  const active = ref(false)
  const id = ref('')
  const productList = ref<Product[]>([])

  function enable({ list, orderId }: { list: Product[]; orderId: string }) {
    productList.value = list
    id.value = orderId
    active.value = true
    document.body.classList.add('order-review-modal')
  }

  function disable() {
    productList.value = []
    active.value = false
    id.value = ''
    document.body.classList.remove('order-review-modal')
  }

  return { active, productList, id, enable, disable }
})
