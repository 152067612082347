import type { Events } from '~/plugins/5.emitter'

export default function addToFavorite(data: Events['wishlist-product-add']) {
  return {
    event: 'add_to_wishlist',
    value: data.price,
    ecommerce: {
      currency: 'RUB',
      items: [{
        item_id: data.id.toString(),
        quantity: 1,
      }],
    },
  }
}
