import type MetaBlock from '~/type/MetaBlock'

export default function (el: { [ key: string ]: any } = {}): MetaBlock {
  return {
    element: {
      description: el.ELEMENT_META_DESCRIPTION,
      title: el.ELEMENT_META_TITLE,
    },
    category: {
      description: el.SECTION_META_DESCRIPTION,
      title: el.SECTION_META_TITLE,
    },
  }
}
