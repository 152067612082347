<script setup lang="ts">
import Swiper from 'swiper'
import { Pagination, Thumbs } from 'swiper/modules'
import { UI05Photoswipe } from 'ui-05'
import empty from '@/assets/images/empty.png'

import 'swiper/css'
import 'swiper/css/thumbs'
import 'swiper/css/pagination'

const props = withDefaults(defineProps<{
  images: string[]
  alt?: string
}>(), {
  alt: '',
})

const slides = computed(() => {
  return props.images.length > 0
    ? props.images
    : [empty]
})

const isOneSlide = computed(() => {
  return slides.value.length < 2
})

const sliderEl = ref<HTMLElement>()
const thumbsEl = ref<HTMLElement>()

const mainSlider = ref<Swiper | null>(null)
const thumbsSlider = ref<Swiper | null>(null)

const photoswipeEl = shallowRef<InstanceType<typeof UI05Photoswipe>>()
const imagesArr = ref<HTMLImageElement[]>()

function getPhotoswipeItems() {
  return Array.from(imagesArr.value!).map(($el) => {
    return {
      src: $el.src,
      w: $el.naturalWidth,
      h: $el.naturalHeight,
    }
  })
}

onMounted(() => {
  thumbsSlider.value = new Swiper(thumbsEl.value!, {
    modules: [Thumbs],
    spaceBetween: 5,
    slidesPerView: 5,
  })

  mainSlider.value = new Swiper(sliderEl.value!, {
    modules: [Thumbs, Pagination],
    slidesPerView: 1,
    thumbs: {
      swiper: thumbsSlider.value,
      autoScrollOffset: 1,
    },
    pagination: {
      el: '.product-slider__pagination',
      clickable: false,
    },
  })
})
</script>

<template>
  <div class="product-slider">
    <div ref="sliderEl" class="product-slider__slides">
      <div class="swiper-wrapper">
        <div
          v-for="(slide, index) in slides"
          :key="index"
          class="product-slider__slide swiper-slide"
          @click="photoswipeEl?.open({ items: getPhotoswipeItems(), options: { index: mainSlider?.activeIndex, history: false } })"
        >
          <img
            ref="imagesArr"
            :src="slide"
            :alt="alt"
          >
        </div>
      </div>
    </div>
    <div
      ref="thumbsEl"
      class="product-slider__thumbs"
      :class="{ 'product-slider__thumbs_hidden': isOneSlide }"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(thumb, index) in slides"
          :key="index"
          class="product-slider__thumb swiper-slide"
        >
          <img :src="thumb" :alt="alt">
        </div>
      </div>
    </div>
    <div v-if="!isOneSlide" class="product-slider__pagination" />
    <UI05Photoswipe v-if="imagesArr" ref="photoswipeEl" />
  </div>
</template>

<style lang="postcss">
.product-slider {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: auto;

  &__slides {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f2f2f2;
    border-radius: 5px;
  }

  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    height: 320px;
    cursor: pointer;
    background-color: #f2f2f2;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      padding: 20px;
      mix-blend-mode: multiply;
    }
  }

  &__thumbs {
    display: flex;
    flex-direction: row;
    height: 60px;
    overflow: scroll;
    background-color: #fff;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__thumbs_hidden {
    display: none;
  }

  &__thumb {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 60px;
    padding: 5px;
    margin-right: 5px;
    overflow: hidden;
    cursor: pointer;
    background: #f2f2f2;
    border-radius: 5px;
    opacity: 0.4;
    transition: opacity 0.25s ease-out;

    &:last-child {
      margin-right: 0;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      mix-blend-mode: multiply;
    }

    &.swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  &__pagination {
    display: none;
  }
}

@media (max-width: 800px) {

  .product-slider {
    direction: column;
    gap: 13px;
    height: 309px;

    &__thumbs {
      display: none;
    }

    &__slides {
      background: #fff;
    }

    &__slide {
      height: 290px;
      background: #fff;
    }

    &__pagination {
      display: flex;
      align-items: center;
      justify-content: center;

      .swiper-pagination-bullet {
        flex-shrink: 0;
        width: 5px;
        height: 5px;
        margin: 0 3px!important;
        background-color: #303030;
        border-radius: 100%;
        transition: transform 0.2s linear, background-color 0.2s linear;
      }

      .swiper-pagination-bullet-active-next,
      .swiper-pagination-bullet-active-prev {
        transform: none;
      }

      .swiper-pagination-bullet-active-next-next,
      .swiper-pagination-bullet-active-prev-prev {
        transform: scale(0.66);
      }

      .swiper-pagination-bullet-active {
        background-color: #000000;
      }
    }
  }
}
</style>
