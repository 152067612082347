import * as Sentry from '@sentry/vue'
import { version } from '../package.json'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()

  if (!sentry.dsn) {
    console.warn('Sentry DSN not set, skipping Sentry initialization')
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    release: `darkstore@${version}`,
    tracesSampleRate: sentry.environment === 'production' ? 0.05 : 1,
    replaysSessionSampleRate: sentry.environment === 'production' ? 0.05 : 1,
    replaysOnErrorSampleRate: sentry.environment === 'production' ? 0.05 : 1,
  })
})
