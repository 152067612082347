<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import type SortType from '~/type/Sort'
import type { Product } from '~/type/product/Product'
import type Pagination from '~/type/Pagination'

interface Props {
  loading?: boolean
  sortList?: SortType[]
  activeSort?: SortType
  list: Product[]
  pagination?: Pagination
  quantityOnPage?: number
  enableObserver?: boolean
  intersection?: boolean
}

withDefaults(defineProps<Props>(), {
  loading: false,
  sortList: () => [],
  pagination: () => ({
    count: 0,
    current: 0,
    itemQuantity: 0,
  }),
  quantityOnPage: 0,
  intersection: false,
})

const emit = defineEmits<{
  (e: 'sort', value: SortType): void
  (e: 'change-page', page: number): void
  (e: 'load-more', page: number): void
  (e: 'intersect'): void
}>()

const grid = shallowRef<HTMLDivElement>()

const { stop } = useIntersectionObserver(
  grid,
  ([{ isIntersecting }]) => {
    if (isIntersecting)
      emit('intersect')
  },
)

onUnmounted(() => stop())
</script>

<template>
  <div class="product-grid">
    <div v-if="$slots.header" class="product-grid__header">
      <slot name="header" />
      <div v-if="sortList.length && activeSort && list.length" class="product-grid__filter">
        <div
          class="product-grid__desktop-sort"
        >
          <Sort
            :list="sortList"
            :value="activeSort"
            @sort="emit('sort', $event)"
          />
        </div>
        <div
          class="product-grid__mobile-sort"
        >
          <SortSelect
            :list="sortList"
            :value="activeSort"
            width="90px"
            font-size="13px"
            @change="emit('sort', $event)"
          />
        </div>
      </div>
    </div>
    <div class="product-grid__content">
      <div
        v-if="list && list.length"
        class="product-grid__product-list"
        :class="[{ 'product-grid__product-list_loading': loading }]"
      >
        <div
          v-for="(product, index) in list"
          :key="index"
        >
          <slot
            name="product"
            :product="product"
          />
        </div>
        <div
          v-if="intersection"
          ref="grid"
          class="product-grid__intersection-elem"
        />
      </div>
      <template v-else>
        <slot name="empty">
          Нет товаров
        </slot>
      </template>
      <div
        v-if="pagination.current && pagination.count > 1"
        class="product-grid__pagination"
      >
        <HybridPagination
          :quantity-on-page="quantityOnPage"
          :quantity="pagination.itemQuantity"
          :count="pagination.count"
          :current="pagination.current"
          @change-page="emit('change-page', $event)"
          @load-more="emit('load-more', $event)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.product-grid {
  height: 100%;

  &__intersection-elem {
    height: 100px;
  }

  &__product-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
    column-gap: 8px;
    transition: opacity 0.3s ease-out;

    &_loading {
      opacity: 0.3;
    }

    @media (max-width: 1300px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
    }
  }

  &__pagination {
    margin-top: 30px;
  }

  &__mobile-sort {
    display: none;
  }

  &__header {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  @media (max-width: 1000px) {

    &__header {
      flex-direction: column;
      align-items: start;
    }
  }
  @media (max-width: 900px) {

    &__filter {
      margin-bottom: 13px;
    }

    &__desktop-sort {
      display: none;
    }

    &__mobile-sort {
      display: block;
    }

    &__pagination {
      margin-top: 20px;
    }
  }
}
</style>
