import type { MapsArea } from '~/type/MapsArea'

function getMaxMinCoords(zoneList: MapsArea[]) {
  // получаем необходимые координаты из списка полигонов
  const coordsList = zoneList.reduce((acc: { coords: number[] }[], p) => {
    acc[0].coords.push(...Object.values(p.zoneAreaCoords.lat))
    acc[1].coords.push(...Object.values(p.zoneAreaCoords.lon))

    return acc
  }, [{ coords: [] }, { coords: [] }])

  return {
    lat: {
      min: Math.min(...coordsList[0].coords),
      max: Math.max(...coordsList[0].coords),
    },
    lon: {
      min: Math.min(...coordsList[1].coords),
      max: Math.max(...coordsList[1].coords),
    },
  }
}

function getZoneCoords(list: MapsArea['polygon']) {
  const coordsList = list.reduce((acc: { coords: number[] }[], z) => {
    acc[0].coords.push(Number(z.lat))
    acc[1].coords.push(Number(z.lon))

    return acc
  }, [{ coords: [] }, { coords: [] }])
  return {
    lat: {
      min: Math.min(...coordsList[0].coords),
      max: Math.max(...coordsList[0].coords),
    },
    lon: {
      min: Math.min(...coordsList[1].coords),
      max: Math.max(...coordsList[1].coords),
    },
  }
}

export function mapsAreasSerializer(param: { [key: string]: any }[]): { polygons: MapsArea[]; maxMinCoords: MapsArea['zoneAreaCoords'] } {
  const areas = param.map((el) => {
    return {
      deliveryTime: el.APPROXIMATION_DELIVERY_TIME,
      code: el.CODE,
      polygon: el.POLYGON,
      zoneAreaCoords: getZoneCoords(el.POLYGON),
    }
  })

  return {
    polygons: areas,
    maxMinCoords: getMaxMinCoords(areas),
  }
}
