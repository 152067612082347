Компонент переключает видимость слотов, в зависимости от разрешения экрана.
Подробнее. В компоненте есть 2 слота: desktop и mobile.
И цель компонента заключается в том, что, то что мы прокинули в слот desktop, скрывается в мобильной версии,
а то что мы прокинули в mobile, показывается

<template>
  <div class="viewport-toogle">
    <div class="viewport-toogle__desktop">
      <slot name="desktop" />
    </div>
    <div class="viewport-toogle__mobile">
      <slot name="mobile" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.viewport-toogle {

  &__mobile {
    display: none;
  }
  @media (max-width: 800px) {

    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }
  }
}
</style>
