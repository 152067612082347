import checkCartChanges from './checkCartChanges'
import { useTokenStore } from '~/store/token'
import { useUserStore } from '~/store/user'
import { useFavoriteStore } from '~/store/favorite'

export default function ({ response }: { response: { [key: string]: any } }) {
  const config = useRuntimeConfig()

  const { set: setToken } = useTokenStore()
  const { set: setData } = useUserStore()
  const { setList: setFavoriteList } = useFavoriteStore()

  setToken(response.token)
  setData(response.user)
  setFavoriteList({ list: response.favorite })

  const cookieAccessToken = useCookie('user-access-token', {
    domain: config.public.crossAuthDomain,
    maxAge: 60 * 60 * 24 * 30,
  })

  cookieAccessToken.value = response.token.accessToken.value

  void checkCartChanges()
}
