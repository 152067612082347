import { stringify } from 'qs'
import AddressSerializer from '~/serializer/Address'
import AddressDadataSerializer from '~/serializer/AddressDadata'
import { mapsAreasSerializer } from '~/serializer/MapsArea'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import type { Address } from '~/type/Address'

export default {
  async getAddress(
    { lat, lng, count, radius }: { lat: number; lng: number; count?: number; radius?: number },
  ) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.get(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address?${stringify({
        lat,
        lon: lng,
        count,
        radius_meters: radius,
      })}`,
      {
        headers: {
          'Authorization': `Token ${runtimeConfig.public.dadataKey}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )

    return AddressDadataSerializer(data?.suggestions?.[0])
  },
  getHints(
    { query, count, locations }: { query: string; count: number; locations: [{ [key: string]: unknown }] },
  ) {
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
    const runtimeConfig = useRuntimeConfig()
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${runtimeConfig.public.dadataKey}`,
      },
      body: JSON.stringify({ query, count, locations }),
    }

    return fetch(url, options)
      .then(response => response.text())
      .then((result) => {
        const tmp: any[] = JSON.parse(result)?.suggestions || []
        return tmp.map(el => AddressDadataSerializer(el))
      })
  },
  async getHintsYandex(
    { query, count, bbox, rspn = 0, ll, spn }: { query: string; count: number; bbox?: string; rspn?: number; ll?: string; spn?: number },
  ) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const request = `https://geocode-maps.yandex.ru/1.x?${stringify({
      apikey: runtimeConfig.public.yandexMap,
      format: 'json',
      geocode: query,
      count,
      rspn,
      bbox,
      ll,
      spn,
    })}`

    const { data } = await $axios.get<APIRequestSuccess< { GeoObjectCollection: { featureMember: any[] } }, 'response'>>(request)
    const list: Address[]
      = data?.response?.GeoObjectCollection?.featureMember?.map((el: any) => {
        return AddressSerializer(el)
      }) || []

    return list.filter(el => el.city || el.street)
  },
  async getAddressByCoordinate(
    { lon, lat }: { lon: number; lat: number },
  ) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()

    const { data } = await $axios.get<APIRequestSuccess< { GeoObjectCollection: { featureMember: any[] } }, 'response'>>(`https://geocode-maps.yandex.ru/1.x?${stringify({
      apikey: runtimeConfig.public.yandexMap,
      format: 'json',
      geocode: `${lon},${lat}`,
      type: 'geo',
    })}`)
    const list: Address[] = data?.response?.GeoObjectCollection?.featureMember?.map((el: any) => {
      return AddressSerializer(el)
    }) || []
    return list.filter(el => el.city || el.street)?.[0]
  },
  async getDeliveryZone() {
    const { $axios } = useNuxtApp()

    const { data } = await $axios.get('/api/v2/darkstore/delivery-areas')

    return mapsAreasSerializer(data.result?.ITEMS)
  },
}
